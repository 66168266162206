import { authServices } from "@utils/setupApi";

export const getOperatingHours = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/operating-hours`,
  });
};

export const updateOperatingHours = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/operating-hours/update`,
    body,
  });
};

export const getOperatingCloseDay = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/close-day`,
  });
};

export const updateOperatingCloseDay = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/close-day/update`,
    body,
  });
};

export const getDailyReport = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/stock/notification`,
    parameter,
  });
};

export const createDailyReport = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/stock/notification`,
    body,
  });
};

export const deleteDailyReport = (parameter) => {
  return authServices({
    method: "DELETE",
    url: `${process.env.REACT_APP_API}/v1/stock/notification`,
    parameter,
  });
};

export const getPickupLocation = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/partner/pickup-location`,
  });
};

export const createPickupLocation = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/partner/pickup-location`,
    body,
  });
};

export const updatePointSystem = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/point-reward-system`,
    body,
  });
};

export const onEnabledReliance = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/external-payment`,
    body,
  });
};

export const onEnabledDelivery = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v1/profile/delivery`,
    body,
  });
};
