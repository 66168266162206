import { message } from "antd";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  getOperatingCloseDay,
  updateOperatingCloseDay,
} from "@services/settings.services";

export const OperationalDateLogic = () => {
  const [isDisplaySchedule, setisDisplaySchedule] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getOperatingCloseDay();
      setStartDate(isEmpty(res) ? null : res?.start);
      setEndDate(isEmpty(res) ? null : res?.end);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDate = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;

    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const onSubmitOperatingDate = async () => {
    const data = {
      start: startDate,
      end: endDate,
    };
    try {
      await updateOperatingCloseDay(data);
      await setisDisplaySchedule(false);
      await message.success(`Berhasil update tanggal toko libur`);
    } catch (error) {
      await message.error(`${error.data.message}`);
    }
  };

  return {
    startDate,
    endDate,
    isDisplaySchedule,
    setisDisplaySchedule,
    onChangeDate,
    onSubmitOperatingDate,
  };
};
