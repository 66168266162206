import { useForm } from "react-hook-form";
import { message } from "antd";

import { createPaymentOption } from "@services/masterRequest.services";

export const PaymentOptionsCreateLogic = ({ onCancel, getData, limit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    const { name, description, admin_fee, duration } = data;

    const details = {
      adminFee: {
        number: admin_fee ? parseFloat(admin_fee) : 0,
        unit: "percentage",
      },
      duration: {
        number: duration ? parseInt(duration) : 0,
        unit: "day",
      },
    };

    const req = {
      name,
      description,
      details,
    };

    try {
      await createPaymentOption(req);
      await e.target.reset();
      await getData(1, limit);
      await onCancel();
      await message.success("Berhasil tambah pembayaran tempo");
    } catch (error) {
      await message.error(`${error.data.message}`);
    }
  };

  return {
    errors,
    handleSubmit,
    onSubmit,
    register,
  };
};
