import axios from "axios";
import { isEmpty } from "lodash";
import { getToken } from "@utils/token";

export const basicServices = async ({
  method,
  url,
  body = null,
  parameter = null,
}) => {
  const data = isEmpty(body) ? null : { data: body };
  const params = isEmpty(parameter) ? null : { params: parameter };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method,
        url,
        auth: {
          username: process.env.REACT_APP_BASIC_USERNAME,
          password: process.env.REACT_APP_BASIC_PASSWORD,
        },
        headers: {
          "Content-Type": "application/json",
        },
        ...data,
        ...params,
      });
      resolve(response.data);
    } catch (error) {
      reject(error.response);
    }
  });
};

export const authServices = async ({
  method,
  url,
  body = null,
  parameter = null,
}) => {
  const data = isEmpty(body) ? null : { data: body };
  const params = isEmpty(parameter) ? null : { params: parameter };
  const token = await getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        ...data,
        ...params,
      });
      const res = await response.data;
      resolve(res);
    } catch (error) {
      reject(error.response);
    }
  });
};
