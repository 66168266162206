import { isNil } from "lodash";
import React from "react";
import { getJoyrideAnnounce, getJoyrideWelcome } from "../../utils/memory";

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const joyrideWelcome = getJoyrideWelcome();
  const joyrideAnnounce = getJoyrideAnnounce();

  const titleCondition = () => {
    if (isNil(joyrideWelcome)) {
      if (isNil(joyrideAnnounce)) {
        return "Selamat Datang";
      }
    }
  };

  const classNameCondition = () => {
    if (isNil(joyrideWelcome)) {
      if (isNil(joyrideAnnounce)) {
        return <p className="mb-2">{step.title}</p>;
      } else {
        return <p className="font-bold mb-2">{step.title}</p>;
      }
    } else {
      return <p className="font-bold mb-2">{step.title}</p>;
    }
  };

  return (
    <div className="bg-white rounded w-96 p-4" {...tooltipProps}>
      {index === 0 ? (
        <div>
          <p className="text-xl font-bold mb-2" style={{ color: "#01573e" }}>
            {titleCondition()}
          </p>
          {step.title && classNameCondition()}
        </div>
      ) : (
        <div>
          {step.title && (
            <p className="text-xl font-bold mb-2" style={{ color: "#01573e" }}>
              {step.title}
            </p>
          )}
        </div>
      )}
      <p className="mb-3">{step.content}</p>
      <div>
        {index > 0 && (
          <div {...backProps}>
            <div id="back" />
          </div>
        )}
        {continuous && (
          <div className="flex justify-end" {...primaryProps}>
            <div className="button-basic w-1/3 cursor-pointer" id="next">
              {primaryProps?.title}
            </div>
          </div>
        )}
        {!continuous && (
          <div className="flex justify-end" {...closeProps}>
            <div className="button-basic w-1/3 cursor-pointer" id="close">
              {closeProps?.title}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
