// eslint-disable-next-line

import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useDebounce } from "@utils/delay";
import { formatPriceIdr } from "@utils/format";
import { getListDisbursement,downloadDisbursement } from "@services/disbursement.services";

export const DisbursementListLogic = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit, startDate, endDate, code, filterBy) => {
    setLoading(true);
    const params = {
      page,
      limit,
      start_date: startDate,
      end_date: endDate,
      invoice_code: code,
    };
    try {
      const res = await getListDisbursement(params);
      if (isEmpty(res?.listPaymentHistory)) {
        setData([]);
      } else {
        setData(res?.listPaymentHistory);
        setPage(parseInt(res?.pagination.currentPage));
        setTotalSize(parseInt(res?.pagination.Count));
        setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "invoiceCode",
      text: "Invoice Number",
    },
    {
      dataField: "transactionDate",
      text: "Transaction Date",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD/MM/YYYY HH:mm")}</div>
      ),
    },
    {
      dataField: "createdAt",
      text: "Disbursement Date",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD/MM/YYYY HH:mm")}</div>
      ),
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row) =>
        isNil(cell) ? <p>-</p> : <div>{formatPriceIdr(cell)}</div>,
    },
  ];

  const onTableChange = () => {};

  const onPageChange = (e) => {
    getData(e, limit, startDate, endDate, code, 1);
  };

  const onLimitChange = (e) => {
    getData(1, e, startDate, endDate, code, 1);
    setLimit(e);
  };

  const handleSearch = useDebounce(async (e) => {
    getData(1, limit, startDate, endDate, e.target.value, 1);
    setCode(e.target.value);
  }, 1000);

  const onFilterDateRange = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;
    getData(1, limit, tempStartDate, tempEndDate, code, 1);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const onDownloadCsv = async () => {
    const parameter = {
      from: startDate,
      to: endDate,
    };
    try {
      const res = await downloadDisbursement(parameter);
      await window.open(`${res.url}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    onFilterDateRange,
    onDownloadCsv
  };
};
