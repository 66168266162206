import { authServices } from "@utils/setupApi";

export const getListCampaignEvents = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/campaign-event`,
    parameter,
  });
};

export const registerCampaignEvent = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/campaign-event-user/register`,
    body,
  });
};

export const generateListSku = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/campaign-event-user/generate-list-sku`,
    body,
  });
};
