// eslint-disable-next-line
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getListSku, uploadCsvPrice } from "@services/sku.services";
import { skuListAtom } from "@store/sku.store";
import { useDebounce } from "@utils/delay";
import { formatPriceIdr } from "@utils/format";
import { downloadTemplateRequest } from "@services/masterRequest.services";

export const SkuProductListLogic = () => {
  const navigate = useNavigate();

  const [listSku, setListSku] = useAtom(skuListAtom);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState("");
  const [valueFilter, setValueFilter] = useState("code");

  useEffect(() => {
    getData(page, limit, valueFilter, search);
  }, []);

  const getData = async (page, limit, filter, search) => {
    setLoading(true);
    const params = {
      page,
      limit,
      [filter]: search,
    };
    try {
      const res = await getListSku(params);
      if (isEmpty(res?.listSku)) {
        setListSku([]);
      } else {
        setListSku(res?.listSku);
        setPage(res?.page);
        setLimit(res?.limit);
        setTotalSize(res?.total);
        setSizePerPage(res?.limit);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "code",
      text: "SKU",
      formatter: (cell, row) => (
        <p
          className='cursor-pointer underline'
          onClick={() => navigate(`/product/${row.id}`)}>
          {cell}
        </p>
      ),
    },
    {
      dataField: "codePartner",
      text: "Seller SKU",
      formatter: (cell) => cell || "-",
    },
    {
      dataField: "name",
      text: "Nama Produk",
    },
    {
      dataField: "quantity",
      text: "Stok",
      // sort: true,
      // sortCaret: (order, column) => {
      //   return <FontAwesomeIcon icon={faSort} className='ml-2' />;
      // },
    },
    {
      dataField: "price",
      text: "Harga",
      formatter: (cell, row) => (
        <p>{row.hasOwnProperty("price") ? formatPriceIdr(row.price) : "-"}</p>
      ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit, valueFilter, search);
  };

  const onLimitChange = (e) => {
    getData(1, e, valueFilter, search);
  };

  const handleSearch = useDebounce(async (e) => {
    getData(1, limit, valueFilter, e.target.value);
    setSearch(e.target.value);
  }, 1000);

  const handleChangeFilter = (val) => {
    getData(1, limit, val, search);
    setValueFilter(val);
  };

  const onUpload = async (file) => {
    const body = {
      file,
    };
    try {
      await uploadCsvPrice(body);
      await message.success(`success upload csv file`);
      await getData(1, limit, valueFilter, search);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onDownloadUploadPrice = async () => {
    try {
      const res = await downloadTemplateRequest("price");
      await window.open(res.url, "_blank");
    } catch (error) {}
  };

  return {
    columns,
    listSku,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    navigate,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    handleChangeFilter,
    valueFilter,
    onUpload,
    onDownloadUploadPrice,
  };
};
