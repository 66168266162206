import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";

import SearchLimit from "@components/molekul/SearchLimit";
import Button from "@components/atom/Button";
import { StockUpdateListLogic } from "./StockUpdateList.logic";
import SearchTable from "../components/SearchTable";
import UploadCSV from "@components/molekul/UploadCSV";
import fileUploadCsV from "@assets/template_stock_update_bp.csv";
import finalStockUploadCsV from "@assets/finalstock_upload.csv";

LoadingOverlay.propTypes = undefined;

const StockUpdateList = () => {
  const {
    stocks,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    navigate,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    handleChangeFilter,
    valueFilter,
    onUpload,
    onUploadFinalCsv,
    onDownloadStock,
  } = StockUpdateListLogic();

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className="flex items-center justify-between pb-3 pt-3 md:pt-0">
              <div className="md:w-1/3">
                <p className="font-bold text-xl pb-10">Update Stock</p>
                <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              </div>
              <div className="w-2/3 pt-4">
                <div className="flex justify-end mb-3">
                  <div className="md:w-1/2">
                    <SearchTable
                      handleSearch={handleSearch}
                      onChangeFilterOpt={handleChangeFilter}
                      filterValues={valueFilter}
                    />
                  </div>
                </div>
                <div className="md:flex justify-end">
                  <div className="mr-2 mb-2 md:mb-0">
                    <UploadCSV
                      onUpload={onUploadFinalCsv}
                      title="Upload Stock Akhir"
                      textButton="Upload Stock Akhir"
                      file={finalStockUploadCsV}
                      fileName="template_finalstock_upload.csv"
                    />
                  </div>
                  <div className="mr-2 mb-2 md:mb-0">
                    <UploadCSV
                      onUpload={onUpload}
                      title="Upload CSV"
                      textButton="Upload CSV"
                      file={fileUploadCsV}
                      fileName="template_stock_upload.csv"
                    />
                  </div>
                  <div className="mr-2 mb-2 md:mb-0">
                    <Button
                      textButton="Download Stock CSV"
                      onClick={onDownloadStock}
                    />
                  </div>
                  <div className="mb-2 md:mb-0">
                    <Button
                      textButton="+ Buat Entry"
                      onClick={() => navigate("/stock-update/create")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-striped table-responsive">
              <BootstrapTable
                keyField="id"
                remote
                data={stocks}
                bordered={false}
                loading={loading}
                columns={columns}
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className="text-center">Empty Data</div>
                )}
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(192,192,192,0.3)",
                })}
                {...paginationTableProps}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-muted">
                  Menunjukan{" "}
                  <span className="font-weight-bold text-dark">
                    {`${stocks.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {stocks.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    </div>
  );
};

export default StockUpdateList;
