import React from "react";
import { DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { isNil } from "lodash";
import moment from "moment";

import ButtonOutlined from "@components/atom/ButtonOutlined";
import Button from "@components/atom/Button";
import { OperationalDateLogic } from "./OperationalDate.logic";
import { convertDay } from "@utils/formatDate";

const { RangePicker } = DatePicker;

const OperationalDate = () => {
  const {
    startDate,
    endDate,
    isDisplaySchedule,
    setisDisplaySchedule,
    onChangeDate,
    onSubmitOperatingDate,
  } = OperationalDateLogic();

  return (
    <div className='border border-gray-300 p-8 rounded my-10'>
      <div className='md:flex items-center justify-between block mb-3 border-b border-gray-300'>
        <div className='mb-3'>
          <p className='text-xl font-bold'>
            <FontAwesomeIcon icon={faCalendar} /> Atur tanggal toko libur
          </p>
          <p>
            Disini kamu bisa atur tanggal tokomu libur, selama tokomu libur,
            produk yang kamu jual tidak dapat di beli ya
          </p>
        </div>
        <div>
          {!isDisplaySchedule && (
            <ButtonOutlined
              textButton={"Atur Waktu Libur"}
              onClick={() => setisDisplaySchedule(true)}
            />
          )}
        </div>
      </div>
      {isDisplaySchedule ? (
        <div className='md:flex items-center justify-between block'>
          <div className='mb-3 md:mb-0'>
            <div className='flex mb-2'>
              <p className='w-40 font-bold'>Mulai</p>
              <p className='font-bold'>Selesai</p>
            </div>
            <RangePicker
              format='DD-MM-YYYY'
              onChange={onChangeDate}
              defaultValue={
                isNil(startDate) && isNil(endDate)
                  ? [startDate, endDate]
                  : [moment(startDate), moment(endDate)]
              }
            />
          </div>
          <div className='flex justify-end'>
            <ButtonOutlined
              textButton={"Batal"}
              style={{ marginRight: 5 }}
              onClick={() => window.location.reload()}
            />
            <Button textButton={"Simpan"} onClick={onSubmitOperatingDate} />
          </div>
        </div>
      ) : (
        <div className='mb-3 md:mb-0'>
          <div className='md:flex mb-2'>
            <div>
              <p className='w-48 font-bold'>Mulai</p>
              <p className='w-48 font-bold'>
                {isNil(startDate)
                  ? "-"
                  : `${convertDay(moment(startDate).day())}, ${moment(
                      startDate
                    ).format("DD MMMM YYYY")}`}
              </p>
            </div>
            <div>
              <p className='w-48 font-bold'>Selesai</p>
              <p className='w-48 font-bold'>
                {isNil(endDate)
                  ? "-"
                  : `${convertDay(moment(endDate).day())}, ${moment(
                      endDate
                    ).format("DD MMMM YYYY")}`}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationalDate;
