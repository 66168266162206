// eslint-disable-next-line
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  getCustomerById,
  getPointReward,
  updatePointReward,
} from "@services/customer.services";
import { useParams } from "react-router-dom";
import { userProfileAtom } from "@store/user.store";
import { message } from "antd";

export const CustomerDetailLogic = () => {
  const params = useParams();

  const [data, setData] = useState([]);
  const [profile] = useAtom(userProfileAtom);
  const [pointReward, setPointReward] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getPoint(profile);
  }, [profile]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getCustomerById(params?.id);
      await setData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error get customer: ", error);
    }
  };

  const getPoint = async (user) => {
    if (user) {
      const queryParams = {
        user_id: params?.id,
        warehouse_code: user?.warehouseId,
      };
      try {
        const res = await getPointReward(queryParams);
        await setPointReward(res);
      } catch (error) {
        console.log("error getPoint: ", error);
      }
    }
  };

  const onChangePoint = (val) => {
    setPointReward({ availablePoints: val });
  };

  const onSubmitPoint = async () => {
    const payload = {
      user_id: params?.id,
      available_point: Number(pointReward?.availablePoints),
    };
    try {
      await updatePointReward(payload);
      await getPoint(profile);
      message.success("Berhasil update point reward");
      setIsEdit(false);
    } catch (err) {
      console.log("error edit pointreward: ", err);
    }
  };

  return {
    data,
    pointReward,
    loading,
    isEdit,
    setIsEdit,
    onChangePoint,
    onSubmitPoint,
  };
};
