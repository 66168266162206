import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { AnalyticsLogic } from "./Analytics.logic";
import {
  faCircle,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import { isEmpty } from "lodash";
import moment from "moment";
import "chart.js/auto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPriceIdr } from "@utils/format";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Analytic = () => {
  const {
    cardData,
    dateRange,
    handleSelect,
    options,
    chartCumulative,
    chartTotal,
    startDate,
    endDate,
    isTransactionExist,
  } = AnalyticsLogic();
  const dateFormat = "DD-MM-YYYY";

  return (
    <div className='content-wrapper'>
      <div className='pl-0 py-2'>
        <p className='text-xl font-bold'>Analisa</p>
      </div>
      {/* ====================== Date picker ====================== */}
      <div className='flex lg:justify-end sm:justify-center mb-3'>
        <span className='flex p-2'>
          <div className='w-32 border border-gray-300 bg-white flex items-center justify-center text-xs'>
            Tanggal Transaksi
          </div>
          <RangePicker
            allowClear={false}
            onChange={dateRange}
            format={dateFormat}
            value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
          />
        </span>
      </div>
      {/* ====================== Main col ====================== */}
      {isTransactionExist ? null : (
        <div className='flex items-center justify-center bg-red-100 p-3 rounded mb-3'>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className='mr-2 text-red-400'
          />
          <p>Tidak ada transaksi di tanggal terpilih</p>
        </div>
      )}
      <div className='container mx-auto py-3'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'>
          {cardData &&
            cardData.map((item, idx) => (
              <div
                className='p-3 rounded-xl bg-white drop-shadow-md hover:shadow-xl transition duration-500 ease-in-out'
                key={idx.toString()}
                onClick={() => handleSelect(item)}
                style={
                  item.activeTab ? { borderTop: "5px solid #00573d" } : null
                }>
                <div className='flex flex-row items-center mb-2 justify-between'>
                  <div className='text-sm'>{item.name}</div>
                  <span className=''>
                    <FontAwesomeIcon
                      icon={faCircle}
                      style={{
                        color: "#D9F4E4",
                      }}
                      size='2xl'
                    />
                    <FontAwesomeIcon
                      icon={item.icon}
                      fixedWidth
                      style={{
                        color: "#00885E",
                        position: "absolute",
                        right: 19,
                        top: 21,
                      }}
                      size='sm'
                      rotation={item.name === "Total Order Ditolak" ? 180 : 0}
                    />
                  </span>
                </div>
                <h2 className='text-xl font-semibold tracking-tight text-gray-600'>
                  {item.name === "Total Order Diproses" ||
                  item.name === "Total Order Ditolak"
                    ? formatPriceIdr(item.value)
                    : item.value || "-"}
                  {item.name === "Jumlah Order Diproses" && " pcs"}
                </h2>
              </div>
            ))}
        </div>
      </div>
      {/* ====================== Chart ====================== */}
      <div className='container mx-auto py-3 my-2'>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3'>
          <div className='p-3 rounded-xl bg-white drop-shadow-md border'>
            {!isEmpty(chartCumulative) && (
              <Line
                data={chartCumulative}
                options={options("Jumlah Order (Invoice)")}
              />
            )}
          </div>
          <div className='p-3 rounded-xl bg-white drop-shadow-md border'>
            {!isEmpty(chartTotal) && (
              <Line data={chartTotal} options={options("Total Order (IDR)")} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytic;
