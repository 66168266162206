import React, { useState } from "react";
import { Modal } from "antd";
import { isEmpty } from "lodash";

import Button from "@components/atom/Button";
import { DatePicker, Select } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const ModalFilter = ({
  onChangeDate,
  onGeneratePo,
  onClear,
  title,
  cutOffValue,
  list,
  disabled,
  dateValue,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    onClear();
    setIsModalVisible(false);
  };

  const onClickGeneratePo = () => {
    onGeneratePo();
    handleCancel();
  };

  return (
    <div>
      <Button textButton={title} onClick={showModal} />
      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={
          <div className="flex items-center justify-end pb-2">
            <div>
              <div
                className={`${
                  disabled ? "bg-gray-300" : "bg-green-900"
                } p-2 text-white cursor-pointer rounded`}
                onClick={disabled ? null : onClickGeneratePo}
              >
                Buat PO
              </div>
            </div>
          </div>
        }
      >
        <div className="w-full">
          <div className="mb-2 flex">
            <div className="w-32 border border-gray-300 bg-white flex items-center justify-center text-xs">
              Tanggal PO
            </div>
            <RangePicker
              placeholder={["Tanggal Mulai", "Tanggal Akhir"]}
              style={{ width: "100%" }}
              onChange={onChangeDate}
              allowClear={false}
              value={dateValue}
            />
          </div>
          <div>
            <div className="flex">
              <div className="w-32 border border-gray-300 bg-white flex items-center justify-center text-xs">
                Waktu Cutoff
              </div>
              <Select
                disabled={true}
                style={{ width: "100%" }}
                value={cutOffValue || "-"}
                allowClear
              >
                {list.map((status, idx) => {
                  return (
                    <Option value={status.value} key={idx}>
                      {status.label}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalFilter;
