import React from "react";
import { Navigate } from "react-router-dom";
import { Input } from "antd";

import Button from "@components/atom/Button";
import { LoginLogic } from "./Login.logic";
import { Controller } from "react-hook-form";
import FloatingChat from "../../../components/molekul/FloatingChat";

import logo from "@assets/logo.png";

const Login = () => {
  const { control, handleSubmit, onSubmit, errors, isLoading, isAuth } =
    LoginLogic();

  if (isAuth) {
    return <Navigate to='/' replace={true} />;
  }
  return (
    <form
      className='bg-yellow-400 h-screen w-full flex items-start justify-center overflow-hidden'
      onSubmit={handleSubmit(onSubmit)}>
      <div
        className='absolute top-28 z-10'
        style={{
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}>
        <img src={logo} alt='logo' className='' />
      </div>
      <div
        className='w-3/4 h-full bg-green-900 origin-center -rotate-45'
        style={{ height: "250vh", marginTop: "-85vh" }}>
        <div className='block'>
          <div className='fixed h-full w-full flex items-center justify-center origin-center rotate-45'>
            <div className='bg-white p-8 pb-28 md:w-2/5 mt-40 md:mt-0  mx-auto rounded'>
              <p className='text-xl font-bold mb-10'>Masuk</p>
              <div className='my-4'>
                <p className='mb-2 font-bold '>Email</p>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      className='border border-gray-300 w-full rounded py-2 px-3'
                      size='large'
                      placeholder='belanjaparts@email.com'
                      onChange={onChange}
                      value={value}
                      name='email'
                    />
                  )}
                  name='email'
                  defaultValue=''
                />
                {errors.email && (
                  <div className='text-red-500 mt-1'>This is required.</div>
                )}
              </div>
              <div className='my-4'>
                <p className='mb-2 font-bold '>Kata Sandi</p>
                <div>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input.Password
                        className='flex items-center border border-gray-300 w-full rounded py-2 px-3'
                        size='large'
                        placeholder='Masukkan kata sandi'
                        onChange={onChange}
                        value={value}
                        name='password'
                      />
                    )}
                    name='password'
                    defaultValue=''
                  />
                  {errors.password && (
                    <div className='text-red-500 mt-1'>This is required.</div>
                  )}
                </div>
              </div>
              {/* <p className='text-right font-bold text-green-900 pb-4 cursor-pointer hover:text-green-600'>
              Lupa kata sandi?
            </p> */}
              <Button
                textButton={"Masuk"}
                style={{ width: "100%" }}
                type='submit'
              />
              {/* <p className='text-center pt-10 text-gray-500'>
              Butuh bantuan?{" "}
              <span className='font-bold text-green-900 cursor-pointer hover:text-green-600'>
                Hubungi kami
              </span>
            </p> */}
            </div>
          </div>
        </div>
      </div>
      <FloatingChat />
    </form>
  );
};

export default Login;
