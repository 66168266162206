import moment from "moment";

export const SkuTableHistoryLogic = () => {
  const columns = [
    {
      dataField: "created_at",
      text: "Tanggal",
      sort: true,
      formatter: (cell, row) =>
        moment(row.createdAt).format("DD MMMM YYYY, HH:mm") || "-",
    },
    {
      dataField: "description",
      text: "Deskripsi",
      formatter: (cell) => cell || "-",
    },
    {
      dataField: "id",
      text: "Admin",
      sort: true,
      formatter: (cell, row) => row.admin.name || "-",
    },
  ];

  return {
    columns,
  };
};
