import React from "react";
import ButtonOutlined from "@components/atom/ButtonOutlined";
import Button from "@components/atom/Button";
import { Select } from "antd";
import { BankAccountFieldLogic } from "./BankAccountField.logic";

const BankAccountField = () => {
  const {
    onChangeName,
    onRemove,
    bank,
    onAddRek,
    onSubmit,
    onChangeBankRek,
    onSelectBank,
    isEditMode,
    setIsEditMode,
    bankList,
  } = BankAccountFieldLogic();
  return (
    <div className="border border-gray-300 p-8 rounded">
      {bank && bank.length ? (
        <div className="mb-3 align-start w-11/12">
          <div className="flex w-full mb-2 gap-3 items-center">
            <p className="w-1/5 font-bold">Nama Bank</p>
            <p className="w-2/6 font-bold">Nomor Rekening</p>
            <p className="w-2/6 font-bold">Nama Pemilik Rekening</p>
          </div>
          {isEditMode
            ? bank.map((item, idx) => (
                <div key={idx} className="flex w-full mb-2 gap-3 items-center">
                  <Select
                    style={{ width: "20%" }}
                    className="border bg-clip-padding
                      border-solid border-gray-300 rounded h-10 flex items-center"
                    placeholder="Select Nama Bank"
                    onChange={(e) => onSelectBank(e, idx)}
                    value={item.bankName}
                    options={bankList}
                    allowClear
                    bordered={false}
                  />
                  <input
                    className="border bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-2/6"
                    value={item.bankRekening}
                    onChange={(e) => onChangeBankRek(e.target.value, idx)}
                    placeholder="No rekening"
                  />
                  <input
                    className="border bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-2/6"
                    value={item.accountName}
                    onChange={(e) => onChangeName(e.target.value, idx)}
                    placeholder="Nama pemilik"
                  />
                  <p
                    className="cursor-pointer pl-3"
                    onClick={() => onRemove(idx, item)}
                  >
                    X
                  </p>
                </div>
              ))
            : bank.map((item, idx) => (
                <div key={idx} className="flex w-full mb-2 gap-3 items-center">
                  <div className="text-base w-1/5">{item?.bankName}</div>
                  <div className="text-base w-2/6">{item?.bankRekening}</div>
                  <div className="text-base w-2/6">{item?.accountName}</div>
                </div>
              ))}
        </div>
      ) : null}
      <div className="sm:flex items-center justify-between block">
        <div className="flex justify-end items-center mt-4">
          {isEditMode ? (
            <ButtonOutlined
              textButton={"+ Tambah Rekening"}
              style={{ marginRight: 5 }}
              onClick={onAddRek}
            />
          ) : (
            <ButtonOutlined
              textButton={"Edit"}
              style={{ marginRight: 5 }}
              onClick={() => setIsEditMode(true)}
            />
          )}
          {isEditMode && <Button textButton={"Simpan"} onClick={onSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default BankAccountField;
