import React from "react";
import { Dropdown, Menu } from "antd";

import menuIcon from "@assets/notes.png";
import profileIcon from "@assets/pic-1.png";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { isAuthAtom } from "@store/auth.store";
import { useAtom } from "jotai";
import { removeToken } from "@utils/token";
import { userProfileAtom } from "@store/user.store";
import Notification from "./components/Notification";

const Header = ({ onClickMenu }) => {
  const navigate = useNavigate();

  const [, setIsAuth] = useAtom(isAuthAtom);
  const [, setUserProfile] = useAtom(userProfileAtom);

  const items = [
    { label: "Profil", key: "0" }, // remember to pass the key prop
    { label: "Keluar", key: "1" }, // which is required
  ];

  const onClick = (e) => {
    if (e.key === "0") {
      return navigate("/profile");
    } else {
      setIsAuth(false);
      setUserProfile(null);
      removeToken();
      navigate("/login");
    }
  };

  const menu = () => {
    return <Menu className="p-3" items={items} onClick={onClick} />;
  };

  return (
    <div className="header z-10">
      <div>
        <img
          src={menuIcon}
          alt="menuIcon"
          className="menuIcon"
          onClick={onClickMenu}
        />
      </div>
      <div className="wrapper-header-right">
        <Notification />
        <div className="account">
          <Dropdown overlay={menu()} trigger={["click"]}>
            <div className="flex items-center">
              <img src={profileIcon} alt="profileIcon" />
              <FontAwesomeIcon icon={faCaretDown} className="pl-3" />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
