import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#ccc",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropzoneMultiple = ({ files, setFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "image/png": [".jpeg", ".png"],
        "application/pdf": [".pdf"],
      },
      minSize: 0,
      maxFiles: 3,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input multiple {...getInputProps()} />
        {isEmpty(files)
          ? null
          : files.map((x, idx) => (
              <p key={idx} style={{ color: "#2196f3", marginTop: 8 }}>
                &#x2022; {x.name.substring(0, 50)}...
              </p>
            ))}
        <div className=" mt-3">
          <div className="button-basic">Telusuri File</div>
        </div>
        <p style={{ marginTop: 20 }}>
          atau tarik beberapa file sekaligus kesini
        </p>
        <em>(maximum 3 files)</em>
      </div>
    </div>
  );
};

export default DropzoneMultiple;
