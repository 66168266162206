import { message } from "antd";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";

import {
  getOperatingHours,
  updateOperatingHours,
} from "@services/settings.services";
import {
  convertSelectCloseHour,
  convertSelectOpenHour,
} from "@utils/convertData";

export const OperationalHourLogic = () => {
  const defaultOpeningHours = {
    label: "Buka 24 jam",
    value: "24:00",
  };
  const defaultClosingHours = "";

  const [isDisplaySchedule, setisDisplaySchedule] = useState(false);
  const [openingHours, setOpeningHours] = useState({
    sunday: defaultOpeningHours,
    monday: defaultOpeningHours,
    tuesday: defaultOpeningHours,
    wednesday: defaultOpeningHours,
    thursday: defaultOpeningHours,
    friday: defaultOpeningHours,
    saturday: defaultOpeningHours,
  });
  const [closingHours, setClosingHours] = useState({
    sunday: defaultClosingHours,
    monday: defaultClosingHours,
    tuesday: defaultClosingHours,
    wednesday: defaultClosingHours,
    thursday: defaultClosingHours,
    friday: defaultClosingHours,
    saturday: defaultClosingHours,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getOperatingHours();
      setOpeningHours({
        ...openingHours,
        sunday: isEmpty(res.sunday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.sunday),
        monday: isEmpty(res.monday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.monday),
        tuesday: isEmpty(res.tuesday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.tuesday),
        wednesday: isEmpty(res.wednesday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.wednesday),
        thursday: isEmpty(res.thursday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.thursday),
        friday: isEmpty(res.friday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.friday),
        saturday: isEmpty(res.saturday)
          ? defaultClosingHours
          : convertSelectOpenHour(res.saturday),
      });
      setClosingHours({
        ...closingHours,
        sunday: isEmpty(res.sunday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.sunday),
        monday: isEmpty(res.monday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.monday),
        tuesday: isEmpty(res.tuesday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.tuesday),
        wednesday: isEmpty(res.wednesday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.wednesday),
        thursday: isEmpty(res.thursday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.thursday),
        friday: isEmpty(res.friday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.friday),
        saturday: isEmpty(res.saturday)
          ? defaultClosingHours
          : convertSelectCloseHour(res.saturday),
      });
    } catch (error) {
      console.log("cannot get data");
    }
  };

  const onSelectOpeningHours = (e, params) => {
    if (isNil(e) && openingHours[params].label === "Buka 24 jam") {
      setOpeningHours({ ...openingHours, [params]: defaultClosingHours });
      setClosingHours({ ...closingHours, [params]: defaultClosingHours });
    } else if (isNil(e) && openingHours[params].label === "Tutup") {
      setOpeningHours({ ...openingHours, [params]: defaultClosingHours });
      setClosingHours({ ...closingHours, [params]: defaultClosingHours });
    } else if (isNil(e)) {
      setOpeningHours({ ...openingHours, [params]: defaultClosingHours });
    } else if (e.label === "Buka 24 jam") {
      setOpeningHours({ ...openingHours, [params]: e });
      setClosingHours({ ...closingHours, [params]: { label: "", value: "" } });
    } else if (e.label === "Tutup") {
      setOpeningHours({ ...openingHours, [params]: e });
      setClosingHours({
        ...closingHours,
        [params]: { label: "", value: "00:00" },
      });
    } else if (closingHours[params]?.label === "24:00 WIB") {
      setOpeningHours({ ...openingHours, [params]: e });
    } else {
      if (isEmpty(closingHours[params])) {
        setOpeningHours({ ...openingHours, [params]: e });
      } else if (e.value > closingHours[params].value) {
        message.error("jam tutup tidak boleh lebih awal dari jam buka");
      } else {
        setOpeningHours({ ...openingHours, [params]: e });
      }
    }
  };

  const onSelectClosingHours = (e, params) => {
    if (isNil(e)) {
      setClosingHours({ ...closingHours, [params]: defaultClosingHours });
    } else if (e.label === "24:00 WIB") {
      setClosingHours({ ...closingHours, [params]: e });
    } else {
      if (isEmpty(openingHours[params])) {
        setClosingHours({ ...closingHours, [params]: e });
      } else if (e.value < openingHours[params].value) {
        message.error("jam tutup tidak boleh lebih awal dari jam buka");
      } else {
        setClosingHours({ ...closingHours, [params]: e });
      }
    }
  };

  const conditionalOpen = (params) => {
    if (openingHours[params]?.value === "24:00") {
      return "00:00";
    } else {
      return openingHours[params]?.value || "";
    }
  };

  const onSubmitOperatingHours = async () => {
    const data = {
      sunday: {
        close: closingHours.sunday?.value || "",
        open: conditionalOpen("sunday"),
      },
      monday: {
        close: closingHours.monday?.value || "",
        open: conditionalOpen("monday"),
      },
      tuesday: {
        close: closingHours.tuesday?.value || "",
        open: conditionalOpen("tuesday"),
      },
      wednesday: {
        close: closingHours.wednesday?.value || "",
        open: conditionalOpen("wednesday"),
      },
      thursday: {
        close: closingHours.thursday?.value || "",
        open: conditionalOpen("thursday"),
      },
      friday: {
        close: closingHours.friday?.value || "",
        open: conditionalOpen("friday"),
      },
      saturday: {
        close: closingHours.saturday?.value || "",
        open: conditionalOpen("saturday"),
      },
    };
    if (
      (isNil(openingHours.sunday?.value) && closingHours.sunday?.value) ||
      (openingHours.sunday?.value && isNil(closingHours.sunday?.value)) ||
      (isNil(openingHours.monday?.value) && closingHours.monday?.value) ||
      (openingHours.monday?.value && isNil(closingHours.monday?.value)) ||
      (isNil(openingHours.tuesday?.value) && closingHours.tuesday?.value) ||
      (openingHours.tuesday?.value && isNil(closingHours.tuesday?.value)) ||
      (isNil(openingHours.wednesday?.value) && closingHours.wednesday?.value) ||
      (openingHours.wednesday?.value && isNil(closingHours.wednesday?.value)) ||
      (isNil(openingHours.thursday?.value) && closingHours.thursday?.value) ||
      (openingHours.thursday?.value && isNil(closingHours.thursday?.value)) ||
      (isNil(openingHours.friday?.value) && closingHours.friday?.value) ||
      (openingHours.friday?.value && isNil(closingHours.friday?.value)) ||
      (isNil(openingHours.saturday?.value) && closingHours.saturday?.value) ||
      (openingHours.saturday?.value && isNil(closingHours.saturday?.value))
    ) {
      message.error(
        `jika jam buka diisi, jam tutup juga harus diisi. atau sebaliknya`
      );
    } else {
      try {
        await updateOperatingHours(data);
        await setisDisplaySchedule(false);
        await message.success(`Berhasil update jam operasional`);
        await getData();
      } catch (error) {
        await message.error(`${error.data.message}`);
      }
    }
  };

  return {
    isDisplaySchedule,
    setisDisplaySchedule,
    openingHours,
    setOpeningHours,
    closingHours,
    setClosingHours,
    onSelectOpeningHours,
    onSelectClosingHours,
    onSubmitOperatingHours,
  };
};
