import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { userProfileAtom } from "@store/user.store";
import { updateUser } from "@services/user.services";
import { getUserProfile } from "@services/auth.services";
import { message } from "antd";
import { getListBankVa } from "@services/salesReports.services";
import { convertBankVaSelect } from "@utils/convertData";

export const BankAccountFieldLogic = () => {
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [bank, setBank] = useState(userProfile.partnerBankAccount || []);
  const [bankList, setBankList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    getBanklist();
  }, []);

  const getBanklist = async () => {
    try {
      const res = await getListBankVa();
      setBankList(convertBankVaSelect(res.transactionVa));
    } catch (err) {
      console.error(err);
    }
  };

  const manipulatePayload = (data) =>
    data.map((x) => ({
      bank_name: x.bankName || "",
      account_name: x.accountName || "",
      bank_rekening: x.bankRekening || "",
      bank_code: x.bankCode || "",
      bank_icon: x.bankIcon || "",
    }));

  const onRemove = async (index, item) => {
    const data = bank.filter((item, idx) => idx !== index);
    const dataDelete = bank.filter((item, idx) => idx == index);
    const dataBank = manipulatePayload(data);
    const payload = { ...userProfile, partnerBankAccount: dataBank };
    delete payload["orgId"];
    delete payload["partnerId"];
    delete payload["warehouseId"];
    delete payload["locale"];
    delete payload["fulfillmentRate"];
    setBank(data);

    if (bank.length === 1) {
      setBank([{ accountName: "", bankName: "", bankRekening: "" }]);
    } else {
      try {
        await updateUser(payload);
        const user = await getUserProfile();
        await setUserProfile(user);
        await message.success(
          `Berhasil menghapus Rekening - "${dataDelete[0].accountName}"`
        );
      } catch (err) {
        console.log("remove rekening location error: ", err);
      }
    }
  };

  const onChangeName = (e, idx) => {
    const data = bank.map((item, id) =>
      id === idx ? { ...item, accountName: e } : item
    );
    setBank(data);
  };

  const onChangeBankRek = (e, idx) => {
    const data = bank.map((item, id) =>
      id === idx ? { ...item, bankRekening: e } : item
    );
    setBank(data);
  };

  const onAddRek = () => {
    const newData = {
      accountName: "",
      bankName: "",
      bankRekening: "",
    };
    bank.length >= 3
      ? message.error(`maximal 3 rekening Bank`)
      : setBank([...bank, newData]);
  };

  const onSelectBank = (dataBank, idx) => {
    const bankListFilter = bankList.filter((x) => dataBank === x.label)[0];
    const data = bank.map((item, id) =>
      id === idx
        ? {
            ...item,
            bankName: dataBank,
            bankIcon: bankListFilter.icon,
            bankCode: bankListFilter.code,
          }
        : item
    );
    setBank(data);
  };

  const onSubmit = async () => {
    const dataBank = manipulatePayload(bank);
    const payload = { ...userProfile, partnerBankAccount: dataBank };
    const types = dataBank.filter((x) => Object.values(x).includes(""));
    delete payload["orgId"];
    delete payload["partnerId"];
    delete payload["warehouseId"];
    delete payload["locale"];
    delete payload["fulfillmentRate"];
    delete payload["pointRewardSystem"];
    if (payload.partnerBankAccount.length == 0) {
      message.warning(`Harap melengkapi data rekening !`);
    } else {
      try {
        await updateUser(payload);
        const user = await getUserProfile();
        await setUserProfile(user);
        await setIsEditMode(false);
        await message.success(`Info Rekening berhasil disimpan`);
      } catch (error) {
        await message.error(`${error.data.message}`);
      }
    }
  };

  return {
    onChangeName,
    onRemove,
    bank,
    onAddRek,
    onSubmit,
    onChangeBankRek,
    onSelectBank,
    isEditMode,
    setIsEditMode,
    bankList,
  };
};
