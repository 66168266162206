import React, { useEffect } from "react";
import Joyride from "react-joyride";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { isNil, isEmpty } from "lodash";

import logo from "@assets/logo.png";
import iconProfile from "@assets/pic-2.png";
import { userProfileAtom } from "@store/user.store";
import { useAtom } from "jotai";
import { isDisplayDrawerAtom } from "@store/config.store";
import { routes } from "../routes";
import Submenu from "./Submenu";
import {
  partnerPopupPayloadAtom,
  runAtom,
  stepIndexAtom,
  stepsAtom,
  isFirstDisplayAtom,
} from "@store/joyride.store";
import {
  notifAtom,
  unReadNotifAtom,
  pageAtom,
  lastPageAtom,
  sizePerPageAtom,
  totalSizeAtom,
} from "@store/notification.store";

import { getJoyrideAnnounce, setJoyrideAnnounce } from "@utils/memory";
import { getAnnouncementPopup } from "@services/announcement.services";
import { getListNotifications } from "@services/notification.services";

import Tooltip from "@layout/components/Tooltip";
import { getJoyrideWelcome, setJoyrideWelcome } from "../utils/memory";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userProfile] = useAtom(userProfileAtom);
  const [, setIsOpen] = useAtom(isDisplayDrawerAtom);

  const poCondition =
    userProfile?.partnerType == "B2B" ||
    userProfile?.partnerType == "Consignment";
  const dhCondition =
    userProfile?.partnerType == "Marketplace" ||
    userProfile?.partnerType == "Consignment MP";

  const [stepIndex, setStepIndex] = useAtom(stepIndexAtom);
  const [partnerPopupPayload, setPartnerPopupPayload] = useAtom(
    partnerPopupPayloadAtom
  );
  const [steps, setSteps] = useAtom(stepsAtom);
  const [run, setRun] = useAtom(runAtom);
  const [, setIsFirstDisplay] = useAtom(isFirstDisplayAtom);

  const [notif, setNotif] = useAtom(notifAtom);
  const [, setUnReadNotif] = useAtom(unReadNotifAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [, setLastPage] = useAtom(lastPageAtom);
  const [sizePerPage, setSizePerPage] = useAtom(sizePerPageAtom);
  const [, setTotalSize] = useAtom(totalSizeAtom);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getNotifications(page, sizePerPage);
  }, [location.pathname]);

  const getNotifications = async (pages, limit) => {
    const params = {
      page: pages,
      limit: limit,
    };
    try {
      const res = await getListNotifications(params);
      setNotif(res?.hasOwnProperty("notifications") ? res?.notifications : []);
      setPage(parseInt(res?.pagination.currentPage));
      setLastPage(parseInt(res?.pagination.LastPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setUnReadNotif(
        res?.hasOwnProperty("unreadCount") ? parseInt(res?.unreadCount) : 0
      );
    } catch (err) {
      console.log("get notification: ", err);
    }
  };

  const getAllData = async () => {
    const joyride = await getJoyrideAnnounce();
    const joyrideWelcome = await getJoyrideWelcome();
    try {
      const res = await getAnnouncementPopup();
      if (isEmpty(res)) {
        setRun(false);
      } else {
        const steps = await [
          {
            target: ".my-first-step",
            title: res.title,
            content:
              "Partner Belanjaparts, dapatkan kemudahan baru dengan fitur terbaru kami. Ayo pelajari tutorialnya sekarang!",
            disableBeacon: true,
            placement: "right",
          },
          {
            target: "#my-other-step",
            content: res?.description,
            ...res,
          },
        ];

        const welcomeSteps = [
          {
            target: ".welcome-step",
            title:
              "Selamat datang Partner Belanjaparts! Tutorial tersedia buat membantu Kamu dalam menggunakan dashboard ini",
            content: "Yuk pelajari dan klik 'Menu Tutorial'",
            disableBeacon: true,
            placement: "right",
          },
          {
            target: "#welcome-topic",
            title: "1. Pilih Topik",
            content:
              "Kamu bisa memilih topik yang sesuai dengan apa yang ingin kamu pelajari di dalam dashboard ini",
          },
          {
            target: "#welcome-subtopic",
            title: "2. Sub-Topik",
            content:
              "Setiap topik terdiri dari beberapa sub-topik yang akan membahas materi tertentu secara lebih detail. Klik sub-topik yang ingin kamu pelajari untuk lanjut ke halaman materi.",
          },
        ];
        setSteps(steps);
        setPartnerPopupPayload(res);
        if (window.innerWidth < 800) {
          setRun(false);
        } else if (isNil(joyrideWelcome)) {
          if (isNil(joyride)) {
            setSteps(welcomeSteps);
            setRun(true);
          } else {
            setSteps(steps);
            if (
              res?.idTopic === joyride.idTopic &&
              res?.idSubTopic === joyride.idSubTopic
            ) {
              setRun(false);
            } else {
              setRun(true);
            }
          }
        } else if (isNil(joyride)) {
          setRun(true);
        } else if (
          res?.idTopic === joyride.idTopic &&
          res?.idSubTopic === joyride.idSubTopic
        ) {
          setRun(false);
        } else {
          setRun(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { action, index, type } = data;
    const joyrideWelcome = await getJoyrideWelcome();
    const joyride = await getJoyrideAnnounce();

    if (action === "next" && index === 0) {
      navigate("/training-material");
    }
    if (type === "tooltip" && action === "update" && stepIndex === 1) {
      if (isNil(joyrideWelcome)) {
        if (isNil(joyride)) {
          setJoyrideWelcome("WelcomeTutorial");
          setStepIndex(null);
          setIsFirstDisplay(true);
        } else {
          setJoyrideAnnounce(partnerPopupPayload);
        }
      } else {
        setJoyrideAnnounce(partnerPopupPayload);
      }
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div>
          <img
            src={logo}
            alt="logo"
            className="logo cursor-pointer"
            onClick={() => navigate("/purchased-order")}
          />
        </div>
      </div>
      <div className="sidebar-navbar">
        <img src={iconProfile} alt="icon-profile" className="icon-profile" />
        <p className="font-bold text-base pt-14 text-white text-center">
          {userProfile?.name}
        </p>
        <p className="text-base mt-2 text-white text-center">
          {userProfile?.locale} | {userProfile?.warehouseId}
        </p>
        <div className="wrapper-nav">
          {/* <NavLink
            to='/user'
            className='navbar-side flex items-center justify-start'
            activeclassname='active'>
            <FontAwesomeIcon icon={faUser} className='mr-2' />
            <p className='text-xs'>USER</p>
          </NavLink> */}
          {routes.map((route, idx) => {
            if (route.menu) {
              if (route.submenu) {
                return (
                  <Submenu
                    key={idx}
                    icon={route.icon}
                    label={route.label}
                    subRoutes={route.subRoutes}
                    onClick={() => setIsOpen(false)}
                  />
                );
              }
              if (route.path === "/purchased-order") {
                return (
                  poCondition && (
                    <NavLink
                      key={idx}
                      to="/purchased-order"
                      onClick={() => setIsOpen(false)}
                      className="navbar-side flex items-center"
                      activeclassname="active"
                    >
                      <FontAwesomeIcon icon={faWallet} className="mr-2" />
                      <p className="text-xs">PURCHASE ORDER</p>
                    </NavLink>
                  )
                );
              }
              if (route.path === "/disbursement-history") {
                return (
                  dhCondition && (
                    <NavLink
                      key={idx}
                      to="/disbursement-history"
                      onClick={() => setIsOpen(false)}
                      className="navbar-side flex items-center"
                      activeclassname="active"
                    >
                      <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                      <p className="text-xs">DISBURSEMENT HISTORY</p>
                    </NavLink>
                  )
                );
              }
              if (route.path === "/training-material") {
                return (
                  <NavLink
                    key={idx}
                    to={route.path}
                    onClick={() => setIsOpen(false)}
                    className="welcome-step my-first-step navbar-side flex items-center"
                    activeclassname="active"
                  >
                    <FontAwesomeIcon icon={route.icon} className="mr-2" />
                    <p className="text-xs text-uppercase">{route.label}</p>
                  </NavLink>
                );
              }
              return (
                <NavLink
                  key={idx}
                  to={route.path}
                  onClick={() => setIsOpen(false)}
                  className="navbar-side flex items-center"
                  activeclassname="active"
                >
                  <FontAwesomeIcon icon={route.icon} className="mr-2" />
                  <p className="text-xs text-uppercase">{route.label}</p>
                </NavLink>
              );
            }
          })}
        </div>
      </div>
      <Joyride
        run={run}
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        hideCloseButton
        scrollToFirstStep
        showProgress={false}
        showSkipButton={false}
        stepIndex={stepIndex}
        hideBackButton={true}
        disableOverlayClose
        disableCloseOnEsc
        disableScrolling={location?.pathname !== "/training-material"}
        locale={{ next: "Lanjut", last: "Ok" }}
        tooltipComponent={Tooltip}
        styles={{
          buttonNext: {
            background: "#01573e",
          },
          spotlight: {
            marginLeft: isNil(stepIndex) ? -10 : 0,
          },
        }}
      />
    </div>
  );
};

export default Sidebar;
