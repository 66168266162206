// eslint-disable-next-line
import { useAtom } from "jotai";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { getListPo, getGeneratePo } from "@services/po.services";
import { getListCutoff } from "@services/dictionaries.services";
import { statusListsAtom } from "@store/dictionaries";
import { poDataAtom, poListAtom } from "@store/po.store";
import { userProfileAtom } from "@store/user.store";
import { statusColor } from "@utils/conditional";
import { useDebounce } from "@utils/delay";
import { formatPriceIdr } from "@utils/format";
import { generalDate } from "@utils/formatDate";

const resManipulate = (data) =>
  data.map((item) => ({
    id: item.id,
    label: item.value,
    value: item.value,
  }));

export const PoListLogic = () => {
  const navigate = useNavigate();

  const [poData, setPoData] = useAtom(poDataAtom);
  const [poList, setPoList] = useAtom(poListAtom);
  const [statusLists] = useAtom(statusListsAtom);
  const [userProfile] = useAtom(userProfileAtom);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [poNumber, setPoNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [poDateFrom, setPoDateFrom] = useState(null);
  const [poDateTo, setPoDateTo] = useState(null);
  const [fullfilmentDateFrom, setFullfilmentDateFrom] = useState([]);
  const [fullfilmentDateTo, setFullfilmentDateTo] = useState([]);
  const [cutOffOpt, setCutOffOpt] = useState([]);
  const [cutOff, setCutOff] = useState({
    value: "",
    label: "",
  });
  const [poDateFromModal, setPoDateFromModal] = useState(null);
  const [poDateToModal, setPoDateToModal] = useState(null);

  useEffect(() => {
    getData(page, limit);
    getCutOffOpt();
  }, []);

  const getCutOffOpt = async () => {
    try {
      const res = await getListCutoff();
      setCutOffOpt(resManipulate(res.entries));
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async (
    page,
    limit,
    poNumber,
    status,
    poDateFrom,
    poDateTo,
    fullfilmentDateFrom,
    fullfilmentDateTo
  ) => {
    setLoading(true);
    const params = {
      page,
      limit,
      po_number: poNumber,
      status: status,
      po_date_from: poDateFrom,
      po_date_to: poDateTo,
      fullfilment_date_from: fullfilmentDateFrom,
      fullfilment_date_to: fullfilmentDateTo,
    };
    try {
      const res = await getListPo(params);
      if (isEmpty(res?.purchaseOrders)) {
        setPoList([]);
      } else {
        setPoData(res);
        setPoList(res?.purchaseOrders);
        setPage(parseInt(res?.pagination.currentPage));
        setTotalSize(parseInt(res?.pagination.Count));
        setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "poRefNum",
      text: "No. PO",
      formatter: (cell, row) => (
        <NavLink
          to={`/purchased-order/${row.purchaseOrderId}`}
          className='cursor-pointer underline hover:underline text-black'
          activeclassname='cursor-pointer underline'>
          {row.poRefNum}
        </NavLink>
      ),
    },
    {
      dataField: "poDate",
      text: "Tanggal PO",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD/MM/YYYY, HH:mm")}</div>
      ),
    },
    {
      dataField: "fullfilmentDate",
      text: "Tanggal Fulfillment",
      formatter: (cell, row) => <div>{generalDate(cell)}</div>,
    },
    {
      dataField: "merchantBillingNo",
      text: "No. Billing",
      formatter: (cell, row) => cell || "-",
    },
    {
      dataField: "merchantBillingNoTimestamp",
      text: "Tanggal Billing",
      formatter: (cell, row) => (
        <div>{cell ? moment(cell).format("DD/MM/YYYY, HH:mm") : "-"}</div>
      ),
    },
    {
      dataField: "total",
      text: "Total Harga",
      formatter: (cell, row) => <div>{formatPriceIdr(cell?.number)}</div>,
    },
    {
      dataField: "paidTimestamp",
      text: "Tanggal Pembayaran",
      formatter: (cell, row) => (
        <div>{cell ? moment(cell).format("DD/MM/YYYY, HH:mm") : "-"}</div>
      ),
    },
    {
      dataField: "status",
      text: "Status PO",
      formatter: (cell, row) => (
        <div
          className={`${statusColor(
            cell
          )} p-2 flex items-center justify-center rounded text-white`}>
          {cell}
        </div>
      ),
    },
  ];

  const onTableChange = () => {};

  const onPageChange = (e) => {
    getData(
      e,
      limit,
      poNumber,
      status,
      poDateFrom,
      poDateTo,
      fullfilmentDateFrom,
      fullfilmentDateTo
    );
  };

  const onLimitChange = (e) => {
    getData(
      1,
      e,
      poNumber,
      status,
      poDateFrom,
      poDateTo,
      fullfilmentDateFrom,
      fullfilmentDateTo
    );
    setLimit(e);
  };

  const handleSearch = useDebounce(async (value, e) => {
    getData(
      1,
      limit,
      e.target.value,
      status,
      poDateFrom,
      poDateTo,
      fullfilmentDateFrom,
      fullfilmentDateTo
    );
    setPoNumber(e.target.value);
  }, 1000);

  const onSelectStatus = (e) => {
    getData(
      1,
      limit,
      poNumber,
      e,
      poDateFrom,
      poDateTo,
      fullfilmentDateFrom,
      fullfilmentDateTo
    );
    setStatus(e);
  };

  const onFilterPoModal = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;
    setPoDateFromModal(tempStartDate);
    setPoDateToModal(tempEndDate);
  };

  const onGeneratePoDate = async () => {
    const param = {
      startDate: poDateFromModal,
      endDate: poDateToModal,
    };
    try {
      await getGeneratePo(param);
      message.success(`Success generate purchase order`);
      getData(
        page,
        limit,
        poNumber,
        status,
        poDateFrom,
        poDateTo,
        fullfilmentDateFrom,
        fullfilmentDateTo
      );
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onClearData = () => {
    setPoDateFromModal(null);
    setPoDateToModal(null);
  };

  const onFilterPo = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("DD-MM-YYYY")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("DD-MM-YYYY")}`;
    getData(
      1,
      limit,
      poNumber,
      status,
      tempStartDate,
      tempEndDate,
      fullfilmentDateFrom,
      fullfilmentDateTo
    );
    setPoDateFrom(tempStartDate);
    setPoDateTo(tempEndDate);
  };

  const onFilterFullfilment = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("DD-MM-YYYY")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("DD-MM-YYYY")}`;
    getData(
      1,
      limit,
      poNumber,
      status,
      poDateFrom,
      poDateTo,
      tempStartDate,
      tempEndDate
    );
    setFullfilmentDateFrom(tempStartDate);
    setFullfilmentDateTo(tempEndDate);
  };

  return {
    columns,
    poData,
    poList,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    statusLists,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    onSelectStatus,
    onFilterPo,
    onFilterFullfilment,
    cutOffOpt,
    userProfile,
    onFilterPoModal,
    onGeneratePoDate,
    onClearData,
    poDateFromModal,
    poDateToModal,
  };
};
