// eslint-disable-next-line
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Popover, message } from "antd";
import { isEmpty } from "lodash";
import { useAtom } from "jotai";
import Zoom from "react-medium-image-zoom";

import {
  getListCampaignEvents,
  registerCampaignEvent,
} from "@services/campaignEvent.services";
import Button from "@components/atom/Button";
import UploadCSVTemplateApi from "@components/molekul/UploadCSVTemplateApi";
import { userProfileAtom } from "@store/user.store";
import { mapStatusCampaignEvent, mapStatusReview } from "@utils/conditional";
import { mapCampaignStatus } from "@utils/conditional";
import { generateListSku } from "@services/campaignEvent.services";

export const CampaignEventListLogic = () => {
  const [userProfile] = useAtom(userProfileAtom);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit) => {
    setLoading(true);
    const params = {
      limit,
      page,
    };
    try {
      const res = await getListCampaignEvents(params);
      isEmpty(res.campaignEvents) ? setData([]) : setData(res.campaignEvents);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "banner",
      text: "Banner",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "400px" };
      },
      formatter: (cell, row) => (
        <div className='text-center'>
          <Zoom zoomMargin={100}>
            <img src={cell} alt='banner' className='w-40 h-40 object-contain' />
          </Zoom>
        </div>
      ),
    },
    {
      dataField: "name",
      text: "Nama",
      headerStyle: () => {
        return { width: "400px" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell) => (
        <Popover content={cell} title='Description'>
          <p
            style={{
              width: 200,
              cursor: "pointer",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {cell}
          </p>
        </Popover>
      ),
    },
    {
      dataField: "created",
      text: "Tanggal",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "800px" };
      },
      formatter: (cell, row) => (
        <div className='text-center'>
          {moment(row.startDate).format("DD/MM/YYYY")} -{" "}
          {moment(row.endDate).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      dataField: "type",
      text: "Tipe",
    },
    {
      dataField: "voucherAttributes",
      text: "Kode Voucher",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "400px" };
      },
      formatter: (cell, row) => (
        <div className='text-center'>
          {cell.map((item, key) => (
            <p key={key}>{item.code}</p>
          ))}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Campaign Status",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "400px" };
      },
      formatter: (cell, row) => (
        <div
          className={`${mapStatusCampaignEvent(
            row?.status
          )} p-2 flex items-center justify-center rounded`}>
          <p className='text-black'>{mapCampaignStatus(row?.status)}</p>
        </div>
      ),
    },
    {
      dataField: "id",
      text: "Action",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "400px" };
      },
      formatter: (cell, row) => (
        <div className='d-flex items-center justify-start'>
          <Button
            textButton='Download List SKU'
            onClick={() => onDownloadListSKU(row.id)}
            style={{ width: 200, marginRight: 5 }}
          />
          {checkRegisteredSeller(row)}
        </div>
      ),
    },
  ];

  const checkRegisteredSeller = (row) => {
    const { registeredPendingSeller, listParticipatingSeller } = row;

    const filterReviewSeller = registeredPendingSeller?.find(
      (item) => item === userProfile.warehouseId
    );
    const filterApprovedSeller = listParticipatingSeller?.find(
      (item) => item === userProfile.warehouseId
    );
    if (filterApprovedSeller) {
      return (
        <div
          className={`${mapStatusReview(
            "Approved"
          )} p-2 flex items-center justify-center rounded`}>
          <p className='text-black'>Approved</p>
        </div>
      );
    } else if (filterReviewSeller) {
      return (
        <div
          className={`${mapStatusReview(
            "In Review"
          )} p-2 flex items-center justify-center rounded`}>
          <p className='text-black'>In Review</p>
        </div>
      );
    } else {
      if (row.status === "Pending") {
        if (row.type === "Exclusive") {
          return (
            <UploadCSVTemplateApi
              onUpload={(file) => onExclusiveRegister(row.id, file)}
              title='Upload CSV'
              textButton='Register'
              onDownloadTemplate={() => onDownloadListSKU(row.id)}
            />
          );
        } else {
          return (
            <Button
              textButton='Register'
              onClick={() => onFreeForAllRegister(row.id)}
            />
          );
        }
      } else {
        return null;
      }
    }
  };

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  const onDownloadListSKU = async (campaign_event_id) => {
    const data = {
      campaign_event_id,
    };
    try {
      const res = await generateListSku(data);
      await window.open(res.csvUrl, "_blank");
    } catch (error) {}
  };

  const onExclusiveRegister = async (campaign_event_id, csv_request_file) => {
    const data = {
      campaign_event_id,
      csv_request_file,
    };
    try {
      await registerCampaignEvent(data);
      await getData(page, limit);
      await message.success("success register campaign");
    } catch (error) {
      await message.error(error.data.message);
    }
  };

  const onFreeForAllRegister = (campaign_event_id) => {
    const data = {
      campaign_event_id,
    };
    return Modal.confirm({
      title: "Konfirmasi",
      content: `Apakah anda akan daftar campaign event?`,
      okText: "Ok",
      cancelText: "Batal",
      onOk: async () => {
        try {
          await registerCampaignEvent(data);
          await getData(page, limit);
          await message.success("success register campaign");
        } catch (error) {
          await message.error(error.data.message);
        }
      },
    });
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
  };
};
