import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";

import { PaylaterSettingsEditLogic } from "./PaylaterSettingsEdit.logic";
import Button from "@components/atom/Button";

const PaylaterSettingsEdit = (props) => {
  const {
    whitelists,
    onChange,
    onSubmit,
    onBack,
    onChangeCreditLimit,
    isLoading,
    loadOptions,
  } = PaylaterSettingsEditLogic(props);

  return (
    <div>
      <div className=' flex w-1/2'>
        <p
          className='font-bold text-xl py-10 pl-3 cursor-pointer'
          onClick={onBack}>
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2 font-bold' />
          Edit Whitelist
        </p>
      </div>
      <table className='w-full'>
        <thead>
          <tr>
            <th className='pl-3 w-1/3'>Kode Bengkel</th>
            <th className='pl-3 w-1/3'>Credit Limit (Rp)</th>
            <th className='pl-3 w-1/3'>Max Credit Limit (Rp)</th>
          </tr>
        </thead>
        <tbody>
          {whitelists.map((whitelist, index) => {
            return (
              <tr key={index}>
                <td className='p-3 w-1/3'>
                  <AsyncSelect
                    isClearable
                    cacheOptions
                    defaultOptions
                    placeholder='Tulis Kode Bengkel'
                    loadOptions={loadOptions}
                    onChange={(e) => onChange(e, index, "workshopId")}
                    menuPortalTarget={document.querySelector("body")}
                    noOptionsMessage={() => "Tulis Kode Bengkel"}
                    value={whitelist.workshopId}
                  />
                </td>
                <td className='p-3 w-1/3'>
                  <input
                    type='text'
                    className='form-control'
                    style={{ minHeight: 38 }}
                    value={whitelist.credit || ""}
                    onChange={(e) => onChangeCreditLimit(e, index, "credit")}
                  />
                </td>
                <td className='p-3 w-1/3'>
                  <input
                    type='text'
                    className='form-control'
                    style={{ minHeight: 38 }}
                    value={whitelist.maxCreditLimit || ""}
                    onChange={(e) =>
                      onChangeCreditLimit(e, index, "maxCreditLimit")
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='p-3'>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <Button
            textButton={isLoading ? "Loading..." : "Submit"}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PaylaterSettingsEdit;
