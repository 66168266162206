import { isEmpty, isNil, parseInt } from "lodash";
import { formatNum } from "@utils/format";

export const convertStatusToSelect = (data) => {
  return data.map((item) => ({
    value: item.value,
    label: item.value,
  }));
};

export const convertSkuToSelect = (data) => {
  if (data) {
    return data.map((item) => ({
      value: item.skuInternal,
      label: `${item.name} - Stock ${formatNum(item.qty)}`,
    }));
  }
};

export const convertTermOfPaymentToSelect = (data) => {
  if (data) {
    return data.map((item) => ({
      value: item.value,
      label: item.value,
    }));
  }
};

export const convertPartialReject = (row) => {
  const data = row.transactionDetail.map((item) => ({
    secondary_product_id: item.secondaryProductId,
    reject_qty: item.rejectedQty,
  }));

  return {
    transaction_id: row.transactionId,
    request_partial_reject: data,
  };
};

export const convertSelectOpenHour = (params) => {
  if (params.open === "00:00" && isNil(params.close)) {
    return {
      label: "Buka 24 jam",
      value: "24:00",
    };
  } else if (isNil(params.open) && params.close === "00:00") {
    return {
      label: "Tutup",
      value: "",
    };
  } else if (isNil(params.open)) {
    return {
      label: "",
      value: "",
    };
  } else {
    return {
      label: `${params.open} WIB`,
      value: params.open,
    };
  }
};

export const convertSelectCloseHour = (params) => {
  if (isNil(params.open) && params.close === "00:00") {
    return {
      label: "",
      value: "00:00",
    };
  } else if (
    params.open !== "00:00" &&
    params.open.length > 0 &&
    isNil(params.close)
  ) {
    return {
      label: "24:00 WIB",
      value: "",
    };
  } else if (isNil(params.close)) {
    return {
      label: "",
      value: "",
    };
  } else {
    return {
      label: `${params.close} WIB`,
      value: params.close,
    };
  }
};

export const insertLoadingFalse = (data, transaction_id) => {
  return data.map((item) =>
    item.transactionId === transaction_id ? { ...item, isLoading: false } : item
  );
};

export const insertTrxDetail = (data, transaction_id, res) => {
  return data.map((item) =>
    item.transactionId === transaction_id
      ? { ...item, transactionDetail: res.Details, isLoading: false }
      : item
  );
};

export const mappingAreaCity = (
  dataCoverageArea,
  e,
  index,
  districtOptions
) => {
  return dataCoverageArea.map((item, id) =>
    id === index
      ? {
          ...item,
          city: e,
          district: null,
          districtOptions,
        }
      : item
  );
};

export const mappingAreaDistrict = (dataCoverageArea, e, index) => {
  return dataCoverageArea.map((item, id) =>
    id === index
      ? {
          ...item,
          district: e,
        }
      : item
  );
};

export const convertStatus = (status) => {
  return {
    value: status,
    label: status,
  };
};

export const findInvoiceDiscount = (voucherDiscounts) => {
  if (isEmpty(voucherDiscounts)) {
    return null;
  }
  return voucherDiscounts.find((voucher) => !voucher.is_free_shipping);
};

export const findShippingDiscount = (voucherDiscounts) => {
  if (isEmpty(voucherDiscounts)) {
    return null;
  }
  return voucherDiscounts.find((voucher) => voucher.is_free_shipping);
};

export const convertBankVaSelect = (data) => {
  if (data) {
    return data.map((item) => ({
      value: item.name,
      label: item.name,
      code: item.code,
      icon: item.icon,
    }));
  }
};
export const convertDataToSelect = (data, params) => {
  if (data) {
    return data.map((item) => ({
      value: item[params],
      label: item[params],
      userId: item.id,
    }));
  }
};

export const convertSubmitWhitelist = (data) => {
  return data.map((item) => ({
    userId: item.workshop_id.userId,
    workshopId: item.workshop_id.value,
    credit: parseInt(item.credit),
    maxCreditLimit: parseInt(item.credit),
    active: true,
  }));
};

export const convertEditWhitelist = (item) => {
  return [
    {
      ...item,
      workshopId: {
        value: item.workshopId,
        label: item.workshopId,
      },
    },
  ];
};
