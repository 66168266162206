import React from "react";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { formatPriceIdr } from "@utils/format";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const ModalUpdateTrxDetail = ({
  title,
  isModalVisible,
  handleCancel,
  handleOk,
  onChangeRejectedQty,
  row,
  isLoading,
}) => {
  return (
    <div>
      <Modal
        width={"60%"}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Upload'
        footer={
          <div className='flex items-center justify-end'>
            <div className='flex items-center'>
              <div
                className='border border-gray-400 p-2 mr-2 cursor-pointer rounded'
                onClick={handleCancel}>
                Cancel
              </div>
              {isLoading ? (
                <div className='bg-green-900 p-2 text-white  cursor-pointer rounded w-24 flex items-center justify-center'>
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <div
                  className='bg-green-900 p-2 text-white  cursor-pointer rounded w-24 flex items-center justify-center'
                  onClick={handleOk}>
                  Update
                </div>
              )}
            </div>
          </div>
        }>
        <div className='t-responsive-edit-trx w-full'>
          <table className='w-full'>
            <thead className='flex items-center justify-between'>
              <tr className='w-1/2 text-gray-500'>
                <th>Nama Produk</th>
              </tr>
              <tr className='w-1/2 text-gray-500'>
                <th>SKU</th>
              </tr>
              <tr className='w-1/2 text-gray-500'>
                <th>Quantity</th>
              </tr>
              <tr className='w-1/2 text-gray-500'>
                <th>Price</th>
              </tr>
              <tr className='w-1/2 text-gray-500'>
                <th>Qty Ditolak</th>
              </tr>
            </thead>
            <tbody>
              {row?.transactionDetail.map((item, key) => (
                <tr className='flex items-center mb-2' key={key}>
                  <td className='w-1/2'>
                    <div>{item.productName}</div>
                  </td>
                  <td className='w-1/2'>
                    <div>{item.sku}</div>
                  </td>
                  <td className='w-1/2'>
                    <div>{item.qty}</div>
                  </td>
                  <td className='w-1/2'>
                    <div>
                      {(item.price &&
                        formatPriceIdr(item.price * parseInt(item.qty))) ||
                        "-"}
                    </div>
                  </td>
                  <td className='w-1/2 '>
                    <input
                      className='p-2 rounded border border-gray-300'
                      type='text'
                      value={item.rejectedQty}
                      onChange={(e) => onChangeRejectedQty(e, row, item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default ModalUpdateTrxDetail;
