import { authServices } from "@utils/setupApi";

export const getListDisbursement = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/payment/history`,
    parameter,
  });
};

export const downloadDisbursement = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/export/disbursement-report`,
    parameter,
  });
};
