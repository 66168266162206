import React from "react";
import OperationalDate from "./operationalDate/OperationalDate";
import OperationalHour from "./operationalHour/OperationalHour";
import StockReport from "./stockReport/StockReport";
import PointSystem from "./pointSystem/PointSystem";
import Reliance from "./reliance/Reliance";
import DeliveryMethod from "./deliveryMethod/DeliveryMethod";

const Settings = () => {
  return (
    <div className='py-3'>
      {/* <div className='flex items-center justify-between md:pt-0'>
        <div className='w-1/2'>
          <p className='font-bold text-xl mb-3'>Setting</p>
        </div>
      </div> */}
      <div>
        <p className='text-xl font-bold'>Pengaturan Toko</p>
        <p>
          Atur jadwal operasional toko, minimum pembelian customer, dan jadwal
          laporan stok otomatis.
        </p>
      </div>
      <PointSystem />
      <DeliveryMethod />
      <Reliance />
      <OperationalHour />
      <OperationalDate />
      <StockReport />
    </div>
  );
};

export default Settings;
