import { authServices } from "@utils/setupApi";

export const getCustomerById = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/customer/${id}`,
  });
};

export const getPointReward = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/customer/point-reward`,
    parameter,
  });
};

export const updatePointReward = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/point-reward-customer`,
    body,
  });
};
