import React from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash";
import Zoom from "react-medium-image-zoom";

import Button from "@components/atom/Button";
import ButtonOutlined from "@components/atom/ButtonOutlined";
import { CustomerDetailLogic } from "./CustomerDetail.logic";
import { formatNum } from "@utils/format";

const CustomerDetail = () => {
  const navigate = useNavigate();
  const {
    data,
    pointReward,
    loading,
    isEdit,
    onChangePoint,
    setIsEdit,
    onSubmitPoint,
  } = CustomerDetailLogic();
  return (
    <div>
      <div className='flex items-center justify-between mb-5 md:pt-0'>
        <div className='flex'>
          <FontAwesomeIcon
            size='lg'
            icon={faArrowLeft}
            className='text-2xl mr-4 cursor-pointer '
            onClick={() => navigate(-1)}
          />
          <p className='font-bold text-xl'>Customer Detail</p>
        </div>
        {loading ? null : (
          <div className='flex'>
            <ButtonOutlined
              textButton={isEdit ? "Close" : "Edit"}
              style={{ marginRight: 3 }}
              onClick={() => setIsEdit(!isEdit)}
            />
            {isEdit ? (
              <Button textButton={"Save"} onClick={onSubmitPoint} />
            ) : null}
          </div>
        )}
      </div>

      <div className='py-0'>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid d-cols-1 gap-2 '>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Full Name</p>
              <p className='font-medium'>{data?.name}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Kode Bengkel</p>
              <p className='font-medium'>{data?.workshopId}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Address</p>
              <p className='font-medium'>{data?.address}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Province</p>
              <p className='font-medium'>{data?.provinceName}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>City</p>
              <p className='font-medium'>{data?.cityName}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>District</p>
              <p className='font-medium'>{data?.districtName}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Handphone Number</p>
              <p className='font-medium'>+{data?.phone}</p>
            </div>
          </div>

          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Email Address</p>
              <p className='font-medium'>{data?.email}</p>
            </div>
          </div>

          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Plus Code</p>
              <p className='font-medium'>{data?.pluscode}</p>
            </div>
          </div>

          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>District</p>
              <p className='font-medium'>{data?.districtName}</p>
            </div>
          </div>

          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Workshop Id</p>
              <p className='font-medium'>{data?.workshopId}</p>
            </div>
          </div>

          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Zip Code</p>
              <p className='font-medium'>{data?.zipCode}</p>
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>Point Reward</p>
              {isEdit ? (
                <input
                  className='border bg-clip-padding
        border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-1/5'
                  value={pointReward?.availablePoints}
                  onChange={(e) => onChangePoint(e.target.value)}
                  placeholder='number'
                />
              ) : (
                <p className='font-medium'>
                  {formatNum(pointReward?.availablePoints || 0) || "-"}
                </p>
              )}
            </div>
          </div>
          <div>
            <div className='p-4'>
              <p className='font-medium text-gray-400'>NIK</p>
              <p className='font-medium'>{data?.nik}</p>
            </div>
          </div>
          <div className='p-4'>
            <p className='font-medium text-gray-400'>Foto NIK</p>
            {isNil(data?.ktpImageUri) ? (
              <p>-</p>
            ) : (
              <Zoom zoomMargin={100}>
                <img
                  src={data?.ktpImageUri}
                  alt='bukti-pembayaran'
                  className='w-40 h-40 object-contain'
                />
              </Zoom>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
