import React from "react";

import Button from "@components/atom/Button";
import { PaymentOptionsEditLogic } from "./PaymentOptionsEdit.logic";

const PaymentOptionsEdit = ({ onCancel, getData, limit, row }) => {
  const { errors, register, handleSubmit, onSubmit } = PaymentOptionsEditLogic({
    onCancel,
    getData,
    limit,
    row,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='mb-3'>
        <p className='font-bold'>Edit Metode Pembayaran</p>
      </div>
      <div className='mb-3'>
        <span>Nama</span>
        <input
          type='text'
          {...register("name", { required: true })}
          className='form-control'
          style={{ minHeight: 38 }}
        />
        {errors.name && (
          <span className='text-red-500'>This field is required</span>
        )}
      </div>
      <div className='mb-3'>
        <span>Deskripsi</span>
        <textarea
          rows={4}
          {...register("description", { required: true })}
          className='form-control'
          style={{ minHeight: 38 }}
        />
        {errors.description && (
          <span className='text-red-500'>This field is required</span>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col-md-6'>
          <span>Duration (Hari)</span>
          <input
            type='text'
            {...register("duration")}
            className='form-control'
            style={{ minHeight: 38 }}
          />
        </div>
        <div className='col-md-6'>
          <span>Admin Fee (%)</span>
          <input
            type='text'
            {...register("admin_fee")}
            className='form-control'
            style={{ minHeight: 38 }}
          />
        </div>
      </div>
      <div className='flex items-center justify-end'>
        <div className='mr-2'>
          <Button textButton={"Batal"} type='button' onClick={onCancel} />
        </div>
        <div>
          <Button textButton={"Submit"} type='submit' />
        </div>
      </div>
    </form>
  );
};

export default PaymentOptionsEdit;
