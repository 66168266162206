import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  faBook,
  faBox,
  faCalendar,
  faClock,
  faCog,
  faCoins,
  faKey,
  faLineChart,
  faPercentage,
  faTimes,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";

import Login from "@containers/auth/login/Login";
import User from "@containers/user/User";
import App from "../App";
import Layout from "@layout/Layout";
import SalesReport from "@containers/salesReport/SalesReport";
import PurchasedOrder from "@containers/purchasedOrder/PurchasedOrder";
import StockUpdate from "@containers/stockUpdate/StockUpdate";
import MasterProduct from "@containers/master/masterProduct/MasterProduct";
import Profile from "@containers/profile/Profile";
import UserCreate from "@containers/user/userCreate/UserCreate";
import PoDetail from "@containers/purchasedOrder/poDetail/PoDetail";
import StockUpdateCreate from "@containers/stockUpdate/stockUpdateCreate/StockUpdateCreate";
import SkuProductDetail from "@containers/master/masterProduct/skuProductDetail/SkuProductDetail";
import CustomerDetail from "@containers/salesReport/components/customerDetail/CustomerDetail";
import Analytics from "@containers/analytics/Analytics";
import Disbursement from "@containers/disbursement/Disbursement";
import TrainingMaterial from "@containers/trainingMaterial/TrainingMaterial";
import TrainingMaterialDetail from "@containers/trainingMaterial/trainingMaterialDetail/TrainingMaterialDetail";
import Settings from "@containers/settings/Settings";
import RequestPrice from "@containers/master/requestPrice/RequestPrice";
import RequestItem from "@containers/master/requestItem/RequestItem";
import CampaignEvent from "@containers/campaignEvent/CampaignEvent";
import CampaignRegistration from "@containers/master/campaignRegistration/CampaignRegistration";
import CoverageArea from "@containers/master/coverageArea/CoverageArea";
import CoverageAreaCreate from "@containers/master/coverageArea/coverageAreaCreate/CoverageAreaCreate";
import SalesReportDetail from "@containers/salesReport/salesReportDetail/SalesReportDetail";
import PaymentOptions from "@containers/master/paymentOptions/PaymentOptions";
import PaylaterSettings from "@containers/master/paylaterSettings/PaylaterSettings";
import PaylaterSettingsCreate from "@containers/master/paylaterSettings/paylaterSettingsCreate/PaylaterSettingsCreate";
import PaylaterSettingsEdit from "@containers/master/paylaterSettings/paylaterSettingsEdit/PaylaterSettingsEdit";
import LoanTransaction from "../containers/loanTransaction/LoanTransaction";

export const routes = [
  {
    path: "/user",
    component: <User />,
  },
  {
    path: "/user/create",
    component: <UserCreate />,
  },
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/analytics",
    component: <Analytics />,
    menu: true,
    label: "Analisa",
    icon: faLineChart,
  },
  {
    path: "/sales-report",
    component: <SalesReport />,
    menu: true,
    label: "Laporan Penjualan",
    icon: faCoins,
  },
  {
    path: "/sales-report/:id",
    component: <SalesReportDetail />,
  },
  {
    path: "/purchased-order",
    component: <PurchasedOrder />,
    menu: true,
    label: "Purchase Order",
    icon: faWallet,
  },
  {
    path: "/purchased-order/:id",
    component: <PoDetail />,
  },
  {
    path: "/loan-transaction",
    component: <LoanTransaction />,
    menu: true,
    label: "Transaksi Peminjaman",
    icon: faClock,
  },
  {
    path: "/stock-update",
    component: <StockUpdate />,
    menu: true,
    label: "Update Stok",
    icon: faBox,
  },
  {
    path: "/stock-update/create",
    component: <StockUpdateCreate />,
  },
  {
    path: "/product",
    component: <MasterProduct />,
    menu: true,
    label: "Master",
    icon: faKey,
    submenu: true,
    subRoutes: [
      {
        path: "/master/product",
        component: <MasterProduct />,
        menu: true,
        label: "Produk",
      },
      // {
      //   path: "/master/price-request",
      //   component: <RequestPrice />,
      //   menu: true,
      //   label: "Pengajuan Harga",
      // },
      {
        path: "/master/new-item-request",
        component: <RequestItem />,
        menu: true,
        label: "Pengajuan Barang",
      },
      {
        path: "/master/campaign-registration",
        component: <CampaignRegistration />,
        menu: true,
        label: "Pendaftaran Campaign",
      },
      {
        path: "/master/coverage-area",
        component: <CoverageArea />,
        menu: true,
        label: "Tujuan Pengiriman",
      },
      {
        path: "/master/payment-options",
        component: <PaymentOptions />,
        menu: true,
        label: "Opsi Pembayaran",
      },
      {
        path: "/master/paylater-settings",
        component: <PaylaterSettings />,
        menu: true,
        label: "Pengaturan Paylater",
      },
    ],
  },
  {
    path: "/master/coverage-area/create",
    component: <CoverageAreaCreate />,
  },
  {
    path: "/master/paylater-settings/create",
    component: <PaylaterSettingsCreate />,
  },
  {
    path: "/master/paylater-settings/edit/:id",
    component: <PaylaterSettingsEdit />,
  },
  {
    path: "/product/:id",
    component: <SkuProductDetail />,
  },
  {
    path: "/customer/:id",
    component: <CustomerDetail />,
  },
  {
    path: "/disbursement-history",
    component: <Disbursement />,
    menu: true,
    label: "Disbursement History",
    icon: faCalendar,
  },
  {
    path: "/campaign-event",
    component: <CampaignEvent />,
    menu: true,
    label: "Campaign Event",
    icon: faPercentage,
  },
  {
    path: "/training-material",
    component: <TrainingMaterial />,
    menu: true,
    label: "Tutorial",
    icon: faBook,
  },
  {
    path: "/training-material/:id",
    component: <TrainingMaterialDetail />,
  },
  {
    path: "/settings",
    component: <Settings />,
    menu: true,
    label: "Pengaturan",
    icon: faCog,
  },
];

const Index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/login' element={<Login />} />
        {routes.map((route, idx) => {
          if (route.submenu) {
            return route.subRoutes.map((submenu, id) => (
              <Route
                key={id}
                path={submenu.path}
                element={<Layout>{submenu.component}</Layout>}
              />
            ));
          }
          return (
            <Route
              key={idx}
              path={route.path}
              element={<Layout>{route.component}</Layout>}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
