export const attributeFunc = (data, param) => {
  const func =
    data && data.attribute.find((x) => x.attributeName.toLowerCase() === param);
  return func ? func.stringValue : "-";
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (upload) => {
      baseURL = upload.target.result;
      resolve(baseURL);
    };
  });
};
