import React from "react";
import { Select, Input } from "antd";

import "antd/es/input/style/css";
import "antd/es/select/style/css";

const { Option } = Select;
const { Search } = Input;

const SearchTable = ({ handleSearch, onChangeFilterOpt, filterValues }) => {
  return (
    <div>
      <div className="flex items-center justify-start w-full">
        <Select
          value={filterValues}
          style={{ width: 180 }}
          onChange={onChangeFilterOpt}
        >
          <Option value="code">SKU</Option>
          <Option value="name">Nama Produk</Option>
        </Select>
        <Search
          type="text"
          placeholder={"Cari"}
          onChange={(e) => handleSearch(e)}
        />
      </div>
    </div>
  );
};

export default SearchTable;
