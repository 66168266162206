import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { DatePicker, Input, Select } from "antd";

import SearchLimit from "@components/molekul/SearchLimit";
import { SalesReportListLogic } from "./SalesReportList.logic";
import Button from "@components/atom/Button";
import ModalUpdateTrxDetail from "../components/ModalUpdateTrxDetail";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

const filterHasPoLists = [
  {
    value: true,
    label: "Dengan PO",
  },
  { value: false, label: "Tanpa PO" },
];

const SalesReportList = () => {
  const {
    data,
    columns,
    columnsB2B,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onFilterDateRange,
    handleSearch,
    onDownloadCsv,
    profile,
    expandRow,
    selectRow,
    isEditVisible,
    handleCancel,
    handleOk,
    onChangeRejectedQty,
    row,
    isLoadingUpdatePartial,
    selectTransaction,
    setSelectTransaction,
    selectList,
    createPO,
    createHL,
    onFilterHasPo,
    onFilterBySellerStatus,
    filterSellerOptions,
  } = SalesReportListLogic();

  const selectListCondition = selectTransaction && selectList.length > 0;
  const mpCondition =
    profile?.partnerType == "Marketplace" ||
    profile?.partnerType == "Consignment MP";

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className="flex items-center justify-between md:pt-0">
              <div className="flex w-full pb-5 flex-row justify-between">
                <p className="font-bold text-xl">Laporan Penjualan</p>
                <div className="md:w-1/2 grid md:grid-cols-2 gap-3">
                  <div className="w-full">
                    <div className="flex">
                      <div className="w-32 h-8 border border-gray-300 bg-white flex items-center justify-center text-xs text-center">
                        Tanggal Transaksi
                      </div>
                      <RangePicker
                        onChange={onFilterDateRange}
                        placeholder={["Tanggal Mulai", "Tanggal Akhir"]}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex items-center justify-start w-full">
                      <div className="w-32 h-8 border border-gray-300 bg-white flex items-center justify-center text-xs">
                        No. Transaksi
                      </div>
                      <Search
                        type="text"
                        placeholder={"Cari"}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Filter Dengan Status Seller"
                      onChange={onFilterBySellerStatus}
                      allowClear
                      mode="multiple"
                    >
                      {filterSellerOptions.map((item, idx) => {
                        return (
                          <Option value={item.label} key={idx}>
                            {item.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {mpCondition ? null : (
                    <div className="w-full">
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Filter Dengan PO / Tanpa PO"
                        onChange={onFilterHasPo}
                        allowClear
                      >
                        {filterHasPoLists.map((filterHasPoList, idx) => {
                          return (
                            <Option value={filterHasPoList.value} key={idx}>
                              {filterHasPoList.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full pb-3 flex-row justify-between">
              <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              <div className="flex flex-row w-120 justify-end">
                {selectListCondition && (
                  <div className="mr-5">
                    <p className="items-center p-2">{`Transaksi Terpilih: ${selectList.length}`}</p>
                  </div>
                )}
                <Button textButton="Download CSV" onClick={onDownloadCsv} />
                <Button
                  style={{
                    backgroundColor: "#ffff",
                    color: "#01573e",
                    marginLeft: 10,
                  }}
                  textButton={`${
                    selectListCondition ? "+ Create PO" : "Pilih Transaksi"
                  }`}
                  onClick={() =>
                    selectListCondition
                      ? createPO()
                      : setSelectTransaction(!selectTransaction)
                  }
                />
                {selectListCondition && (
                  <Button
                    style={{
                      backgroundColor: "#ffff",
                      color: "#01573e",
                      marginLeft: 10,
                    }}
                    textButton={"+ Create Handover List"}
                    onClick={() =>
                      selectListCondition
                        ? createHL()
                        : setSelectTransaction(!selectTransaction)
                    }
                  />
                )}
              </div>
            </div>

            <div className="table-striped table-responsive">
              <BootstrapTable
                keyField="id"
                remote
                data={data}
                bordered={false}
                loading={loading}
                condensed
                columns={mpCondition ? columns : columnsB2B}
                expandRow={expandRow}
                selectRow={
                  selectTransaction
                    ? selectRow
                    : {
                        mode: "checkbox",
                        hideSelectColumn: true,
                        hideSelectAll: true,
                        selected: [],
                      }
                }
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className="text-center">Empty Data</div>
                )}
                {...paginationTableProps}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-muted">
                  Menunjukan{" "}
                  <span className="font-weight-bold text-dark">
                    {`${data.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {data.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
      <ModalUpdateTrxDetail
        title={"Edit Partial Reject"}
        isModalVisible={isEditVisible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        onChangeRejectedQty={onChangeRejectedQty}
        row={row}
        isLoading={isLoadingUpdatePartial}
      />
    </div>
  );
};

export default SalesReportList;
