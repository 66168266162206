import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";
import { DatePicker, Select } from "antd";
import { isEmpty } from "lodash";
import ModalFilter from "@components/molekul/ModalFilter";
import moment from "moment";

import Button from "@components/atom/Button";
import SearchLimit from "@components/molekul/SearchLimit";
import { PoListLogic } from "./PoList.logic";
import SearchTable from "../components/SearchTable";

LoadingOverlay.propTypes = undefined;

const { RangePicker } = DatePicker;
const { Option } = Select;

const PoList = () => {
  const {
    poData,
    poList,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    statusLists,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    onSelectStatus,
    onFilterPo,
    onFilterFullfilment,
    cutOffOpt,
    userProfile,
    onFilterPoModal,
    onGeneratePoDate,
    onClearData,
    poDateFromModal,
    poDateToModal,
  } = PoListLogic();

  const dateFormat = "YYYY-MM-DD";

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className="flex items-center justify-between pb-3 pt-3 md:pt-0">
              <div className="w-1/2">
                <p className="font-bold text-xl pb-10">Purchase Order</p>
                <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              </div>
              <div className="md:w-1/2 grid md:grid-cols-2 gap-3">
                <div className="w-full">
                  <div className="mb-2 flex">
                    <div className="w-32 border border-gray-300 bg-white flex items-center justify-center text-xs">
                      Tanggal PO
                    </div>
                    <RangePicker
                      placeholder={["Tanggal Mulai", "Tanggal Akhir"]}
                      onChange={onFilterPo}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-32 border border-gray-300 bg-white flex items-center justify-center text-center text-xs">
                      Tanggal Fulfillment
                    </div>
                    <RangePicker
                      placeholder={["Tanggal Mulai", "Tanggal Akhir"]}
                      onChange={onFilterFullfilment}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="mb-2">
                    <SearchTable handleSearch={handleSearch} />
                  </div>
                  <div>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Pilih Status"
                      onChange={onSelectStatus}
                      allowClear
                    >
                      {statusLists.map((status, idx) => {
                        return (
                          <Option value={status.value} key={idx}>
                            {status.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="mb-2 md:mb-0" />
                <div className="mb-2 md:mb-0 flex justify-end">
                  <ModalFilter
                    title="Buat PO"
                    list={cutOffOpt || []}
                    cutOffValue={userProfile?.cutoffTime}
                    onChangeDate={onFilterPoModal}
                    onGeneratePo={onGeneratePoDate}
                    onClear={onClearData}
                    disabled={
                      isEmpty(userProfile?.cutoffTime) ||
                      isEmpty(poDateFromModal) ||
                      poDateFromModal === poDateToModal
                    }
                    dateValue={
                      poDateFromModal !== null
                        ? [
                            moment(poDateFromModal, dateFormat),
                            moment(poDateToModal, dateFormat),
                          ]
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div className='card-po grid grid-cols-4 px-5 py-4 rounded mb-3'>
              <div>
                <p className='text-white text-sm'>Total Dana</p>
                <p className='text-white text-xl font-bold'>Rp12.456.000</p>
                <p className='text-white text-xs'>23 Mei - 23 Juni 2022</p>
              </div>
              <div>
                <p className='text-white text-sm'>Proses Pencarian</p>
                <p className='text-white text-xl  font-bold'>Rp12.456.000</p>
                <p className='text-white text-xs'>23 Mei - 23 Juni 2022</p>
              </div>
              <div>
                <p className='text-white text-sm'>Completed</p>
                <p className='text-white text-xl  font-bold'>Rp12.456.000</p>
                <p className='text-white text-xs'>23 Mei - 23 Juni 2022</p>
              </div>
              <div></div>
            </div> */}
            <div className="table-striped table-responsive">
              <BootstrapTable
                keyField="purchaseOrderId"
                remote
                data={poList}
                bordered={false}
                loading={loading}
                columns={columns}
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className="text-center">Empty Data</div>
                )}
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(192,192,192,0.3)",
                })}
                {...paginationTableProps}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-muted">
                  Menunjukan{" "}
                  <span className="font-weight-bold text-dark">
                    {`${poList.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {poList.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    </div>
  );
};

export default PoList;
