import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";
import { DatePicker, Input } from "antd";

import SearchLimit from "@components/molekul/SearchLimit";
import { DisbursementListLogic } from "./DisbursementList.logic";
import Button from "@components/atom/Button";

LoadingOverlay.propTypes = undefined;

const { RangePicker } = DatePicker;
const { Search } = Input;

const Disbursement = () => {
  const {
    data,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onFilterDateRange,
    handleSearch,
    onDownloadCsv,
  } = DisbursementListLogic();

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className="flex items-center justify-between pb-3 pt-3 md:pt-0">
              <div className="w-1/4">
                <p className="font-bold text-xl pb-10">Disbursement History</p>
                <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              </div>

              <div
                className="md:w-auto grid md:grid-cols-2"
                style={{
                  justifyItems: "end",
                  justifyContent: "flex-end",
                }}
              >
                <div className="w-auto">
                  <div
                    className="flex"
                    style={{
                      width: 300,
                      marginRight: 10,
                    }}
                  >
                    <div className="w-32 px-2 border border-gray-300 bg-white flex items-center justify-center text-xs text-center">
                      Disbursement Date
                    </div>
                    <RangePicker onChange={onFilterDateRange} />
                  </div>
                </div>
                <div className="w-auto" style={{ width: 250 }}>
                  <div className="flex">
                    <div className="w-32 border border-gray-300 bg-white flex items-center justify-center text-xs text-center">
                      Transaction
                    </div>
                    <Search
                      type="text"
                      placeholder={"Search"}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="w-auto" />

                <div className="w-auto">
                  <Button
                    textButton="Download CSV"
                    onClick={onDownloadCsv}
                    style={{
                      width: 150,
                      marginTop: 25,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="table-striped table-responsive">
              <BootstrapTable
                keyField="id"
                remote
                data={data}
                bordered={false}
                loading={loading}
                columns={columns}
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className="text-center">Empty Data</div>
                )}
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(192,192,192,0.3)",
                })}
                {...paginationTableProps}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-muted">
                  Menunjukan{" "}
                  <span className="font-weight-bold text-dark">
                    {`${data.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {data.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    </div>
  );
};

export default Disbursement;
