import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import Button from "@components/atom/Button";
import PoEdit from "../poEdit/PoEdit";
import { PoDetailLogic } from "./PoDetail.logic";
import { generalDate } from "../../../utils/formatDate";
import { formatPriceIdr } from "../../../utils/format";
import { validateUrl } from "../../../utils/stringValidation";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import { Spin } from "antd";

const PoDetail = () => {
  const {
    isEdit,
    poDetail,
    setIsEdit,
    navigate,
    onDownloadPdf,
    setPoDetail,
    isLoading,
  } = PoDetailLogic();

  return (
    <div className='po-detail'>
      {isEdit ? (
        <PoEdit isEdit={isEdit} setIsEdit={setIsEdit} />
      ) : (
        <>
          <div className='flex items-center justify-between pb-10 pt-3 md:pt-0'>
            <div className='flex'>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className='text-2xl mr-4 cursor-pointer '
                onClick={() => {
                  setPoDetail(null);
                  navigate(-1);
                }}
              />
              <p className='font-bold text-xl'>Detail Purchased Order</p>
            </div>
            {poDetail?.status === "Paid" ? null : isLoading ? null : (
              <div className='flex'>
                <Button
                  textButton={"Edit"}
                  onClick={() => setIsEdit(!isEdit)}
                />
              </div>
            )}
          </div>
          {isLoading ? (
            <div className='flex items-center justify-center'>
              <Spin />
            </div>
          ) : (
            <div>
              <div className='grid grid-cols-3 gap-5 pb-10'>
                <div>
                  <p className='pb-1'>No. PO</p>
                  <p className='font-bold'>{poDetail?.poRefNum}</p>
                </div>
                <div>
                  <p className='pb-1'>Tanggal PO</p>
                  <p className='font-bold'>{generalDate(poDetail?.poDate)}</p>
                </div>
                <div>
                  <p className='pb-1'>Total PO</p>
                  <p className='font-bold'>
                    {formatPriceIdr(poDetail?.total.number)}
                  </p>
                </div>
                <div>
                  <p className='pb-1'>Status PO</p>
                  <p className='font-bold'>{poDetail?.status}</p>
                </div>
                <div>
                  <p className='pb-1'>Tanggal Fulfillment</p>
                  <p className='font-bold'>
                    {generalDate(poDetail?.fullfilmentDate)}
                  </p>
                </div>
                <div>
                  <p className='pb-1'>ETA Payment Date</p>
                  <p className='font-bold'>
                    {generalDate(poDetail?.paymentDate)}
                  </p>
                </div>
                <div>
                  <p className='pb-1'>No Billing</p>
                  <p className='font-bold'>
                    {poDetail?.merchantBillingNo || "-"}
                  </p>
                </div>
                <div>
                  <p className='pb-1'>Tanggal Billing</p>
                  <p className='font-bold'>
                    {poDetail?.merchantBillingNoTimestamp
                      ? moment(poDetail?.merchantBillingNoTimestamp).format(
                          "DD/MM/YYYY, HH:mm"
                        )
                      : "-"}
                  </p>
                </div>

                <div>
                  <p className='pb-1'>Tanggal Pembayaran</p>
                  <p className='font-bold'>
                    {poDetail?.paidTimestamp
                      ? moment(poDetail?.paidTimestamp).format(
                          "DD/MM/YYYY, HH:mm"
                        )
                      : "-"}
                  </p>
                </div>
              </div>
              <div className='grid grid-cols-3 gap-5 pb-10'>
                <div>
                  <p className='pb-1'>PO Detail</p>
                  {isEmpty(poDetail?.poDetailsUri) &&
                  isEmpty(poDetail?.poDetailsUri) ? (
                    <p>-</p>
                  ) : (
                    <>
                      {!isEmpty(poDetail?.poDetailsUri) && (
                        <Button
                          textButton='Download PDF'
                          onClick={() => onDownloadPdf(poDetail?.poDetailsUri)}
                          style={{ marginTop: 5, marginBottom: 10 }}
                        />
                      )}

                      {!isEmpty(poDetail?.poDetailsCsvUri) && (
                        <Button
                          textButton='Download CSV'
                          onClick={() =>
                            onDownloadPdf(poDetail?.poDetailsCsvUri)
                          }
                        />
                      )}
                    </>
                  )}
                </div>
                <div>
                  <p className='pb-1'>Photo Surat Jalan</p>
                  {!isNil(poDetail?.dispatchNoteUrl) ? (
                    poDetail?.dispatchNoteUrl.length ? (
                      <div className='d-flex flex-column align-self-start mt-2'>
                        {poDetail?.dispatchNoteUrl.map((x, i) =>
                          validateUrl(x) ? (
                            <a
                              key={i.toString()}
                              href={x}
                              target='_blank'
                              rel='noopener noreferrer'>
                              {x.length > 30 ? x.substring(0, 30) + "..." : x}
                            </a>
                          ) : (
                            <p className='font-bold'>{x}</p>
                          )
                        )}
                      </div>
                    ) : (
                      <div className='detail-value font-weight-bold'>-</div>
                    )
                  ) : (
                    "-"
                  )}
                </div>
                <div>
                  <p className='pb-1'>Transaction No.</p>
                  {!isNil(poDetail?.invoiceRefNum) ? (
                    poDetail?.invoiceRefNum.length ? (
                      <div className='d-flex flex-column align-items-start align-self-start mt-2'>
                        {poDetail?.invoiceRefNum.map((invoice, i) => (
                          <div className='font-bold' key={i}>
                            {invoice}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className='font-bold'>-</div>
                    )
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className='grid grid-cols-3 gap-5 pb-10'>
                <div>
                  <p className='pb-1'>Invoice Detail</p>
                  {isEmpty(poDetail?.invoiceUri) ? (
                    <p>-</p>
                  ) : (
                    <Button
                      textButton='Download PDF'
                      onClick={() => onDownloadPdf(poDetail?.invoiceUri)}
                    />
                  )}
                </div>
              </div>
              <div>
                <p className='text-xl font-bold mb-1'>Return Item</p>
                <div className='grid grid-cols-3 gap-5 pb-3'>
                  <div>
                    <p className='pb-1'>Total Return</p>
                    <p className='font-bold'>
                      {formatPriceIdr(poDetail?.return.amount.number)}
                    </p>
                  </div>
                  <div>
                    <p className='pb-1'>Return Notes</p>
                    <p className='font-bold'>{poDetail?.return.notes}</p>
                  </div>
                  <div>
                    <p className='pb-1'>Invoice Return</p>
                    {isEmpty(poDetail?.return?.invoiceUri) ? (
                      <p>-</p>
                    ) : (
                      <Button
                        textButton='Download PDF'
                        onClick={() =>
                          onDownloadPdf(poDetail?.return?.invoiceUri)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PoDetail;
