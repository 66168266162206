import React from "react";
import StockUpdateList from "./stockUpdateList/StockUpdateList";

const StockUpdate = () => {
  return (
    <div>
      <StockUpdateList />
    </div>
  );
};

export default StockUpdate;
