// eslint-disable-next-line
import moment from "moment";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import { isEmpty, isNil } from "lodash";

import {
  downloadTemplateRequest,
  getListRequest,
  uploadRequest,
} from "@services/masterRequest.services";
import { mapStatusReview } from "@utils/conditional";

export const RequestItemListLogic = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit) => {
    setLoading(true);
    const params = {
      limit,
      page,
      type: "product",
    };
    try {
      const res = await getListRequest(params);
      isEmpty(res.partnerRequest) ? setData([]) : setData(res.partnerRequest);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "File CSV",
      headerStyle: () => {
        return { width: "150px" };
      },
      formatter: (cell, row) => (
        <p
          onClick={() => window.open(`${row?.url}`)}
          className='text-blue-500 cursor-pointer'>
          {row.nameFile.length > 25
            ? `${row.nameFile.substring(0, 25)}...`
            : row.nameFile}
        </p>
      ),
    },
    {
      dataField: "catalogs",
      text: "File Katalog",
      headerStyle: () => {
        return { width: "400px" };
      },
      formatter: (cell, row) =>
        isEmpty(row.catalogs) ? (
          <div>-</div>
        ) : (
          <div className='row align-self-center '>
            {row.catalogs.map((x, idx) => (
              <div key={idx}>
                <a> &#x2022; </a>
                <a
                  className='p-1'
                  key={idx}
                  href='#'
                  onClick={() => window.open(x.file)}>
                  {x.name.length > 40
                    ? `${x.name.substring(0, 40)}...`
                    : x.name}
                </a>
              </div>
            ))}
          </div>
        ),
    },
    {
      dataField: "created",
      text: "Tanggal",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "185px" };
      },
      formatter: (cell, row) => (
        <div className='text-center'>
          {moment(cell).format("DD MMMM YYYY, HH:mm")}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "170px" };
      },
      formatter: (cell, row) => (
        <div
          className={`${mapStatusReview(
            row?.status
          )} p-2 flex items-center justify-center rounded`}>
          <p className='text-black'>{row?.status}</p>
        </div>
      ),
    },
    {
      dataField: "keterangan",
      text: "Catatan",
      headerAlign: "center",
      formatter: (cell, row) => (
        <div className='text-center'>{cell || "-"}</div>
      ),
    },
    {
      dataField: "partialApproveDocument",
      text: "Feedback CSV",
      headerAlign: "center",
      headerStyle: () => {
        return { width: "200px" };
      },
      formatter: (cell, row) =>
        isNil(cell.name) ? (
          <p className='text-center'>-</p>
        ) : (
          <p
            onClick={() => window.open(`${cell?.url}`)}
            className='text-blue-500 cursor-pointer text-center'>
            Download
          </p>
        ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  const onUpload = async (file, name_file, pic, catalogs) => {
    const body = {
      file,
      name_file,
      pic,
      catalogs,
      type: "product",
    };
    try {
      await uploadRequest(body);
      await message.success(`success upload csv file`);
      await getData(1, limit);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateRequest("product");
      window.open(res.url);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onUpload,
    onDownloadTemplate,
  };
};
