import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { message } from "antd";
import { updatePointSystem } from "@services/settings.services";
import { getUserProfile } from "@services/auth.services";
import { userProfileAtom } from "@store/user.store";

export const PointSystemLogic = () => {
  const [profile, setUserProfile] = useAtom(userProfileAtom);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      setEnabled(profile?.pointRewardSystem);
    }
  }, [profile]);

  const onChangeToogle = async () => {
    setLoading(true);
    setEnabled(!enabled);
    try {
      await updatePointSystem({ enable: !enabled });
      const user = await getUserProfile();
      setUserProfile(user);
      setLoading(false);
    } catch (err) {
      await message.error(`${err.data.message}`);
      setLoading(false);
    }
  };

  return {
    enabled,
    setEnabled,
    loading,
    onChangeToogle,
  };
};
