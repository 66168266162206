import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import Zoom from "react-medium-image-zoom";
import { Select } from "antd";
import { isEmpty, isNil } from "lodash";

import { SalesReportDetailLogic } from "./SalesReportDetail.logic";
import { fullDate } from "@utils/formatDate";
import { formatPriceIdr } from "@utils/format";
import { convertStatus } from "@utils/convertData";
import Button from "@components/atom/Button";

const status = [
  {
    value: "Processed by Merchant",
    label: "Processed by Merchant",
  },
  { value: "Delivered", label: "Delivered" },
  { value: "Canceled", label: "Canceled" },
];

const SalesReportDetail = () => {
  const {
    dataDetail,
    onBack,
    transactionDetailColumns,
    handleChange,
    invoiceDiscount,
    shippingDiscount,
    onVerifyPayment,
    onDownloadInvoicePdf,
  } = SalesReportDetailLogic();

  const TextDetail = ({ label, value }) => (
    <div>
      <p className='font-bold text-neutral-400'>{label}</p>
      <p className='font-bold'>{value || "-"}</p>
    </div>
  );

  return (
    <div className='mt-4'>
      <p className='font-bold text-xl cursor-pointer mb-4' onClick={onBack}>
        <FontAwesomeIcon icon={faArrowLeft} className='mr-2 font-bold' />
        Detail Laporan Penjualan
      </p>
      <div className='grid md:grid-cols-4 grid-cols-2 gap-5 px-4'>
        <TextDetail label='Nama Pembeli' value={dataDetail?.customerName} />
        <TextDetail label='Nomor Hp' value={dataDetail?.phone} />
        <TextDetail label='Email' value={dataDetail?.email} />
        <TextDetail label='Kode Bengkel' value={dataDetail?.workshopId} />
        <TextDetail label='Alamat Pengiriman' value={dataDetail?.address} />
        <TextDetail label='Kecamatan' value={dataDetail?.districtName} />
        <TextDetail label='Kota' value={dataDetail?.cityName} />
        <TextDetail label='Provinsi' value={dataDetail?.provinceName} />
        <TextDetail label='Kode Pos' value={dataDetail?.zipCode} />
        <TextDetail label='Locale' value={dataDetail?.locale} />
        <TextDetail label='Plus Code' value={dataDetail?.pluscode} />
        <TextDetail
          label='Status Pembayaran'
          value={dataDetail?.paymentStatus}
        />
        <TextDetail label='Nomor Transaksi' value={dataDetail?.code} />
        <TextDetail
          label='Tanggal Transaksi'
          value={fullDate(dataDetail?.createdAt)}
        />
        <TextDetail
          label='Total Pengiriman'
          value={formatPriceIdr(dataDetail?.shippingFee || 0)}
        />
        <TextDetail
          label='Total Transaksi'
          value={formatPriceIdr(dataDetail?.total || 0)}
        />
        <TextDetail
          label='Metode Pembayaran'
          value={dataDetail?.paymentOption}
        />
        <TextDetail
          label='Metode Pengiriman'
          value={dataDetail?.deliveryMethod}
        />
        <TextDetail
          label='Admin Fee'
          value={formatPriceIdr(dataDetail?.adminFee || 0)}
        />
        <TextDetail
          label='Additional Discount'
          value={formatPriceIdr(dataDetail?.additionalDiscount || 0)}
        />
        <TextDetail
          label='Voucher Invoice Code'
          value={isNil(invoiceDiscount) ? "-" : invoiceDiscount.code}
        />
        <TextDetail
          label='Voucher Invoice Discount'
          value={
            isNil(invoiceDiscount) ? "-" : formatPriceIdr(invoiceDiscount.value)
          }
        />
        <TextDetail
          label='Voucher Shipping Code'
          value={isNil(shippingDiscount) ? "-" : shippingDiscount.code}
        />
        <TextDetail
          label='Voucher Shipping Discount'
          value={
            isNil(shippingDiscount)
              ? "-"
              : formatPriceIdr(shippingDiscount.value)
          }
        />
        {isEmpty(dataDetail?.urlInvoice) ? null : (
          <div>
            <p className='font-bold text-neutral-400'>Invoice PDF</p>
            <Button
              textButton={"Download Invoice PDF"}
              onClick={onDownloadInvoicePdf}
            />
          </div>
        )}
      </div>
      <div className='grid md:grid-cols-4 grid-cols-2 gap-5 px-4 mt-5'>
        <div>
          <p className='font-bold text-neutral-400'>Status</p>
          <Select
            className='w-full'
            value={dataDetail?.status}
            onChange={handleChange}
            options={
              dataDetail?.status === "Processed by Merchant" ||
              dataDetail?.status === "Delivered"
                ? status
                : [convertStatus(dataDetail?.status), ...status]
            }
          />
        </div>
        {dataDetail?.paymentStatus === "Paid" ? null : (
          <div>
            <p className='font-bold text-neutral-400'>Verifikasi</p>
            <Button
              textButton={"Verifikasi Pembayaran"}
              onClick={onVerifyPayment}
            />
          </div>
        )}
      </div>
      <div className='mt-5 px-4'>
        <p className='font-bold text-xl cursor-pointer mb-4'>Info Tambahan</p>
        <div>
          <p className='font-bold text-neutral-400'>Bukti Pembayaran</p>
          {isNil(dataDetail?.depositImages) ? (
            <p>-</p>
          ) : (
            <Zoom zoomMargin={100}>
              <img
                src={dataDetail?.depositImages}
                alt='bukti-pembayaran'
                className='w-40 h-40 object-contain'
              />
            </Zoom>
          )}
        </div>
      </div>
      <div className='my-5 px-4 pb-5'>
        <p className='font-bold text-xl cursor-pointer mb-4'>
          Produk yang dibeli
        </p>
        <BootstrapTable
          keyField='id'
          remote
          data={dataDetail?.transactionDetail || []}
          bordered={false}
          columns={transactionDetailColumns}
          hover
          noDataIndication={() => <div className='text-center'>Empty Data</div>}
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
        />
      </div>
    </div>
  );
};

export default SalesReportDetail;
