import { authServices } from "@utils/setupApi";

export const getListStatus = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "STATUS",
    },
  });
};

export const getListPartnerStatus = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "PARTNER_STATUS",
    },
  });
};

export const getListTermsOfPayment = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "PAYMENT",
    },
  });
};

export const getListCutoff = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "CUTOFF_TIME",
    },
  });
};

export const getListSellerStatus = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "SELLER_STATUS",
    },
  });
};

export const getListCityOptions = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      code: "CITIES",
    },
  });
};

export const getListDistrictOptions = (parent) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/dictionaries`,
    parameter: {
      parent,
      code: "DISTRICTS",
    },
  });
};
