// eslint-disable-next-line
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useDebounce } from "@utils/delay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { NavLink } from "react-router-dom";
import { message, Popover, Modal, Spin } from "antd";

import { userProfileAtom } from "@store/user.store";
import { formatPriceIdr } from "@utils/format";
import {
  convertPartialReject,
  insertLoadingFalse,
  insertTrxDetail,
  convertStatusToSelect,
} from "@utils/convertData";
import {
  updatePartialReject,
  updateSellerStatus,
  salesReportCsv,
  getListSalesReports,
  createPOSales,
  createHandoverList,
  getDetailSalesReport,
} from "@services/salesReports.services";
import { getListSellerStatus } from "@services/dictionaries.services";
import ButtonCancel from "../../../components/atom/ButtonCancel";
import ButtonConfirm from "@components/atom/ButtonConfirm";
import Button from "@components/atom/Button";
import { transactionStatusColor } from "@utils/conditional";

const { confirm } = Modal;

export const SalesReportListLogic = () => {
  const [profile] = useAtom(userProfileAtom);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [code, setCode] = useState(null);
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [row, setRow] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [isLoadingUpdatePartial, setIsLoadingUpdatePartial] = useState(false);
  const [selectTransaction, setSelectTransaction] = useState(false);
  const [selectList, setSelectList] = useState([]);
  const [hasPoRef, setHasPoRef] = useState(null);
  const [filterSellerOptions, setFilterSellerOptions] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    getData(page, limit);
    getSellerStatus();
  }, []);

  const getSellerStatus = async () => {
    try {
      const res = await getListSellerStatus();
      setFilterSellerOptions(convertStatusToSelect(res.entries));
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async (
    page,
    limit,
    startDate,
    endDate,
    code,
    filterBy,
    hasPoRef,
    seller_status
  ) => {
    setLoading(true);
    const params = {
      page,
      limit,
      startDate,
      endDate,
      code,
      filterBy,
      hasPoRef,
      seller_status,
    };
    try {
      const res = await getListSalesReports(params);
      if (isEmpty(res?.listTransaction)) {
        setData([]);
      } else {
        setData(
          res?.listTransaction.map((x, i) => ({
            ...x,
            id: i,
            transactionId: x.id,
            isLoading: true,
          }))
        );
        setPage(parseInt(res?.pagination.currentPage));
        setTotalSize(parseInt(res?.pagination.Count));
        setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getDataDetail = async (transaction_id) => {
    const params = {
      transaction_id,
    };
    try {
      const res = await getDetailSalesReport(params);
      if (isEmpty(res)) {
        setData(insertLoadingFalse(data, transaction_id));
      } else {
        setData(insertTrxDetail(data, transaction_id, res));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeRejectedQty = (e, row, item) => {
    const filterRow = {
      ...row,
      transactionDetail: row.transactionDetail.map((trx) =>
        trx.id === item.id ? { ...trx, rejectedQty: e.target.value } : trx
      ),
    };
    setRow(filterRow);
  };

  const onEditTrxDetail = (row) => {
    setRow(row);
    setIsEditVisible(true);
  };

  const handleOk = async () => {
    setIsLoadingUpdatePartial(true);
    const body = convertPartialReject(row);
    try {
      await updatePartialReject(body);
      await setIsLoadingUpdatePartial(false);
      await window.location.reload();
    } catch (error) {
      message.error(`${error.data.message}`);
      await setIsLoadingUpdatePartial(false);
    }
  };

  const handleCancel = () => {
    setIsEditVisible(false);
  };

  const onConfirmSeller = (row) => {
    const body = {
      partner_id: profile?.partnerId,
      status: "Seller Confirmed",
    };
    confirm({
      title: "Confirm all items in the Invoice?",
      async onOk() {
        const res = await updateSellerStatus(row.code, body);
        if (res.updateStatus) {
          setData((current) =>
            current.map((obj) =>
              obj.code === row.code
                ? { ...row, sellerStatus: "Seller Confirmed" }
                : obj
            )
          );
        } else {
          message.error("update sellerStatus: ", res.updateStatus);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // const onSellerReject = (row) => {
  //   const body = {
  //     partner_id: profile?.partnerId,
  //     status: "Seller Rejected",
  //   };
  //   confirm({
  //     title: "Reject all items in the Invoice?",
  //     async onOk() {
  //       await updateSellerStatus(row.code, body);
  //       await window.location.reload();
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  const expandRow = {
    renderer: (row, rowIndex) => {
      return (
        <div className='p-2'>
          {row.isLoading ? (
            <div className='flex items-center justify-center'>
              <Spin />
            </div>
          ) : (
            <div>
              <div className='flex items-center'>
                <div className='w-1/3 text-gray-500'>Nama Produk</div>
                <div className='w-1/3 text-gray-500'>SKU BP</div>
                <div className='w-1/3 text-gray-500'>SKU Seller</div>
                <div className='w-1/3 text-gray-500'>Qty</div>
                <div className='w-1/3 text-gray-500'>Harga Unit</div>
                <div className='w-1/3 text-gray-500'>Harga Total</div>
                <div className='w-1/3 text-gray-500'>
                  Qty Ditolak{" "}
                  <Popover
                    content={"Qty yang tidak dapat di penuhi/kekurangan"}
                    title={null}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className='ml-1 cursor-pointer'
                    />
                  </Popover>
                </div>
              </div>
              {isNil(row?.transactionDetail) ? (
                <div className='flex items-center mb-2'>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                  <div className='w-1/3'>-</div>
                </div>
              ) : (
                row?.transactionDetail.map((item, key) => (
                  <div className='flex items-center mb-2' key={key}>
                    <div className='w-1/3'>{item.productName}</div>
                    <div className='w-1/3'>{item.sku}</div>
                    <div className='w-1/3'>{item.skuSeller}</div>
                    <div className='w-1/3'>{item.qty}</div>
                    <div className='w-1/3'>
                      {(item.price && formatPriceIdr(item.price)) || "-"}
                    </div>
                    <div className='w-1/3'>
                      {(item.price &&
                        formatPriceIdr(item.price * parseInt(item.qty))) ||
                        "-"}
                    </div>
                    <div className='w-1/3'>{item.rejectedQty}</div>
                  </div>
                ))
              )}
              {isNil(row?.transactionDetail) ? null : row.sellerStatus ===
                  "Waiting Seller Confirmation" ||
                row.sellerStatus === "Partial Reject" ? (
                <div className='flex justify-end'>
                  <Button
                    textButton='Edit'
                    style={{ marginRight: 5 }}
                    onClick={() => onEditTrxDetail(row)}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnPosition: "right",
    expanded: expanded,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setExpanded((expanded) => [...expanded, row.id]);
        if (isEmpty(row.transactionDetail)) {
          getDataDetail(row.transactionId);
        }
      } else {
        setExpanded((prevExpanded) => prevExpanded.filter((x) => x !== row.id));
      }
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => null,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon className='text-left' icon={faAngleUp} />;
      }
      return <FontAwesomeIcon className='text-left' icon={faAngleDown} />;
    },
  };

  const columns = [
    {
      dataField: "code",
      text: "No. Transaksi",
      formatter: (cell, row) => (
        <NavLink
          to={`/sales-report/${row.transactionId}`}
          className='cursor-pointer underline text-black'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "confirmedDate",
      text: "Tanggal Transaksi",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD/MM/YYYY HH:mm")}</div>
      ),
    },
    {
      dataField: "customerName",
      text: "Nama Customer",
      formatter: (cell, row) => (
        <NavLink
          style={{ color: "#000" }}
          to={`/customer/${row.customerId}`}
          className='cursor-pointer underline hover:underline '
          activeclassname='cursor-pointer underline'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "workshopId",
      text: "Kode Bengkel",
    },
    {
      dataField: "deliveryMethod",
      text: "Delivery Method",
      formatter: (cell, row) => (
        <div>{cell == "Express Delivery" ? "Express Delivery" : ""}</div>
      ),
    },
    {
      dataField: "status",
      text: "Order Status",
      formatter: (cell, row) =>
        isNil(cell) ? (
          <div>-</div>
        ) : (
          <div
            className={`p-2 flex items-center justify-center rounded text-white`}
            style={{ background: transactionStatusColor(cell) }}>
            {cell}
          </div>
        ),
    },
    // {
    //   dataField: "invoiceUrl",
    //   text: "Invoice",
    //   formatter: (cell, row) => (
    //     <Button
    //       textButton='Download Invoice'
    //       onClick={() => onDownloadSellerInvoice(row)}
    //     />
    //   ),
    // },
    {
      dataField: "id",
      text: "",
      formatter: (cell, row) => {
        return row.sellerStatus === "Waiting Seller Confirmation" ? (
          <div className='flex'>
            <div className='mr-2'>
              <ButtonConfirm
                textButton='Konfirmasi'
                onClick={() => onConfirmSeller(row)}
              />
            </div>
            {/* <ButtonCancel
              textButton="Reject"
              onClick={() => onSellerReject(row)}
            /> */}
          </div>
        ) : null;
      },
    },
    {
      dataField: "paymentStatus",
      text: "Status Pembayaran",
      formatter: (cell, row) =>
        cell === "Disbursed" ? <div>{cell}</div> : <div>-</div>,
    },
  ];

  const columnsB2B = [
    {
      dataField: "code",
      text: "No. Transaksi",
      formatter: (cell, row) => (
        <NavLink
          to={`/sales-report/${row.transactionId}`}
          className='cursor-pointer underline text-black'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "confirmedDate",
      text: "Tanggal Transaksi",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD/MM/YYYY HH:mm")}</div>
      ),
    },
    { dataField: "poRefNum", text: "Nomor PO" },
    {
      dataField: "customerName",
      text: "Nama Customer",
      formatter: (cell, row) => (
        <NavLink
          style={{ color: "#000" }}
          to={`/customer/${row.customerId}`}
          className='cursor-pointer underline hover:underline '
          activeclassname='cursor-pointer underline'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "workshopId",
      text: "Kode Bengkel",
    },
    {
      dataField: "deliveryMethod",
      text: "Delivery Method",
      formatter: (cell, row) => (
        <div>{cell == "Express Delivery" ? "Express Delivery" : ""}</div>
      ),
    },
    {
      dataField: "status",
      text: "Order Status",
      formatter: (cell, row) =>
        isNil(cell) ? (
          <div>-</div>
        ) : (
          <div
            className={`p-2 flex items-center justify-center rounded text-white`}
            style={{ background: transactionStatusColor(cell) }}>
            {cell}
          </div>
        ),
    },
    {
      dataField: "id",
      text: "",
      formatter: (cell, row) => {
        return row.sellerStatus === "Waiting Seller Confirmation" ? (
          <div className='flex'>
            <div className='mr-2'>
              <ButtonConfirm
                textButton='Konfirmasi'
                onClick={() => onConfirmSeller(row)}
              />
            </div>
            {/* <ButtonCancel
              textButton='Reject'
              onClick={() => onSellerReject(row)}
            /> */}
          </div>
        ) : null;
      },
    },
    {
      dataField: "paymentStatus",
      text: "Status Pembayaran",
      formatter: (cell, row) =>
        cell === "Disbursed" ? <div>{cell}</div> : <div>-</div>,
    },
  ];

  const onTableChange = () => {};

  const onPageChange = (e) => {
    setExpanded([]);
    getData(e, limit, startDate, endDate, code, 1, hasPoRef, filterStatus);
  };

  const onLimitChange = (e) => {
    setExpanded([]);
    getData(1, e, startDate, endDate, code, 1, hasPoRef, filterStatus);
    setLimit(e);
  };

  const handleSearch = useDebounce(async (e) => {
    setExpanded([]);
    getData(
      1,
      limit,
      startDate,
      endDate,
      e.target.value,
      1,
      hasPoRef,
      filterStatus
    );
    setCode(e.target.value);
  }, 1000);

  const onFilterDateRange = (date) => {
    setExpanded([]);
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;
    getData(
      1,
      limit,
      tempStartDate,
      tempEndDate,
      code,
      1,
      hasPoRef,
      filterStatus
    );
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const onDownloadCsv = async () => {
    const parameter = {
      from: startDate,
      to: endDate,
      seller_status: filterStatus || null,
    };
    try {
      const res = await salesReportCsv(parameter);
      await window.open(`${res.url}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const onDownloadSellerInvoice = (row) => {
    window.open(`${row.urlInvoiceSeller}`, "_blank");
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectList([...selectList, row.code]);
      } else {
        let filterSelectedList = selectList.filter((x) => x !== row.code);
        setSelectList(filterSelectedList);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setSelectList(rows.map((x) => x.code)) : setSelectList([]);
    },
  };

  const createPO = async () => {
    try {
      await createPOSales(selectList.toString());
      await getData(page, limit, startDate, endDate, code, 1, hasPoRef);
      message.success("Success create PO");
      setSelectTransaction(false);
      setSelectList([]);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const createHL = async () => {
    const data = {
      transaction_number: selectList,
    };
    try {
      const res = await createHandoverList(data);
      message.success("Success create Handover List");
      setSelectTransaction(false);
      setSelectList([]);
      await window.open(res.uriGenerateHandover);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onFilterHasPo = (e) => {
    setExpanded([]);
    getData(1, limit, startDate, endDate, code, 1, e, filterStatus);
    setHasPoRef(e);
  };

  const onFilterBySellerStatus = (e) => {
    const val = e.length === 0 ? null : e.join(",");
    setExpanded([]);
    getData(1, limit, startDate, endDate, code, 1, hasPoRef, val);

    setFilterStatus(val);
  };

  return {
    columns,
    columnsB2B,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    onFilterDateRange,
    onDownloadCsv,
    profile,
    expandRow,
    selectRow,
    isEditVisible,
    handleCancel,
    handleOk,
    onChangeRejectedQty,
    row,
    isLoadingUpdatePartial,
    selectTransaction,
    setSelectTransaction,
    selectList,
    createPO,
    createHL,
    onFilterHasPo,
    onFilterBySellerStatus,
    filterSellerOptions,
  };
};
