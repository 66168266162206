import React, { useEffect } from "react";
import PoList from "@containers/purchasedOrder/poList/PoList";
import { getListStatus } from "../../services/dictionaries.services";
import { convertStatusToSelect } from "../../utils/convertData";
import { statusListsAtom } from "../../store/dictionaries";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";

const PurchasedOrder = () => {
  const [statusLists, setStatusLists] = useAtom(statusListsAtom);

  useEffect(() => {
    if (isEmpty(statusLists)) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      const res = await getListStatus();
      await setStatusLists(convertStatusToSelect(res.entries));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PoList />
    </div>
  );
};

export default PurchasedOrder;
