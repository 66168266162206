import { useEffect, useState } from "react";
import { has, isNil } from "lodash";
import { message } from "antd";
import moment from "moment";
import {
  getDailyReport,
  createDailyReport,
  deleteDailyReport,
} from "@services/settings.services";

export const StockReportLogic = () => {
  const [isDisplaySchedule, setisDisplaySchedule] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState([
    { time: "00:00", listMail: [] },
  ]);
  const [focused, setFocused] = useState(false);

  const getManipulateDataTime = (data) => {
    return data.map((x) => ({
      id: x.id,
      time: Object.values(x.time).join(":"),
      email: x.listMail,
    }));
  };

  const postManipulateDataTime = (data) => {
    const dataFilter = data.filter((x) => !has(x, "id"));
    const result = dataFilter.map((x) => ({
      time: {
        hours: parseInt(x.time.split(":")[0]),
        minutes: parseInt(x.time.split(":")[1]),
        seconds: parseInt(0),
      },
      list_mail: x.email,
    }));
    return result;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const params = {
      page: 1,
      limit: 10,
    };
    try {
      const res = await getDailyReport(params);
      await setScheduleTime(getManipulateDataTime(res.configurations || []));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDate = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;

    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const onSubmitSchedule = async () => {
    const dataFilter = scheduleTime.filter((x) => !has(x, "id"));
    const list_mail = dataFilter.map((x) => x.email);
    const arrEmailEmpty = list_mail.flat().includes("");
    const data = { schedules: postManipulateDataTime(scheduleTime) };

    if (arrEmailEmpty) {
      return message.error(`Email tidak boleh kosong`);
    } else {
      try {
        await createDailyReport(data);
        await setisDisplaySchedule(false);
        await message.success(`Berhasil membuat daily stock report`);
      } catch (error) {
        await message.error(`${error.data.message}`);
      }
    }
  };

  const onAddSchedule = () => {
    const newData = { time: "00:00:00", email: [""] };
    setScheduleTime([...scheduleTime, newData]);
  };

  const onRemove = async (index, item) => {
    const data = scheduleTime.filter((item, idx) => idx !== index);
    setScheduleTime(data);
    if (has(item, "id")) {
      try {
        await deleteDailyReport({ id: item.id });
      } catch (err) {
        console.log("error", err);
      }
    }
    if (scheduleTime.length === 1) {
      setisDisplaySchedule(false);
      setScheduleTime([{ time: "00:00", listMail: [] }]);
    }
  };

  const onChangeTime = (time, timeString, idx) => {
    const data = scheduleTime.map((item, id) =>
      id === idx ? { ...item, time: timeString } : item
    );
    setScheduleTime(data);
  };

  const onChangeEmail = (e, idx) => {
    const data = scheduleTime.map((item, id) =>
      id === idx ? { ...item, email: e.length == 0 ? [""] : e } : item
    );
    setScheduleTime(data);
  };

  return {
    startDate,
    endDate,
    isDisplaySchedule,
    setisDisplaySchedule,
    onChangeDate,
    onSubmitSchedule,
    onChangeTime,
    scheduleTime,
    onAddSchedule,
    onRemove,
    onChangeEmail,
    focused,
    setFocused,
  };
};
