export const timeOptions = [
  {
    label: "00:30 WIB",
    value: "00:30",
  },
  {
    label: "01:00 WIB",
    value: "01:00",
  },
  {
    label: "01:30 WIB",
    value: "01:30",
  },
  {
    label: "02:00 WIB",
    value: "02:00",
  },
  {
    label: "02:30 WIB",
    value: "02:30",
  },
  {
    label: "03:00 WIB",
    value: "03:00",
  },
  {
    label: "03:30 WIB",
    value: "03:30",
  },
  {
    label: "04:00 WIB",
    value: "04:00",
  },
  {
    label: "04:30 WIB",
    value: "04:30",
  },
  {
    label: "05:00 WIB",
    value: "05:00",
  },
  {
    label: "05:30 WIB",
    value: "05:30",
  },
  {
    label: "06:00 WIB",
    value: "06:00",
  },
  {
    label: "06:30 WIB",
    value: "06:30",
  },
  {
    label: "07:00 WIB",
    value: "07:00",
  },
  {
    label: "07:30 WIB",
    value: "07:30",
  },
  {
    label: "08:00 WIB",
    value: "08:00",
  },
  {
    label: "08:30 WIB",
    value: "08:30",
  },
  {
    label: "09:00 WIB",
    value: "09:00",
  },
  {
    label: "09:30 WIB",
    value: "09:30",
  },
  {
    label: "10:00 WIB",
    value: "10:00",
  },
  {
    label: "10:30 WIB",
    value: "10:30",
  },
  {
    label: "11:00 WIB",
    value: "11:00",
  },
  {
    label: "11:30 WIB",
    value: "11:30",
  },
  {
    label: "12:00 WIB",
    value: "12:00",
  },
  {
    label: "12:30 WIB",
    value: "12:30",
  },
  {
    label: "13:00 WIB",
    value: "13:00",
  },
  {
    label: "13:30 WIB",
    value: "13:30",
  },
  {
    label: "14:00 WIB",
    value: "14:00",
  },
  {
    label: "14:30 WIB",
    value: "14:30",
  },
  {
    label: "15:00 WIB",
    value: "15:00",
  },
  {
    label: "15:30 WIB",
    value: "15:30",
  },
  {
    label: "16:00 WIB",
    value: "16:00",
  },
  {
    label: "16:30 WIB",
    value: "16:30",
  },
  {
    label: "17:00 WIB",
    value: "17:00",
  },
  {
    label: "17:30 WIB",
    value: "17:30",
  },
  {
    label: "18:00 WIB",
    value: "18:00",
  },
  {
    label: "18:30 WIB",
    value: "18:30",
  },
  {
    label: "19:00 WIB",
    value: "19:00",
  },
  {
    label: "19:30 WIB",
    value: "19:30",
  },
  {
    label: "20:00 WIB",
    value: "20:00",
  },
  {
    label: "20:30 WIB",
    value: "20:30",
  },
  {
    label: "21:00 WIB",
    value: "21:00",
  },
  {
    label: "21:30 WIB",
    value: "21:30",
  },
  {
    label: "22:00 WIB",
    value: "22:00",
  },
  {
    label: "22:30 WIB",
    value: "22:30",
  },
  {
    label: "23:00 WIB",
    value: "23:00",
  },
  {
    label: "23:30 WIB",
    value: "23:30",
  },
];
