import { authServices, basicServices } from "@utils/setupApi";

export const loginUser = (body) => {
  return basicServices({
    method: "POST",
    url: `${process.env.REACT_APP_AUTH_API}/v1/authentication/login`,
    body,
  });
};

export const getUserProfile = (body) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/profile`,
    body,
  });
};
