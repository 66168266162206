import { authServices } from "@utils/setupApi";

export const getAnnouncement = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/announcement/Website Partner`,
  });
};

export const getAnnouncementPopup = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/announcement/Pop Up`,
  });
};
