export const statusRefNotif = (status) => {
  switch (status) {
    case "po":
      return "Purchase Order";
    case "material":
      return "Tutorial";
    case "request_item":
      return "Request Barang";
    case "request_price":
      return "Request Harga";
    case "stock_update":
      return "Update Stok";
    case "transaction":
      return "Transaksi";
    case "campaign_event":
      return "Campaign";
    case "campaign_registration":
      return "Campaign";
    default:
      return "-";
  }
};

export const statusColor = (status) => {
  switch (status) {
    case "Pending":
      return "bg-blue-500";
    case "Processing":
      return "bg-yellow-400";
    case "Completed":
      return "bg-green-700";
    case "Received":
      return "bg-green-700";
    case "Canceled":
      return "bg-red-500";
    default:
      return "bg-blue-300";
  }
};

export const sellerStatusColor = (status) => {
  switch (status) {
    case "Waiting Seller Confirmation":
      return "#4791fe";
    case "Seller Confirmed":
      return "#53fca1";
    case "Seller Rejected":
      return "#ff3f3e";
    case "Seller Reject":
      return "#858585";
    case "Partial Reject":
      return "#000";
    case "User Cancelled":
      return "#858585";
    case "Return to Seller":
      return "#858585";
    case "BP Received":
      return "#8b4513";
    case "Pickup Completed":
      return "#20B2AA";
    default:
      return "#4791fe";
  }
};

export const mapStatusCampaignEvent = (status) => {
  switch (status) {
    case "Pending":
      return "bg-yellow-300";
    case "Active":
      return "bg-blue-300";
    case "Cancel":
      return "bg-red-300";
    case "Complete":
      return "bg-green-300";
    default:
      return "bg-yellow-300";
  }
};

export const mapStatusReview = (status) => {
  switch (status) {
    case "Approved":
      return "bg-green-300";
    case "Rejected":
      return "bg-red-300";
    case "In Review":
      return "bg-yellow-300";
    default:
      return "bg-blue-300";
  }
};

export const mapCampaignStatus = (status) => {
  switch (status) {
    case "Active":
      return "Sedang Aktif";
    case "Pending":
      return "Belum Dimulai";
    case "Cancel":
      return "Dibatalkan";
    case "Complete":
      return "Sudah Berakhir";
    default:
      return "Belum Dimulai";
  }
};

export const transactionStatusColor = (status) => {
  switch (status) {
    case "Waiting User Confirmation":
      return "#4791fe";
    case "Paid and Processing Shipment":
      return "#fed500";
    case "Confirmed and Processing Shipment":
      return "#a5ff01";
    case "User Confirmed":
      return "#53fca1";
    case "On Delivery":
      return "#23fe6c";
    case "Delivered":
      return "#23fe6c";
    case "Complete":
      return "#2cae4e";
    case "On Hold":
      return "#fd6b6b";
    case "Partial Return":
      return "#ff00e7";
    case "Resend":
      return "#c7c7c7";
    case "Revised":
      return "#423a38";
    case "Canceled":
      return "#ff3f3e";
    default:
      return "#4791fe";
  }
};
