import React from "react";

import fileIcon from "@assets/file.png";
import closeIcon from "@assets/close.png";

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#ccc",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const DropZoneFile = ({ onRemoveFile }) => {
  return (
    <div style={baseStyle}>
      <div className='relative flex p-2' onClick={onRemoveFile}>
        <img src={fileIcon} alt='file-icon' className='w-20 h-20' />
        <img
          src={closeIcon}
          alt='close-icon'
          className='h-8 w-8 absolute -top-3 -right-3 cursor-pointer'
        />
      </div>
    </div>
  );
};

export default DropZoneFile;
