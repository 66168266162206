import { Cookies } from "react-cookie";

const cookies = new Cookies();

const setJoyrideAnnounce = (payload) => {
  cookies.set("@joyrideAnnounce", JSON.stringify(payload), { path: "/" });
};

const getJoyrideAnnounce = () => cookies.get("@joyrideAnnounce");

const removeJoyrideAnnounce = () => {
  cookies.remove("@joyrideAnnounce", { path: "/" });
};

const setJoyrideWelcome = (payload) => {
  cookies.set("@joyrideWelcome", JSON.stringify(payload), { path: "/" });
};

const getJoyrideWelcome = () => cookies.get("@joyrideWelcome");

const removeJoyrideWelcome = () => {
  cookies.remove("@joyrideWelcome", { path: "/" });
};

export {
  setJoyrideAnnounce,
  getJoyrideAnnounce,
  removeJoyrideAnnounce,
  setJoyrideWelcome,
  getJoyrideWelcome,
  removeJoyrideWelcome,
};
