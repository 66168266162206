import React, { useState } from "react";
import { Select, Input } from "antd";

import "antd/es/input/style/css";
import "antd/es/select/style/css";

const { Option } = Select;
const { Search } = Input;

const SearchOption = ({ handleSearch, options }) => {
  const [filterBy, setfilterBy] = useState(options[0].value);
  const [text, setText] = useState("");

  const onChangeFilter = (e) => {
    setfilterBy(e);
    setText("");
  };

  return (
    <div>
      <div className='flex items-center justify-start w-full'>
        <Select
          value={filterBy}
          style={{ width: 120 }}
          onChange={onChangeFilter}>
          {options.map((option, idx) => (
            <Option key={idx} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <Search
          value={text}
          type='text'
          placeholder={"Cari"}
          onChange={(e) => {
            setText(e.target.value);
            handleSearch(filterBy, e);
          }}
        />
      </div>
    </div>
  );
};

export default SearchOption;
