import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import Sidebar from "@layout/Sidebar";
import Header from "@layout/Header";
import { isEmpty, isNil } from "lodash";
import Drawer from "react-modern-drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faClose } from "@fortawesome/free-solid-svg-icons";

import { isAuthAtom } from "@store/auth.store";
import { userProfileAtom } from "@store/user.store";
import { isAuthenticated } from "@utils/token";
import { getUserProfile } from "@services/auth.services";
import { isDisplayDrawerAtom } from "@store/config.store";
import { isDisplayAnnouncementAtom } from "@store/announcement.store";
import { getAnnouncement } from "@services/announcement.services";
import { removeToken } from "../utils/token";

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useAtom(isDisplayDrawerAtom);
  const [isAuth, setIsAuth] = useAtom(isAuthAtom);
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [isDisplayAnnouncement, setIsDisplayAnnouncement] = useAtom(
    isDisplayAnnouncementAtom
  );

  const [annoucement, setAnnoucement] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isEmpty(userProfile)) {
      getDataProfile();
    }
  }, []);

  const getData = async () => {
    const isLoggedIn = await isAuthenticated();
    if (isNil(isLoggedIn)) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
      navigate(`${location.pathname}`);
    }
  };

  const getDataProfile = async () => {
    try {
      const res = await getUserProfile();
      await setUserProfile(res);
      const announce = await getAnnouncement();
      await setAnnoucement(announce);
    } catch (error) {
      await setAnnoucement(null);
      if (error.status === 401) {
        setIsAuth(false);
        removeToken();
      }
    }
  };

  const onClickMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const onCloseAnnouncement = () => {
    setIsDisplayAnnouncement(false);
  };

  const onNavigate = () => {
    return isEmpty(annoucement?.url)
      ? null
      : window.open(`${annoucement?.url}`, "_blank");
  };

  if (isAuth) {
    return (
      <div className='layout'>
        <div className='wrap-sidebar md:block hidden'>
          <Sidebar />
        </div>
        <div className='wrap-header'>
          <Header onClickMenu={onClickMenu} />
          <div className='wrap-content'>
            {isDisplayAnnouncement && annoucement !== null && (
              <div
                className={`flex items-center justify-between bg-emerald-600 p-3 rounded mb-3 ${
                  isEmpty(annoucement?.url) ? "" : "cursor-pointer"
                }`}
                onClick={onNavigate}>
                <div className='flex items-center'>
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    className='text-white text-2xl mr-3'
                  />
                  <p
                    className={`text-white text-base ${
                      isEmpty(annoucement?.url) ? "" : "underline"
                    }`}>
                    {annoucement.title}
                  </p>
                </div>
                <div
                  className='flex items-center justify-center rounded-full border border-white h-6 w-6 cursor-pointer'
                  onClick={onCloseAnnouncement}>
                  <FontAwesomeIcon icon={faClose} className='text-white' />
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
        <Drawer open={isOpen} onClose={onClickMenu} direction='left'>
          <Sidebar />
        </Drawer>
      </div>
    );
  } else {
    return <Navigate to='/login' replace={true} />;
  }
};

export default Layout;
