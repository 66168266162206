import { convertSkuToSelect } from "@utils/convertData";
import { createStockUpdate, getSearchSku } from "@services/stock.services";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import { userProfileAtom } from "@store/user.store";
import { message } from "antd";

export const StockUpdateCreateLogic = () => {
  const navigate = useNavigate();

  const [userProfile] = useAtom(userProfileAtom);

  const [stocks, setStocks] = useState([{}]);
  const [inputValue, setValue] = useState("");
  const [sku, setSku] = useState(null);
  const [enabled, setEnabled] = useState(false);

  // handle input change event
  const handleInputChange = (value) => {
    setValue(value);
  };

  // handle selection
  const handleChange = (e, index, skuType) => {
    setSku(e);
    const data = stocks.map((stock, id) =>
      id === index ? { ...stock, sku: e } : stock
    );
    setStocks(data);
  };

  // load options using API call
  const loadOptions = async (inputValue) => {
    const params = {
      name: inputValue,
      limit: 10,
      sku_type: enabled ? "external" : "internal",
    };
    try {
      const res = await getSearchSku(params);
      return convertSkuToSelect(res.sku);
    } catch (error) {
      console.log(error);
    }
  };

  const addSecondaryProduct = () => {
    const product = {};
    setStocks([...stocks, product]);
  };

  const onHandleStock = (e, index, params) => {
    const data = stocks.map((stock, id) =>
      id === index
        ? {
            ...stock,
            [params]:
              params === "stock_in" || params === "stock_out"
                ? parseInt(e.target.value)
                : e.target.value,
          }
        : stock
    );
    setStocks(data);
  };

  const handleSubmit = async () => {
    const data = {
      stocks: stocks.map((stock) => {
        return {
          ...stock,
          sku: stock.sku ? stock.sku.value : "",
        };
      }),
      sku_type: enabled ? "external" : "internal",
    };
    try {
      await createStockUpdate(data);
      await navigate("/stock-update");
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onRemove = (index) => {
    const data = stocks.filter((stock, idx) => idx !== index);
    setStocks(data);
  };

  const onChangeSwitch = async () => {
    setEnabled(!enabled);
  };

  return {
    loadOptions,
    handleInputChange,
    handleChange,
    addSecondaryProduct,
    onHandleStock,
    handleSubmit,
    onRemove,
    stocks,
    navigate,
    onChangeSwitch,
    enabled,
    setEnabled,
  };
};
