import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { useAtom } from "jotai";

import notifOff from "@assets/notification-off.png";
import infoPng from "@assets/info.png";
import { useNavigate } from "react-router-dom";
import { statusRefNotif } from "../../utils/conditional";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import {
  notifAtom,
  unReadNotifAtom,
  pageAtom,
  lastPageAtom,
  sizePerPageAtom,
  totalSizeAtom,
} from "@store/notification.store";

import {
  getListNotifications,
  readAllNotification,
} from "../../services/notification.services";

import moment from "moment";

const Notification = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [notif, setNotif] = useAtom(notifAtom);
  const [unReadNotif, setUnReadNotif] = useAtom(unReadNotifAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [lastPage, setLastPage] = useAtom(lastPageAtom);
  const [sizePerPage, setSizePerPage] = useAtom(sizePerPageAtom);
  const [totalSize, setTotalSize] = useAtom(totalSizeAtom);

  const getNotifications = async (pages, limit, more = false) => {
    const params = {
      page: pages,
      limit: limit,
    };
    try {
      const res = await getListNotifications(params);
      const newArr = [...notif, ...res?.notifications];
      more
        ? setNotif(newArr)
        : setNotif(
            res?.hasOwnProperty("notifications") ? res?.notifications : []
          );
      setPage(parseInt(res?.pagination.currentPage));
      setLastPage(parseInt(res?.pagination.LastPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setUnReadNotif(
        res?.hasOwnProperty("unreadCount") ? parseInt(res?.unreadCount) : 0
      );
    } catch (err) {
      console.log("get notification: ", err);
    }
  };

  const onDownloadCsv = async (link) => {
    try {
      await window.open(`${link}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const readNotif = async (id) => {
    const body = {
      read_all: id ? false : true,
      notification_id: id ? id : "",
    };
    try {
      await readAllNotification(body);
      await getNotifications(page, sizePerPage);
    } catch (err) {
      console.log("error PUT notification: ", err);
    }
  };

  const onHandleRedirect = (item) => {
    if (item.redirectTo == "material") {
      navigate(`/training-material/${item.referenceId}`);
    } else if (item.redirectTo == "transaction") {
      navigate("/sales-report");
    } else if (item.redirectTo == "po") {
      navigate("/purchased-order");
    } else if (item.redirectTo == "stock_update") {
      navigate("/stock-update");
    } else if (item.redirectTo == "request_price") {
      navigate("/master/price-request");
    } else if (item.redirectTo == "request_item") {
      navigate("/master/new-item-request");
    } else if (item.redirectTo == "disbursement_history") {
      navigate("/disbursement-history");
    } else if (item.redirectTo == "campaign_event") {
      navigate("/campaign-event");
    } else if (item.redirectTo == "campaign_registration") {
      navigate("/master/campaign-registration");
    } else {
      navigate("/");
    }

    if (!item.hasOwnProperty("readTimestamp")) {
      onClickNotif(item.id);
    }

    setOpen(false);
  };

  const notifListArr =
    notif &&
    notif.map((item) => {
      const tamp = {};
      tamp.key = item.id + " " + item.hasOwnProperty("readTimestamp");
      tamp.label = (
        <div
          className={`flex w-full py-2 px-3 flex-col text-gray-700 max-w-sm 
            border-[1px] rounded
            ${
              item.hasOwnProperty("readTimestamp")
                ? "bg-white-100"
                : "bg-green-100 border-green-700"
            }`}>
          <div className='flex w-full flex-col border-b-1'>
            <div className='flex flex-row items-center mb-2'>
              <img
                src={infoPng}
                className='w-auto h-[10px] mr-1.5 fill-cyan-500'
              />

              <p className='text-xs font-light font-semibold items-center text-gray-500 antialiased tracking-tight'>
                {statusRefNotif(item.redirectTo)} &#x2022;{" "}
                {moment(item.createdAt).format("DD MMMM YYYY - HH:mm")}
              </p>
            </div>
            <p className='text-[0.9rem] font-bold leading-relaxed text-gray-900 antialiased'>
              {item.title}
            </p>
          </div>
          <div className='mt-0 p-0'>
            <p className='text-[0.8rem] leading-relaxed text-gray-700 antialiased tracking-tight'>
              {item.remarks}
            </p>
          </div>

          <div className='flex flex-row gap-1 mt-1 p-0'>
            {item.hasOwnProperty("redirectTo") ? (
              <a
                className='text-xs font-semibold leading-relaxed text-emerald-700 underline antialiased tracking-tight'
                onClick={() => onHandleRedirect(item)}>
                Lihat Detail
              </a>
            ) : null}
            {item.hasOwnProperty("csvUrl") ? (
              <>
                <p className='text-xs'>|</p>
                <a
                  className='text-xs font-semibold leading-relaxed text-emerald-700 underline antialiased tracking-tight'
                  onClick={() => onDownloadCsv(item.csvUrl)}>
                  Download CSV
                </a>
              </>
            ) : null}
          </div>
        </div>
      );
      return tamp;
    });

  const moreData = {
    key: "03",
    label: (
      <div className='flex items-center justify-center'>
        <a
          className='text-sm font-semibold leading-relaxed text-green-500 antialiased tracking-tight'
          onClick={() => getNotifications(page + 1, sizePerPage, true)}>
          Lihat lebih banyak
        </a>
      </div>
    ),
  };

  const emptyNotif = [
    {
      key: "04",
      disabled: true,
      label: (
        <div className='flex items-center justify-center w-[20rem] h-[3rem]'>
          <p className='text-sm font-base leading-relaxed text-gray-800 antialiased tracking-tight'>
            tidak ada notification
          </p>
        </div>
      ),
    },
  ];

  const notifList = [
    {
      key: "00",
      type: "group",
      label: (
        <div className='flex items-center justify-between mx-2 my-2'>
          <p className='text-gray-500 text-xl'>Notification</p>
          <div
            className='flex items-center justify-center rounded-full border-2 border-gray-500 h-6 w-6 cursor-pointer'
            onClick={() => setOpen(false)}>
            <FontAwesomeIcon
              size='xs'
              icon={faClose}
              className='text-gray-500'
            />
          </div>
        </div>
      ),
    },
    {
      key: "01",
      type: "group",
      style: { overflow: "auto", maxHeight: 600, margin: -8, padding: 0 },
      children:
        notif && notif.length === 0
          ? emptyNotif
          : page < lastPage
          ? [...notifListArr, moreData]
          : notifListArr,
    },
    {
      key: "02",
      type: "group",
      label:
        notif && notif.length !== 0 ? (
          <div className='flex items-center justify-end mt-3 my-1'>
            <a
              className='text-sm font-semibold leading-relaxed text-emerald-700 antialiased tracking-tight'
              onClick={() => readNotif()}>
              Tandai semua dibaca
            </a>
          </div>
        ) : null,
    },
  ];

  const onClickNotif = (key) => {
    if (key.split(" ")[1] === "false") {
      readNotif(key.split(" ")[0]);
    }
  };

  const menuNotif = () => {
    return (
      <Menu
        style={{ borderRadius: 7 }}
        items={notifList}
        onClick={(e) => onClickNotif(e.key)}
        triggerSubMenuAction='click'
      />
    );
  };

  const handleVisibleChange = (flag) => {
    setOpen(flag);
  };

  return (
    <div className='notification' onClick={() => readNotif()}>
      <Dropdown
        overlay={menuNotif()}
        menu={{ notifList }}
        onVisibleChange={handleVisibleChange}
        visible={open}
        trigger={["click"]}>
        <div className='flex items-center relative'>
          <img src={notifOff} className='notifIcon' alt='notifIcon' />
          {unReadNotif !== 0 ? (
            <div className='absolute bottom-0 right-4 bottom-auto left-auto z-10 inline-block rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 whitespace-nowrap rounded-full bg-red-600 py-1 px-2 text-center align-baseline text-xs font-bold leading-none text-white'>
              {unReadNotif || 0}
            </div>
          ) : null}
        </div>
      </Dropdown>
    </div>
  );
};

export default Notification;
