import { useAtom } from "jotai";
import React from "react";

import {
  isEditProfileAtom,
  userProfileAtom,
  pickupLoactionsAtom,
} from "@store/user.store";
import Button from "@components/atom/Button";
import { has } from "lodash";

const ProfileDetail = () => {
  const [userProfile] = useAtom(userProfileAtom);
  const [pickupLocation] = useAtom(pickupLoactionsAtom);
  const [, setIsEditProfile] = useAtom(isEditProfileAtom);
  const convertPhone = (data) =>
    data && data.slice(0, 2) === "62" ? data.substring(2) : data;

  return (
    <div className='container mx-auto space-y-5 pb-5'>
      <div className='flex flex-grow justify-between py-3'>
        <p className='text-2xl font-bold text-gray-700'>Account Settings</p>
        <Button textButton={"Edit"} onClick={() => setIsEditProfile(true)} />
      </div>

      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <p className='text-2xl font-bold text-gray-700'>{userProfile?.name}</p>
        {userProfile?.hasOwnProperty("fulfillmentRate") ? (
          <p className='text-base mt-2'>
            Fulfillment Rating: {userProfile?.fulfillmentRate.toFixed(2)}%
          </p>
        ) : null}
      </div>
      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <p className='text-2xl font-bold pb-4 text-gray-700'>Info Personal</p>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Nama Profile
            </p>
            <div className='text-base font-bold'>{userProfile?.name}</div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>Locale</p>
            <div className='text-base font-bold'>{userProfile?.locale}</div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4 '>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Alamat Email
            </p>
            <div className='text-base font-bold'>{userProfile?.email}</div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>Phone</p>
            <div className='text-base font-bold'>
              {userProfile?.phone
                ? `+62${convertPhone(userProfile?.phone)}`
                : "-"}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>Alamat</p>
            <div className='text-base font-bold'>{userProfile?.address}</div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Warehouse Code
            </p>
            <div className='text-base font-bold'>
              {userProfile?.warehouseId}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Whatsapp Phone
            </p>
            <div className='text-base font-bold'>
              {userProfile?.phoneWa
                ? `+62${convertPhone(userProfile?.phoneWa)}`
                : "-"}
            </div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>Notes</p>
            <div className='text-base font-bold'>
              {userProfile?.notes || "-"}
            </div>
          </div>
        </div>
      </div>

      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <p className='text-2xl font-bold pb-4 text-gray-700'>
          Infomasi lainnya
        </p>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Cut-off Time
            </p>
            <div className='text-base font-bold'>
              {`${userProfile?.cutoffTime} WIB` || "-"}
            </div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Nama Bank
            </p>
            <div className='text-base font-bold'>
              {userProfile?.bankName || "-"}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Terms of Payment
            </p>
            <div className='text-base font-bold'>
              {userProfile?.termPayment || "-"}
            </div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Nama Pemilik Rekening
            </p>
            <div className='text-base font-bold'>
              {userProfile?.bankAccountName || "-"}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Tipe Partner
            </p>
            <div className='text-base font-bold'>
              {userProfile?.partnerType || "-"}
            </div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Nomor Rekening
            </p>
            <div className='text-base font-bold'>
              {userProfile?.bankRek || "-"}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Intercity SLA
            </p>
            <div className='text-base font-bold'>
              {userProfile?.intercitySla || "-"}
            </div>
          </div>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Pickup Locale
            </p>
            <div className='text-base font-bold'>
              {userProfile?.pickupLocale || "-"}
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-3 pb-4'>
          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>NPWP</p>
            <div className='text-base font-bold'>
              {userProfile?.npwp || "-"}
            </div>
          </div>

          <div>
            <p className='pb-1 text-base font-medium text-gray-500'>
              Limit TOP
            </p>
            <div className='text-base font-bold'>
              {userProfile?.limitTop || "-"}
            </div>
          </div>
        </div>
      </div>
      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <div className='grid md:grid-cols gap-3 pb-4'>
          <div>
            <p className='text-2xl font-bold text-gray-700 pb-4'>Info Pickup</p>
            {pickupLocation.length ? (
              <div className='mb-3 align-start w-11/12'>
                <div className='flex w-full mb-2 gap-3 items-center bg-gray-100'>
                  <p className='w-1/5 font-medium text-gray-500'>
                    Nama Warehouse
                  </p>
                  <p className='w-3/5 font-medium text-gray-500'>Alamat</p>
                  <p className='w-1/5 font-medium text-gray-500'>Plus Code</p>
                </div>
                {pickupLocation.map((item, idx) => (
                  <div
                    key={idx}
                    className='flex w-full mb-2 gap-3 items-center'>
                    <div className='text-base font-bold w-1/5'>
                      {item?.name}
                    </div>
                    <div className='text-base font-bold w-3/5'>
                      {item?.address}
                    </div>
                    <div className='text-base font-bold w-1/5'>
                      {item?.pluscode}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className='flex w-full mb-2 gap-3 items-center'>
                <div className='text-base font-bold w-1/5'>-</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <div className='grid md:grid-cols gap-3 pb-4'>
          <div>
            <p className='text-2xl font-bold text-gray-700 pb-4'>
              Info Rekening
            </p>
            {has(userProfile, "partnerBankAccount") ? (
              <div className='mb-3 align-start w-11/12'>
                <div className='flex w-full mb-2 gap-3 items-center bg-gray-100'>
                  <p className='w-1/5 font-medium text-gray-500'>Nama Bank</p>
                  <p className='w-3/5 font-medium text-gray-500'>
                    Nomor Rekening
                  </p>
                  <p className='w-1/5 font-medium text-gray-500'>
                    Nama Pemilik Rekening
                  </p>
                </div>
                {userProfile?.partnerBankAccount.map((item, idx) => (
                  <div
                    key={idx}
                    className='flex w-full mb-2 gap-3 items-center'>
                    <div className='text-base font-bold w-1/5'>
                      {item?.bankName}
                    </div>
                    <div className='text-base font-bold w-3/5'>
                      {item?.bankRekening}
                    </div>
                    <div className='text-base font-bold w-1/5'>
                      {item?.accountName}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className='flex w-full mb-2 gap-3 items-center'>
                <div className='text-base font-bold w-1/5'>-</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
