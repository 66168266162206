import React from "react";

import Button from "@components/atom/Button";
import { ProfileEditLogic } from "./ProfileEdit.logic";
import { Controller } from "react-hook-form";
import { Input, Select } from "antd";
import WarehouseField from "./components/warehouseField/WarehouseField";
import BankAccountField from "./components/bankAccountField/BankAccountField";
const { Option } = Select;

const ProfileEdit = (data) => {
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    register,
    onSubmit,
    userProfile,
    setIsEditProfile,
    listsTermOfPayment,
    PasswordStatusConfirm,
  } = ProfileEditLogic(data);

  return (
    <div className='container mx-auto'>
      <form className='profil space-y-5 pb-5' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-grow justify-between py-3'>
          <p className='text-2xl font-bold text-gray-700'>Account Settings</p>
          <div className='flex'>
            <div className='mr-2'>
              <Button textButton={"Save"} type='submit' />
            </div>
            <Button
              textButton={"Batalkan"}
              onClick={() => setIsEditProfile(false)}
              style={{
                backgroundColor: "#ffff",
                color: "#01573e",
              }}
            />
          </div>
        </div>
        <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
          <p className='text-2xl font-bold text-gray-700'>
            {userProfile?.name}
          </p>
          {userProfile?.hasOwnProperty("fulfillmentRate") ? (
            <p className='text-lg mt-2'>
              Fulfillment Rating: {userProfile?.fulfillmentRate.toFixed(2)}%
            </p>
          ) : null}
        </div>
        <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
          <p className='text-2xl font-bold pb-4 text-gray-700'>Info Personal</p>
          <div className='grid md:grid-cols-2 gap-3 pb-4'>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Nama Profile
              </p>
              <input
                className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                {...register("name")}
              />
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>Locale</p>
              <input
                className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                {...register("locale")}
              />
            </div>
          </div>
          <div className='grid md:grid-cols-2 gap-3 pb-4 '>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Alamat Email
              </p>
              <input
                className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                {...register("email")}
              />
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>Phone</p>
              <div className='flex items-center gap-x-2'>
                <input
                  type='text'
                  disabled
                  className='w-24 border border-gray-300 w-full font-medium text-slate-400 rounded h-10 flex items-center px-3 bg-gray-100'
                  value={"+62"}
                />
                <input
                  type='text'
                  className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                  {...register("phone")}
                />
              </div>
            </div>
          </div>
          <div className='grid md:grid-cols-2 gap-3 pb-4'>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>Alamat</p>
              <input
                className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                {...register("address")}
              />
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Warehouse Code
              </p>
              <input
                disabled
                className='border bg-gray-100 bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3'
                {...register("warehouseId")}
              />
            </div>
          </div>

          <div className='grid md:grid-cols-2 gap-3 pb-4'>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Whatsapp Phone
              </p>
              <div className='flex items-center gap-x-2'>
                <input
                  type='text'
                  disabled
                  className='w-24 border border-gray-300 w-full font-medium text-slate-400 rounded h-10 flex items-center px-3 bg-gray-100'
                  value={"+62"}
                />
                <input
                  type='text'
                  className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                  {...register("phone_wa")}
                />
              </div>
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>Notes</p>
              <div className='flex items-center gap-x-2'>
                <textarea
                  rows={10}
                  className='border border-gray-300 w-full rounded flex items-center p-3 bg-white'
                  {...register("notes")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
          <p className='text-2xl font-bold text-gray-700'>Informasi lainnya</p>
          <p className='text-base font-normal pb-4 text-gray-500'>
            Jika ingin mengupdate data informasi bank dan lainnya harap hubungi
            cs admin
          </p>
          <div className='grid md:grid-cols-2 gap-3 pb-4'>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Cut-off Time
              </p>
              <input
                disabled
                className='border bg-gray-100 bg-clip-padding
              border-solid border-gray-300 w-full rounded h-10 flex items-center px-3'
                {...register("cutoffTime")}
              />
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Nama Bank
              </p>
              <input
                disabled
                className='border bg-gray-100 bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3'
                {...register("bank_name")}
              />
            </div>
          </div>
          <div className='grid md:grid-cols-2 gap-3 pb-4 '>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Term Of Payment
              </p>
              <div>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      style={{ width: "100%", borderRadius: 10 }}
                      placeholder='Pilih Status'
                      onChange={onChange}
                      value={value}>
                      {listsTermOfPayment.map((status, idx) => {
                        return (
                          <Option value={status.value} key={idx}>
                            {status.label}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  name='term_payment'
                  defaultValue=''
                />
              </div>
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Nama Pemilik Rekening
              </p>
              <div className='flex items-center gap-x-2'>
                <input
                  disabled
                  className='border bg-gray-100 bg-clip-padding border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 '
                  {...register("bank_account_name")}
                />
              </div>
            </div>
          </div>

          <div className='grid md:grid-cols-2 gap-3 pb-4 '>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Tipe Partner
              </p>
              <input
                disabled
                className='border bg-gray-100 bg-clip-padding border-solid border-gray-300 w-full rounded h-10 flex items-center px-3'
                {...register("partnerType")}
              />
            </div>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>
                Nomer Rekening
              </p>
              <div className='flex items-center gap-x-2'>
                <input
                  disabled
                  className='border bg-gray-100 bg-clip-padding border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 '
                  {...register("bank_rek")}
                />
              </div>
            </div>
          </div>

          <div className='grid md:grid-cols-2 gap-3 pb-4 '>
            <div>
              <p className='pb-1 text-base font-medium text-gray-500'>NPWP</p>
              <input
                disabled
                className='border bg-gray-100 bg-clip-padding border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 '
                {...register("npwp")}
              />
            </div>
          </div>
        </div>

        <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
          <p className='text-2xl font-bold text-gray-700 pb-4'>
            Reset Password
          </p>

          <div className='grid md:grid-cols-2 gap-3 pb-4'>
            <div>
              <div className='flex flex-row align-center'>
                <p className='pb-1 text-base font-medium text-gray-500'>
                  Password Baru
                </p>
              </div>
              <div>
                <Controller
                  control={control}
                  rules={{
                    minLength: { value: 6, message: "Minimal 6 characters" },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input.Password
                      className='border border-gray-300 w-full rounded h-10 flex items-center px-3 bg-white'
                      size='large'
                      onChange={onChange}
                      value={value}
                      name='password'
                      autoComplete='new-password'
                    />
                  )}
                  name='password'
                  defaultValue=''
                />
                {errors.password?.message && (
                  <p className='text-red-700'>{errors.password?.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white mb-4'>
        <div className='grid md:grid-cols gap-3 pb-4'>
          <div>
            <p className='text-2xl font-bold text-gray-700 pb-4'>Info Pickup</p>
            <WarehouseField />
          </div>
        </div>
      </div>

      <div className='grid gap-y-1 px-4 py-4 rounded-2xl shadow-md bg-white'>
        <div className='grid md:grid-cols gap-3 pb-4'>
          <div>
            <p className='text-2xl font-bold text-gray-700 pb-4'>
              Info Rekening
            </p>
            <BankAccountField />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
