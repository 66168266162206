// eslint-disable-next-line
import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { isEmpty } from "lodash";

import { getListStock } from "@services/stock.services";
import { stockListAtom } from "@store/stock.store";
import { useDebounce } from "@utils/delay";
import {
  uploadCsvFinalStock,
  uploadCsvStockUpdate,
} from "@services/stock.services";
import { downloadStock } from "@services/stock.services";

export const StockUpdateListLogic = () => {
  const navigate = useNavigate();

  const [stocks, setStocks] = useAtom(stockListAtom);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [search, setSearch] = useState("");
  const [valueFilter, setValueFilter] = useState("code");

  useEffect(() => {
    getData(page, limit, valueFilter, search);
  }, []);

  const getData = async (page, limit, filter, name) => {
    setLoading(true);
    const params = {
      limit,
      page,
      [filter]: name,
    };

    try {
      const res = await getListStock(params);
      isEmpty(res.stocks) ? setStocks([]) : setStocks(res.stocks);
      setPage(res.page);
      setSizePerPage(res.limit);
      setLimit(res.limit);
      setTotalSize(res.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Nama Admin",
      formatter: (cell, row) => <div>{row?.name}</div>,
      headerStyle: {
        justifyContent: "center",
        width: "12%",
      },
    },
    {
      dataField: "secondaryProduct.id",
      text: "SKU",
      formatter: (cell, row) => <div>{row.secondaryProduct?.code}</div>,
    },
    {
      dataField: "secondaryProduct.codePartner",
      text: "Seller SKU",
      formatter: (cell, row) => (
        <div>{row.secondaryProduct?.codePartner || "-"}</div>
      ),
      headerStyle: {
        width: "10%",
      },
    },
    {
      dataField: "updatedAt",
      text: "Nama Produk",
      formatter: (cell, row) => <div>{row.secondaryProduct?.name}</div>,
    },
    {
      dataField: "stockIn",
      text: "Stock Masuk",
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "stockOut",
      text: "Stock Keluar",
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "finalTotal",
      text: "Final Stock",
      headerStyle: {
        width: "9%",
      },
    },
    {
      dataField: "description",
      text: "Deskripsi",
    },
    {
      dataField: "createdAt",
      text: "Tanggal",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD MMMM YYYY HH:mm")}</div>
      ),
      headerStyle: {
        width: "7%",
      },
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit, valueFilter, search);
  };

  const onLimitChange = (e) => {
    getData(1, e, valueFilter, search);
  };

  const handleSearch = useDebounce(async (e) => {
    getData(1, limit, valueFilter, e.target.value);
    setSearch(e.target.value);
  }, 1000);

  const handleChangeFilter = (val) => {
    getData(1, limit, val, search);
    setValueFilter(val);
  };

  const onUpload = async (file) => {
    const body = {
      file,
    };
    try {
      await uploadCsvStockUpdate(body);
      await message.success(`success upload csv file`);
      await getData(1, limit, valueFilter, search);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onUploadFinalCsv = async (file, type) => {
    const body = {
      file,
      type,
    };

    try {
      await uploadCsvFinalStock(body);
      await message.success(`success upload csv file`);
      await getData(1, limit, valueFilter, search);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onDownloadStock = async () => {
    try {
      const res = await downloadStock();
      await window.open(`${res.url}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    columns,
    stocks,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    navigate,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    handleChangeFilter,
    valueFilter,
    onUpload,
    onUploadFinalCsv,
    onDownloadStock,
  };
};
