import React from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accordion = ({ heading, children, topic, idx, setIsActive }) => {
  const { isActive } = topic;

  return (
    <div className='mb-3'>
      <div
        id={idx === 0 ? "welcome-topic" : ""}
        className={`p-3 rounded flex items-center justify-between cursor-pointer ${
          isActive ? "bg-green-300" : "bg-gray-300"
        }`}
        style={{ background: isActive ? "#d9f1d7" : "#ededed" }}
        onClick={() => setIsActive(idx)}>
        <p>{heading}</p>
        <span>
          {isActive ? (
            <FontAwesomeIcon icon={faChevronUp} className='mr-2' />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} className='mr-2' />
          )}
        </span>
      </div>
      {isActive && (
        <div className='p-4' style={{ background: "#f2f9fa" }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
