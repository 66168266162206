import React from "react";
import { Switch } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { DeliveryMethodLogic } from "./DeliveryMethod.logic";

const DeliveryMethod = () => {
  const { enabled, loading, onChangeToogle, delivery, selfPickup } =
    DeliveryMethodLogic();
  return (
    <div className='border border-gray-300 p-8 rounded my-10'>
      <div className='mb-3'>
        <div className='md:flex items-center justify-between block mb-3 border-b border-gray-300'>
          <p className='text-xl font-bold mb-3'>
            <FontAwesomeIcon icon={faShippingFast} /> Metode Pengiriman
          </p>
        </div>
        <div className='mb-5'>
          <p className='font-bold text-xl mb-3'>Delivery</p>
          <div className='flex mb-2 items-center gap-2'>
            <span
              className={`${
                delivery ? "text-gray-300" : "text-black-300"
              } font-semibold mr-1`}>
              Disable
            </span>
            <Switch
              style={{ backgroundColor: delivery ? "#059669" : "white" }}
              checked={delivery}
              onChange={() => onChangeToogle("delivery")}
              loading={loading}
            />
            <span
              className={`${
                !delivery ? "text-gray-300" : "text-black-300"
              } font-semibold ml-1`}>
              Enable
            </span>
          </div>
        </div>
        <div>
          <p className='font-bold text-xl mb-3'>Self Pickup</p>
          <div className='flex mb-2 items-center gap-2'>
            <span
              className={`${
                selfPickup ? "text-gray-300" : "text-black-300"
              } font-semibold mr-1`}>
              Disable
            </span>
            <Switch
              style={{ backgroundColor: selfPickup ? "#059669" : "white" }}
              checked={selfPickup}
              onChange={() => onChangeToogle("selfPickup")}
              loading={loading}
            />
            <span
              className={`${
                !selfPickup ? "text-gray-300" : "text-black-300"
              } font-semibold ml-1`}>
              Enable
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethod;
