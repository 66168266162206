import { message } from "antd";
import { isEmpty, isNil } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { upsertWarehouseCoverageArea } from "@services/masterRequest.services";
import { getListCityOptions } from "@services/dictionaries.services";
import { convertStatusToSelect } from "@utils/convertData";
import { getListDistrictOptions } from "@services/dictionaries.services";
import { mappingAreaCity, mappingAreaDistrict } from "@utils/convertData";

export const CoverageAreaCreateLogic = () => {
  const navigate = useNavigate();

  const [dataCoverageArea, setDataCoverageArea] = useState([
    {
      districtOptions: [],
    },
  ]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getListCityOptions();
      isEmpty(res.entries)
        ? setCityOptions([])
        : setCityOptions(convertStatusToSelect(res.entries));
    } catch (error) {}
  };

  const onSubmit = async () => {
    if (
      isEmpty(dataCoverageArea[0].city) ||
      isEmpty(dataCoverageArea[0].district)
    ) {
      message.error("data kota atau kecamatan harus diisi");
    } else {
      const body = {
        coverages: dataCoverageArea.map((item) => ({
          city: item.city.value,
          district: item.district.value,
          minPurchase: parseFloat(item.minPurchase),
          shippingFee: parseFloat(item.shippingFee),
        })),
      };
      setIsLoading(true);
      try {
        await upsertWarehouseCoverageArea(body);
        await navigate("/master/coverage-area");
        await message.success("berhasil buat tujuan pengiriman");
        await setIsLoading(false);
      } catch (error) {
        await setIsLoading(false);
        await message.error(`${error.data.message}`);
      }
    }
  };

  const onAdd = () => {
    const data = {};
    setDataCoverageArea([...dataCoverageArea, data]);
  };

  const onChange = async (e, index, params) => {
    if (params === "city") {
      if (isNil(e)) {
        setDataCoverageArea(mappingAreaCity(dataCoverageArea, e, index, []));
      } else {
        try {
          const res = await getListDistrictOptions(e.value);
          isEmpty(res.entries)
            ? setDataCoverageArea(
                mappingAreaCity(dataCoverageArea, e, index, [])
              )
            : setDataCoverageArea(
                mappingAreaCity(
                  dataCoverageArea,
                  e,
                  index,

                  convertStatusToSelect(res.entries)
                )
              );
        } catch (error) {}
      }
    } else {
      setDataCoverageArea(mappingAreaDistrict(dataCoverageArea, e, index));
    }
  };

  const onRemove = (index) => {
    if (dataCoverageArea.length > 1) {
      const data = dataCoverageArea.filter((_, idx) => idx !== index);
      setDataCoverageArea(data);
    }
  };

  const onBack = () => navigate("/master/coverage-area");

  const onChangeText = (e, index, params) => {
    const data = dataCoverageArea.map((item, idx) => ({
      ...item,
      [params]: index === idx ? e.target.value : item.params,
    }));
    setDataCoverageArea(data);
  };

  return {
    dataCoverageArea,
    onAdd,
    onRemove,
    onChange,
    onSubmit,
    onBack,
    cityOptions,
    isLoading,
    onChangeText,
  };
};
