import { message } from "antd";
import { debounce, isEmpty } from "lodash";
import { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getListWorkshops } from "@services/user.services";
import { convertDataToSelect } from "@utils/convertData";
import {
  getWhitelist,
  updateWhitelist,
} from "../../../../services/masterRequest.services";
import { useEffect } from "react";
import { convertEditWhitelist } from "../../../../utils/convertData";

export const PaylaterSettingsEditLogic = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [whitelists, setWhitelists] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const whitelist = await getWhitelist(params.id);
      setWhitelists(convertEditWhitelist(whitelist));
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    const body = {
      workshopId: whitelists[0].workshopId.value,
      maxCreditLimit: parseInt(whitelists[0].maxCreditLimit),
      credit: parseInt(whitelists[0].credit),
      active: whitelists[0].active,
      provider: whitelists[0].provider,
    };

    try {
      await updateWhitelist(body);
      await onBack();
      await message.success("Berhasil update whitelist");
    } catch (error) {
      await message.error(`${error.data.message}`);
    }
  };

  const onChange = (e, index, params) => {
    const data = whitelists.map((whitelist, id) =>
      id === index
        ? {
            ...whitelist,
            [params]: e,
          }
        : whitelist
    );
    setWhitelists(data);
  };

  const onChangeCreditLimit = (e, index, params) => {
    const data = whitelists.map((whitelist, id) =>
      id === index
        ? {
            ...whitelist,
            [params]: e.target.value,
          }
        : whitelist
    );
    setWhitelists(data);
  };

  const getAsyncOptions = async (workshop_id) => {
    const params = {
      page: 1,
      workshop_id,
    };
    try {
      const res = await getListWorkshops(params);
      return isEmpty(res) ? [] : convertDataToSelect(res.users, "workshopId");
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      if (inputText.length > 0)
        getAsyncOptions(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const onBack = () => navigate("/master/paylater-settings");

  return {
    whitelists,
    onChange,
    onSubmit,
    onBack,
    onChangeCreditLimit,
    isLoading,
    loadOptions,
  };
};
