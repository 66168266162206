import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Submenu = ({ icon, label, subRoutes, onClick }) => {
  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <div>
      <NavLink
        to='#'
        onClick={() => setIsCollapse(!isCollapse)}
        className='navbar-side flex items-center justify-between inactive'>
        <div className='flex items-center'>
          <FontAwesomeIcon icon={icon} className='mr-2' />
          <p className='text-xs uppercase'>{label}</p>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`mr-2 ${isCollapse && "rotate-90"}`}
        />
      </NavLink>
      <div
        className={`${
          isCollapse ? "block transition duration-150 ease-linear" : "hidden"
        } `}>
        {subRoutes.map((route, idx) => (
          <NavLink
            key={idx}
            to={route.path}
            onClick={onClick}
            className='navbar-side flex items-center ml-5'
            activeclassname='active'>
            <p className='text-xs uppercase'>{route.label}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Submenu;
