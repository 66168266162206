import React from "react";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { isEmpty, isNil } from "lodash";

import Button from "@components/atom/Button";
import ButtonOutlined from "@components/atom/ButtonOutlined";
import { timeOptions } from "@utils/options";
import { OperationalHourLogic } from "./OperationalHour.logic";

const openingHoursOptions = [
  {
    label: "Buka 24 jam",
    value: "24:00",
  },
  {
    label: "Tutup",
    value: "",
  },
  {
    label: "00:00 WIB",
    value: "00:00",
  },
  ...timeOptions,
];

const closingHoursOptions = [
  ...timeOptions,
  {
    label: "24:00 WIB",
    value: "",
  },
];

const OperationalHour = () => {
  const {
    isDisplaySchedule,
    setisDisplaySchedule,
    openingHours,
    closingHours,
    onSelectOpeningHours,
    onSelectClosingHours,
    onSubmitOperatingHours,
  } = OperationalHourLogic();

  const RenderSelect = ({ label, params }) => {
    return (
      <div className='flex mb-3 items-center'>
        <p className='w-20'>{label}</p>
        <div className='w-48 mr-3'>
          <ReactSelect
            options={openingHoursOptions}
            defaultValue={openingHours[params]}
            value={openingHours[params]}
            onChange={(e) => onSelectOpeningHours(e, params)}
            isClearable
          />
        </div>
        <div className='w-48'>
          <ReactSelect
            isDisabled={
              openingHours[params].label === "Buka 24 jam" ||
              openingHours[params].label === "Tutup"
            }
            options={closingHoursOptions}
            defaultValue={closingHours[params]}
            value={closingHours[params]}
            onChange={(e) => onSelectClosingHours(e, params)}
            isClearable
          />
        </div>
      </div>
    );
  };

  const RenderDetail = ({ label, params }) => {
    if (isNil(openingHours[params]) || isNil(closingHours[params])) return null;
    return (
      <div className='flex'>
        <p className='w-16'>{label}</p>{" "}
        {openingHours[params]?.label === "Tutup" ||
        (openingHours[params]?.label === "Buka 24 jam" &&
          isEmpty(closingHours[params]?.label)) ? (
          <span> {openingHours[params]?.label}</span>
        ) : (
          <span>
            {openingHours[params]?.label} - {closingHours[params]?.label}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className='border border-gray-300 p-8 rounded my-10'>
      <div className='md:flex items-center justify-between block mb-3 border-b border-gray-300'>
        <div className='mb-3'>
          <p className='text-xl font-bold'>
            <FontAwesomeIcon icon={faClock} /> Atur jam operasional toko
          </p>
          <p>
            Kamu bisa mengatur jadwal tokomu mulai beroperasi dan tutup di
            setiap minggunya
          </p>
        </div>
        <div>
          {!isDisplaySchedule && (
            <ButtonOutlined
              textButton={"Atur Jam Operasional"}
              onClick={() => setisDisplaySchedule(true)}
            />
          )}
        </div>
      </div>
      {isDisplaySchedule ? (
        <div>
          <div className='flex my-2'>
            <p className='w-20'>Hari</p>
            <div>
              <p className='w-48'>Jam Buka</p>
            </div>
            <div className='ml-3'>
              <p className='w-48'>Jam Tutup</p>
            </div>
          </div>
          <RenderSelect label='Senin' params='monday' />
          <RenderSelect label='Selasa' params='tuesday' />
          <RenderSelect label='Rabu' params='wednesday' />
          <RenderSelect label='Kamis' params='thursday' />
          <RenderSelect label='Jumat' params='friday' />
          <RenderSelect label='Sabtu' params='saturday' />
          <div className='md:flex items-center justify-between block'>
            <RenderSelect label='Minggu' params='sunday' />
            <div className='flex justify-end'>
              <ButtonOutlined
                textButton={"Batal"}
                style={{ marginRight: 5 }}
                onClick={() => window.location.reload()}
              />
              <Button textButton={"Simpan"} onClick={onSubmitOperatingHours} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <RenderDetail label='Senin' params='monday' />
            <RenderDetail label='Selasa' params='tuesday' />
            <RenderDetail label='Rabu' params='wednesday' />
            <RenderDetail label='Kamis' params='thursday' />
            <RenderDetail label='Jumat' params='friday' />
            <RenderDetail label='Sabtu' params='saturday' />
            <RenderDetail label='Minggu' params='sunday' />
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationalHour;
