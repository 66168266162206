import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { SkuTableHistoryLogic } from "./SkuTableHistory.logic";

const SkuTableHistory = ({ data }) => {
  const { columns } = SkuTableHistoryLogic();
  return (
    <div>
      <p className="font-bold mb-2">Riwayat SKU</p>
      <div className="table-striped table-responsive">
        <BootstrapTable
          keyField="id"
          remote
          data={data && data?.history ? data?.history : []}
          bordered={false}
          columns={columns}
          hover
          noDataIndication={() => <div className="text-center">Empty Data</div>}
        />
      </div>
    </div>
  );
};

export default SkuTableHistory;
