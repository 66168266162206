import { useState, useEffect } from "react";
import moment from "moment";
import { isNil } from "lodash";
import {
  faBox,
  faMoneyBillWave,
  faChartLine,
  faArrowsAltV,
  faRotateBack,
  faRectangleXmark,
  faCartFlatbed,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { getAnalytics } from "@services/analytics.services";
import { message } from "antd";

const convertToChart = (dataRes, title) => {
  const defaultChart = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    datasets: [
      {
        label:
          title === "Jumlah Order (Invoice)" ? "Jumlah Order (Pcs)" : title,
        data: [33, 53, 85, 41, 44, 65, 44, 65, 85, 41, 10, 99],
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(
            0,
            title === "Jumlah Order (Invoice)"
              ? "rgba(4,155,255,1)"
              : "rgba(250,174,50,1)"
          );
          gradient.addColorStop(1, "rgba(255,255,255,0.01)");
          return gradient;
        },
        borderColor:
          title === "Jumlah Order (Invoice)"
            ? "rgba(4,155,255,1)"
            : "rgba(250,174,50,1)",
        borderWidth: 1.7,
      },
    ],
  };

  let labels = [];
  let dataT = [];
  let dataC = [];
  dataRes.sort((a, b) => moment(a.date) - moment(b.date));
  for (let item of dataRes) {
    labels.push(item.date.slice(0, -5));
    title === "Jumlah Order (Invoice)"
      ? dataC.push(parseFloat(item.numOrder))
      : dataT.push(parseFloat(item.totalOrder));
  }
  defaultChart.datasets[0].data =
    title === "Jumlah Order (Invoice)" ? dataC : dataT;
  defaultChart.datasets[0]["title"] = title;

  return {
    ...defaultChart,
    labels,
  };
};

export const AnalyticsLogic = () => {
  const [cardData, setCardData] = useState(null);
  const [activeTab, setActiveTab] = useState({});
  const [chartCumulative, setChartCumulative] = useState({});
  const [chartTotal, setChartTotal] = useState({});
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState(moment().format("DD-MM-YYYY"));
  const [isTransactionExist, setIsTransactionExist] = useState(true);

  const options = (title) => ({
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: { stepSize: 1, beginAtZero: true },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
    elements: {
      line: {
        // tension: 0.4,
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
      title: {
        display: true,
        text: title === "Jumlah Order (Invoice)" ? "Jumlah Order (Pcs)" : title,
        font: {
          size: 16,
          family: "Mulish",
        },
        padding: 15,
      },
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          family: "Mulish",
        },
      },
    },
    interaction: {
      intersect: true,
    },
  });

  const getData = async (from, to) => {
    const param = {
      date_from: from || "",
      date_to: to || "",
    };
    try {
      const res = await getAnalytics(param);
      const renderIcon = (item) => {
        switch (item) {
          case "cumulativeNumOrder":
            return { name: "Jumlah Order Diproses", icon: faBox };
          case "cumulativeTotalOrder":
            return { name: "Total Order Diproses", icon: faMoneyBillWave };
          case "cumulativeFulfillmentPercentage":
            return { name: "% Order Dipenuhi", icon: faChartLine };
          case "averageSLA":
            return { name: "Average SLA", icon: faArrowsAltV };
          case "rejectedItem":
            return { name: "Jumlah Barang Ditolak", icon: faRotateBack };
          case "totalRejectedItem":
            return { name: "Total Order Ditolak", icon: faRectangleXmark };
          case "cumulativeProductOutOfStock":
            return { name: "Jumlah SKU Kosong", icon: faCartFlatbed };
          case "lifetimeSLA":
            return { name: "Lifetime SLA", icon: faClock };
          default:
            break;
        }
      };

      const objToArr = Object.entries(res)
        .map((val, idx) => ({
          ...renderIcon([val[0]][0]),
          [val[0]]: val[1],
          value: val[1],
          activeTab: false,
          id: idx + 1,
        }))
        .filter((e) => Object.keys(e)[0] !== "dailyData")
        .filter((item) => item.name);

      setCardData(objToArr);
      if (isNil(res?.dailyData)) {
        setIsTransactionExist(false);
        const defaultChart = [
          {
            date: moment(from, "DD-MM-YYYY").format("DD MMM YYYY"),
            numOrder: "0",
            totalOrder: "0",
          },
          {
            date: moment(to, "DD-MM-YYYY").format("DD MMM YYYY"),
            numOrder: "0",
            totalOrder: "0",
          },
        ];
        setChartCumulative(
          convertToChart(defaultChart, "Jumlah Order (Invoice)")
        );
        setChartTotal(convertToChart(defaultChart, "Total Order (IDR)"));
      } else {
        setIsTransactionExist(true);
        setChartCumulative(
          convertToChart(res?.dailyData, "Jumlah Order (Invoice)")
        );
        setChartTotal(convertToChart(res?.dailyData, "Total Order (IDR)"));
      }
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  useEffect(() => {
    getData(startDate, endDate);
  }, []);

  const dateRange = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("DD-MM-YYYY")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("DD-MM-YYYY")}`;
    getData(tempStartDate, tempEndDate);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const handleSelect = (item) => {
    const newArr = cardData.map((obj) => {
      if (obj.id === item.id) return { ...obj, activeTab: true };
      return { ...obj, activeTab: false };
    });
    setActiveTab(item);
    setCardData(newArr);
  };

  return {
    cardData,
    dateRange,
    handleSelect,
    options,
    chartCumulative,
    chartTotal,
    startDate,
    endDate,
    isTransactionExist,
  };
};
