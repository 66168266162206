export const formatPriceIdr = (price) => {
  let formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};

export const formatNum = (price) => {
  let formatter = new Intl.NumberFormat("id-ID", {
    style: "decimal",
    minimumFractionDigits: 0,
  });

  return formatter.format(price);
};
