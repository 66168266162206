import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNil } from "lodash";
import { Modal, message } from "antd";
import { useAtom } from "jotai";

import {
  getDetailTransaction,
  updateStatusTransaction,
  verifyPayment,
} from "@services/salesReports.services";
import { formatPriceIdr } from "@utils/format";
import { fullDate } from "@utils/formatDate";
import { findInvoiceDiscount, findShippingDiscount } from "@utils/convertData";
import { userProfileAtom } from "@store/user.store";

export const SalesReportDetailLogic = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [userProfile] = useAtom(userProfileAtom);

  const [dataDetail, setDataDetail] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getDetailTransaction(params?.id);
      setDataDetail(res);
    } catch (error) {
      console.log(error);
    }
  };

  const transactionDetailColumns = [
    {
      dataField: "productName",
      text: "Nama Produk",
      sort: true,
      formatter: (cell, row) => `${row.productName}`,
    },
    {
      dataField: "secondaryProductId",
      text: "Nama Secondary Produk",
      sort: true,
      formatter: (cell, row) =>
        isNil(row.secondaryProduct.warehouse)
          ? "-"
          : `${row.secondaryProduct.name} (${row.secondaryProduct.warehouse[0].code})`,
    },
    {
      dataField: "code",
      text: "SKU",
      sort: true,
      formatter: (cell, row) => `${row.sku}`,
    },
    {
      dataField: "transactionId",
      text: "Stok",
      sort: true,
      formatter: (cell, row) =>
        isNil(row.secondaryProduct.warehouse)
          ? "-"
          : `${row.secondaryProduct.warehouse[0].quantity}`,
    },
    {
      dataField: "qty",
      text: "Jumlah",
      sort: true,
      formatter: (cell, row) => `${row.qty}`,
    },
    {
      dataField: "price",
      text: "Harga/satuan",
      sort: true,
      formatter: (cell, row) => {
        return <p>{formatPriceIdr(row.initialPrice)}</p>;
      },
    },
    {
      dataField: "discountPercentage",
      text: "Diskon %",
      sort: false,
      formatter: (cell) => <p className='text-center'>{cell}</p>,
    },
    {
      dataField: "totalPrice",
      text: "Total Harga",
      sort: true,
      formatter: (cell, row) => (
        <p>{formatPriceIdr(row.initialPrice * row.qty - row.discount)}</p>
      ),
    },
  ];

  const transactionHistoryColumns = [
    {
      dataField: "createdAt",
      text: "Tanggal",
      sort: true,
      formatter: (cell) => (cell ? fullDate(cell) : "-"),
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "admin",
      text: "Admin",
      sort: true,
      formatter: (cell, row) => `${row.admin.name}`,
    },
  ];

  const onBack = () => {
    navigate("/sales-report");
  };

  const handleChange = async (e) => {
    const payload = {
      transaction_id: params?.id,
      status: e,
    };
    await Modal.confirm({
      title: "Konfirmasi",
      content: `Apakah anda ingin mengubah status ke ${e}?`,
      okText: "Ya",
      cancelText: "Tidak",
      onOk: async () => {
        try {
          await updateStatusTransaction(payload);
          await getData();
          await message.success("Berhasil update status");
        } catch (error) {
          await message.error(error.data.message);
        }
      },
    });
  };

  const onVerifyPayment = async () => {
    const payload = {
      transactions: [
        {
          invoice_number: dataDetail?.code,
          admin_name: userProfile?.name,
          payment_date: dataDetail?.paymentDate,
        },
      ],
    };
    await Modal.confirm({
      title: "Konfirmasi",
      content: `Apakah anda yakin untuk verifikasi pembayaran?`,
      okText: "Ya",
      cancelText: "Tidak",
      onOk: async () => {
        try {
          await verifyPayment(payload);
          await getData();
          await message.success("Berhasil verifikasi pembayaran");
        } catch (error) {
          await message.error(error.data.message);
        }
      },
    });
  };

  const onDownloadInvoicePdf = async () => {
    await window.open(dataDetail?.urlInvoice, "_blank");
  };

  const invoiceDiscount = findInvoiceDiscount(dataDetail?.voucherDiscounts);
  const shippingDiscount = findShippingDiscount(dataDetail?.voucherDiscounts);

  return {
    onBack,
    dataDetail,
    transactionDetailColumns,
    transactionHistoryColumns,
    handleChange,
    invoiceDiscount,
    shippingDiscount,
    onVerifyPayment,
    onDownloadInvoicePdf,
  };
};
