import React from "react";
import ButtonOutlined from "@components/atom/ButtonOutlined";
import Button from "@components/atom/Button";
import { WarehouseFieldLogic } from "./WarehouseField.logic";

const WarehouseField = () => {
  const {
    onChangeName,
    onRemove,
    onChangeAddress,
    warehouses,
    onAddWarehouse,
    onSubmit,
    onChangePlusCode,
    isEditMode,
    setIsEditMode,
  } = WarehouseFieldLogic();
  return (
    <div className="border border-gray-300 p-8 rounded">
      {warehouses.length ? (
        <div className="mb-3 align-start w-11/12">
          <div className="flex w-full mb-2 gap-3 items-center">
            <p className="w-1/5 font-bold">Nama Warehouse</p>
            <p className="w-3/5 font-bold">Alamat</p>
            <p className="w-1/5 font-bold">Plus Code</p>
          </div>
          {isEditMode
            ? warehouses.map((item, idx) => (
                <div key={idx} className="flex w-full mb-2 gap-3 items-center">
                  <input
                    className="border bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-1/5"
                    value={item.name}
                    onChange={(e) => onChangeName(e.target.value, idx)}
                    placeholder="Nama alamat"
                  />

                  <input
                    className="border bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-3/5"
                    value={item.address}
                    onChange={(e) => onChangeAddress(e.target.value, idx)}
                    placeholder="Alamat lengkap"
                  />

                  <input
                    className="border bg-clip-padding
            border-solid border-gray-300 w-full rounded h-10 flex items-center px-3 w-1/5"
                    value={item.pluscode}
                    onChange={(e) => onChangePlusCode(e.target.value, idx)}
                    placeholder="Plus code"
                  />

                  <p
                    className="cursor-pointer pl-3"
                    onClick={() => onRemove(idx, item)}
                  >
                    X
                  </p>
                </div>
              ))
            : warehouses.map((item, idx) => (
                <div key={idx} className="flex w-full mb-2 gap-3 items-center">
                  <div className="text-base w-1/5">{item?.name}</div>
                  <div className="text-base w-3/5">{item?.address}</div>
                  <div className="text-base w-1/5">{item?.pluscode}</div>
                </div>
              ))}
        </div>
      ) : null}
      <div className="sm:flex items-center justify-between block">
        <div className="flex justify-end items-center mt-4">
          {isEditMode ? (
            <ButtonOutlined
              textButton={"+ Tambah Alamat"}
              style={{ marginRight: 5 }}
              onClick={onAddWarehouse}
            />
          ) : (
            <ButtonOutlined
              textButton={"Edit"}
              style={{ marginRight: 5 }}
              onClick={() => setIsEditMode(true)}
            />
          )}
          {isEditMode && <Button textButton={"Simpan"} onClick={onSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default WarehouseField;
