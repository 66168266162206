import React from "react";
import { Switch } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { RelianceLogic } from "./Reliance.logic";

const Reliance = () => {
  const { enabled, loading, onChangeToogle } = RelianceLogic();
  return (
    <div className='border border-gray-300 p-8 rounded my-10'>
      <div className='mb-3'>
        <div className='md:flex items-center justify-between block mb-3 border-b border-gray-300'>
          <p className='text-xl font-bold mb-3'>
            <FontAwesomeIcon icon={faClock} /> Reliance
          </p>
        </div>
        <div className='flex mb-2 items-center gap-2'>
          <span
            className={`${
              enabled ? "text-gray-300" : "text-black-300"
            } font-semibold mr-1`}>
            Disable
          </span>
          <Switch
            style={{ backgroundColor: enabled ? "#059669" : "white" }}
            checked={enabled}
            onChange={onChangeToogle}
            loading={loading}
          />
          <span
            className={`${
              !enabled ? "text-gray-300" : "text-black-300"
            } font-semibold ml-1`}>
            Enable
          </span>
        </div>
      </div>
    </div>
  );
};

export default Reliance;
