import { faCaretUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const data = [
  {
    id: 1,
    name: "Thio",
    email: "thio@gmail.com",
    phone: "0812-1231-3866",
  },
  {
    id: 2,
    name: "Mike",
    email: "thio@gmail.com",
    phone: "0812-1231-3866",
  },
  {
    id: 3,
    name: "Say",
    email: "thio@gmail.com",
    phone: "0812-1231-3866",
  },
];

export const UserListLogic = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);

  const columns = [
    {
      dataField: "name",
      text: "Full Name",
      sort: true,
      sortCaret: (order, column) => {
        return <FontAwesomeIcon icon={faSort} className='ml-2' />;
      },
    },
    {
      dataField: "phone",
      text: "handphone Number",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      headerClasses: "disabled-sorting text-right",
      classes: "text-right",
      formatter: (cell, row) => <div></div>,
    },
  ];

  const onTableChange = () => {};

  const onPageChange = () => {};

  const onLimitChange = () => {};

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
  };
};
