import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPoDetail } from "../../../services/po.services";
import { poDetailAtom } from "../../../store/po.store";

export const PoDetailLogic = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [poDetail, setPoDetail] = useAtom(poDetailAtom);

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await getPoDetail(params?.id);
      setPoDetail(res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onDownloadPdf = (params) => {
    if (params) {
      window.open(`${params}`, "_blank");
    }
  };

  return {
    isEdit,
    poDetail,
    setIsEdit,
    navigate,
    onDownloadPdf,
    setPoDetail,
    isLoading,
  };
};
