import { Cookies } from "react-cookie";
import JwtAuthentication from "./jwtAuthentication";

const cookies = new Cookies();

const setToken = (idToken) => {
  cookies.set("@id_token", idToken, { path: "/" });
};

const getToken = () => cookies.get("@id_token");

const removeToken = () => {
  cookies.remove("@id_token", { path: "/" });
};

const isAuthenticated = async () => {
  // Checks if there is a saved token and it's still valid
  try {
    const token = await getToken();

    const localToken = JwtAuthentication.checkExpirity(token);

    return token && !!localToken.token; // handwaiving here
  } catch (error) {}
};

export { setToken, getToken, removeToken, isAuthenticated };
