import { authServices } from "@utils/setupApi";

export const getListSalesReports = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/transaction`,
    parameter,
  });
};

export const salesReportCsv = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/export/sales-report`,
    parameter,
  });
};

export const updatePartialReject = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/partial-reject`,
    body,
  });
};

export const updateSellerStatus = (transactionCode, body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v1/transaction/seller/status/${transactionCode}`,
    body,
  });
};

export const createPOSales = (data) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/order/generate?code=${data}`,
  });
};

export const createHandoverList = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/order/generate/handover`,
    body,
  });
};

export const getDetailSalesReport = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/transaction/detail`,
    parameter,
  });
};

export const getDetailTransaction = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/transaction/${id}`,
  });
};

export const updateStatusTransaction = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/transaction/status`,
    body,
  });
};

export const verifyPayment = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/transaction/verify`,
    body,
  });
};

export const getListBankVa = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/transaction_va/list_bank`,
  });
};
