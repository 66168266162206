// eslint-disable-next-line
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { getListCampaignRegistration } from "@services/masterRequest.services";
import { mapStatusReview } from "@utils/conditional";

export const CampaignRegistrationListLogic = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit) => {
    setLoading(true);
    const params = {
      limit,
      page,
    };
    try {
      const res = await getListCampaignRegistration(params);
      isEmpty(res.campaignEventUsers)
        ? setData([])
        : setData(res.campaignEventUsers);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "campaignName",
      text: "Nama",
    },
    {
      dataField: "created",
      text: "Tanggal",
      headerAlign: "center",
      formatter: (cell, row) => (
        <div className='text-center'>
          {moment(cell).format("DD MMMM YYYY HH:mm")}
        </div>
      ),
    },
    {
      dataField: "id",
      text: "File CSV",
      formatter: (cell, row) =>
        row?.csvRequestUrl ? (
          <p
            onClick={() => window.open(`${row?.csvRequestUrl}`)}
            className='text-blue-500 cursor-pointer'>
            Download
          </p>
        ) : (
          "-"
        ),
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      formatter: (cell, row) => (
        <div
          className={`${mapStatusReview(
            row?.status
          )} p-2 flex items-center justify-center rounded`}>
          <p className='text-black'>{row?.status}</p>
        </div>
      ),
    },
    {
      dataField: "notes",
      text: "Catatan",
      headerAlign: "center",
      formatter: (cell, row) => (
        <div className='text-center'>{cell || "-"}</div>
      ),
    },
    {
      dataField: "id",
      text: "Feedback CSV",
      formatter: (cell, row) =>
        row?.feedbackFileUrl ? (
          <p
            onClick={() => window.open(`${row?.feedbackFileUrl}`)}
            className='text-blue-500 cursor-pointer'>
            Download
          </p>
        ) : (
          "-"
        ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
  };
};
