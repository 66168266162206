import React, { useState } from "react";
import { useAtom } from "jotai";
import { attributeFunc } from "@utils/manipulate";
import { formatPriceIdr } from "@utils/format";
import { updateDetailSku, getDetailSku } from "@services/sku.services";
import { skuDetailAtom } from "@store/sku.store";
import { message } from "antd";

export const SkuTableDetailLogic = (data) => {
  const [editMode, setEditMode] = useState(false);
  const [, setSkuDetail] = useAtom(skuDetailAtom);
  const [codePartnerTxt, setCodePartnerTxt] = useState("");
  const [success, setSuccess] = useState(false);

  const updateData = async () => {
    const dataUpdate = {
      sku: data.code,
      sku_partner: codePartnerTxt,
    };
    try {
      const res = await updateDetailSku(dataUpdate);
      if (res.status) {
        message.success(res.message);
        await getDataDetail(data.id);
        setSuccess(true);
      } else {
        message.error(res.message);
      }
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const getDataDetail = async () => {
    try {
      const res = await getDetailSku({ id: data.id });
      setSkuDetail(res);
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "code",
      text: "SKU",
      formatter: (cell) => cell || "-",
      headerStyle: () => {
        return { width: "15%" };
      },
      editable: false,
    },
    {
      dataField: "codePartner",
      text: "Seller SKU",
      formatter: (cell) =>
        codePartnerTxt.length ? codePartnerTxt : cell || "-",
      headerStyle: () => {
        return { width: "15%" };
      },
      style: {
        backgroundColor: editMode ? "#dbfcd4" : "none",
      },
      classes: () => {
        return editMode ? "shadow-lg shadow-inner shadow-green-500/50" : "";
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      formatter: (cell) => cell || "-",
      headerStyle: () => {
        return { width: "5%" };
      },
      editable: false,
    },
    {
      dataField: "manufacture",
      text: "Manufacture",
      formatter: (cell) => cell,
      headerStyle: () => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "ukuran",
      text: "Ukuran",
      formatter: (cell, row) => row.attribute && attributeFunc(row, "ukuran"),
      editable: false,
    },
    {
      dataField: "sae",
      text: "SAE",
      formatter: (cell, row) => row.attribute && attributeFunc(row, "sae"),
      editable: false,
    },
    {
      dataField: "mesin",
      text: "Mesin",
      formatter: (cell, row) => row.attribute && attributeFunc(row, "mesin"),
      editable: false,
    },
    {
      dataField: "merk",
      text: "Merk",
      formatter: (cell, row) => row.attribute && attributeFunc(row, "merk"),
      editable: false,
    },
    {
      dataField: "tipe",
      text: "Tipe",
      formatter: (cell, row) => row.attribute && attributeFunc(row, "tipe"),
      editable: false,
    },
    {
      dataField: "quantity",
      text: "Qty",
      formatter: (cell) => cell,
      editable: false,
    },
    {
      dataField: "price",
      text: "Harga Unit",
      formatter: (cell) => (cell ? <p>{formatPriceIdr(cell)}</p> : "-"),
      editable: false,
    },
  ];

  const onEdit = () => {
    setEditMode(!editMode);
    setCodePartnerTxt("");
    setSuccess(false);
  };

  const onTableChange = (e) => {
    console.log(e);
  };

  return {
    columns,
    onEdit,
    editMode,
    updateData,
    codePartnerTxt,
    setCodePartnerTxt,
    onTableChange,
    success,
  };
};
