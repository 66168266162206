import { authServices } from "@utils/setupApi";

export const getListPo = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/order`,
    parameter,
  });
};

export const getPoDetail = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/order/${id}`,
  });
};

export const updatePo = (id, body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v1/order/${id}`,
    body,
  });
};

export const uploadPo = (id, body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/upload/${id}`,
    body,
  });
};

export const getGeneratePo = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/order/generate`,
    parameter,
  });
};
