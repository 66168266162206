import { useAtom } from "jotai";
import { isNil } from "lodash";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { isAuthAtom } from "@store/auth.store";
import { isAuthenticated } from "@utils/token";

const App = () => {
  const location = useLocation();

  const [isAuth, setIsAuth] = useAtom(isAuthAtom);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const isLoggedIn = await isAuthenticated();
    if (isNil(isLoggedIn)) {
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  };

  if (isAuth) {
    if (location.pathname === "/") {
      return <Navigate to="/sales-report" replace={true} />;
    }
  } else {
    return <Navigate to="/login" replace={true} />;
  }
};

export default App;
