import { message } from "antd";
import { debounce, isEmpty } from "lodash";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getListWorkshops } from "@services/user.services";
import {
  convertDataToSelect,
  convertSubmitWhitelist,
} from "@utils/convertData";
import { createWhitelists } from "@services/masterRequest.services";

export const PaylaterSettingsCreateLogic = () => {
  const navigate = useNavigate();

  const [whitelists, setWhitelists] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    const body = {
      userCredits: convertSubmitWhitelist(whitelists),
    };
    try {
      await createWhitelists(body);
      await onBack();
      await message.success("Berhasil membuat whitelist");
    } catch (error) {
      await message.error(`${error.data.message}`);
    }
  };

  const onAdd = () => {
    const data = {};
    setWhitelists([...whitelists, data]);
  };

  const onChange = (e, index, params) => {
    const data = whitelists.map((whitelist, id) =>
      id === index
        ? {
            ...whitelist,
            [params]: e,
          }
        : whitelist
    );
    setWhitelists(data);
  };

  const onChangeCreditLimit = (e, index, params) => {
    const data = whitelists.map((whitelist, id) =>
      id === index
        ? {
            ...whitelist,
            [params]: e.target.value,
          }
        : whitelist
    );
    setWhitelists(data);
  };

  const onRemove = (index) => {
    if (whitelists.length > 1) {
      const data = whitelists.filter((_, idx) => idx !== index);
      setWhitelists(data);
    }
  };

  const getAsyncOptions = async (workshop_id) => {
    const params = {
      page: 1,
      workshop_id,
    };
    try {
      const res = await getListWorkshops(params);
      return isEmpty(res) ? [] : convertDataToSelect(res.users, "workshopId");
    } catch (error) {
      console.log(error);
    }
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      if (inputText.length > 0)
        getAsyncOptions(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const onBack = () => navigate("/master/paylater-settings");

  return {
    whitelists,
    onAdd,
    onRemove,
    onChange,
    onSubmit,
    onBack,
    onChangeCreditLimit,
    isLoading,
    loadOptions,
  };
};
