import { useAtom } from "jotai";
import React, { useEffect } from "react";

import { isEditProfileAtom } from "@store/user.store";
import ProfileEdit from "./profileEdit/ProfileEdit";
import ProfileDetail from "./profileDetail/ProfileDetail";
import { getListTermsOfPayment } from "@services/dictionaries.services";
import { getPickupLocation } from "@services/settings.services";
import { listsTermOfPaymentAtom, pickupLoactionsAtom } from "@store/user.store";
import { convertTermOfPaymentToSelect } from "../../utils/convertData";

const Profile = () => {
  const [isEditProfile] = useAtom(isEditProfileAtom);
  const [, setListsTermOfPayment] = useAtom(listsTermOfPaymentAtom);
  const [, setPickupLocations] = useAtom(pickupLoactionsAtom);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getListTermsOfPayment();
      const resLocation = await getPickupLocation();
      await setListsTermOfPayment(convertTermOfPaymentToSelect(res?.entries));
      await setPickupLocations(resLocation.locations || []);
    } catch (error) {
      console.log(error);
    }
  };

  if (isEditProfile) {
    return <ProfileEdit />;
  } else {
    return <ProfileDetail />;
  }
};

export default Profile;
