import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@components/atom/Button";
import imageIcon from "@assets/image.png";

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#ccc",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const acceptImageAndPdf = {
  "image/*": [".jpeg", ".png", ".pdf", ".zip", ".rar"],
};

const Dropzone = ({ files, setFiles, accept }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <img src={imageIcon} alt="icon" className="w-10 h-10" />
        {isEmpty(files) ? null : (
          <p style={{ color: "#2196f3", marginTop: 8 }}>{files[0].name}</p>
        )}
        <div className=" mt-3">
          <div className="button-basic">Telusuri File</div>
        </div>
        <p style={{ marginTop: 20 }}>atau tarik file kesini</p>
      </div>
    </div>
  );
};

export default Dropzone;
