import {
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faImage,
  faVideoCamera,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const FileIcon = (type) => {
  switch (type) {
    case "video":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faFileVideo}
          className='text-6xl cursor-pointer'
        />
      );
    case "pdf":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faFilePdf}
          className='text-6xl cursor-pointer text-red-500'
        />
      );
    case "word":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faFileWord}
          className='text-6xl cursor-pointer text-blue-500'
        />
      );
    case "excel":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faFileExcel}
          className='text-6xl cursor-pointer text-green-500'
        />
      );
    case "image":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faImage}
          className='text-6xl cursor-pointer'
        />
      );
    case "ppt":
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faFilePowerpoint}
          className='text-6xl cursor-pointer text-orange-500'
        />
      );
    default:
      return (
        <FontAwesomeIcon
          size='lg'
          icon={faVideoCamera}
          className='text-6xl cursor-pointer text-red-500'
        />
      );
  }
};

export default FileIcon;
