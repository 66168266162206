import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import { loginUser } from "@services/auth.services";
import { setToken } from "@utils/token";
import { isAuthAtom } from "@store/auth.store";
import { userProfileAtom } from "@store/user.store";
import { message } from "antd";

export const LoginLogic = () => {
  const navigate = useNavigate();

  const [isAuth, setIsAuth] = useAtom(isAuthAtom);

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await loginUser(data);
      await setToken(res.token);
      await setIsAuth(true);
      await navigate("/");
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    setValue,
    errors,
    isLoading,
    register,
    isAuth,
  };
};
