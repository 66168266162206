// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Switch, message } from "antd";

import { useDebounce } from "@utils/delay";
import { getListWhitelists } from "@services/masterRequest.services";
import { formatPriceIdr } from "@utils/format";
import { updateToggleWhitelist } from "@services/masterRequest.services";

export const PaylaterSettingsListLogic = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit, filterBy, text) => {
    setLoading(true);
    const params = {
      limit,
      page,
      [filterBy]: text,
    };
    try {
      const res = await getListWhitelists(params);
      isEmpty(res.userCredits) ? setData([]) : setData(res.userCredits);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "workshopName",
      text: "Nama Bengkel",
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "workshopId",
      text: "Kode Bengkel",
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "provider",
      text: "Provider",
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "credit",
      text: "Credit Limit",
      formatter: (cell, row) => <p>{formatPriceIdr(cell)}</p>,
    },
    {
      dataField: "maxCreditLimit",
      text: "Max Credit Limit",
      formatter: (cell, row) => <p>{formatPriceIdr(cell)}</p>,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: (cell, row) => (
        <Switch checked={cell} onChange={() => onChangeStatus(row)} />
      ),
    },
    {
      dataField: "id",
      text: "Action",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => (
        <div>
          <FontAwesomeIcon
            icon={faPencil}
            className='text-green-900 cursor-pointer mr-3'
            onClick={() => {
              navigate(`/master/paylater-settings/edit/${row.id}`);
            }}
          />
        </div>
      ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  const onChangeStatus = async (row) => {
    const res = data.map((whitelist) =>
      whitelist.id === row.id ? { ...row, active: !row.active } : whitelist
    );
    const body = {
      userId: row.userId,
      provider: row.provider,
      workshopId: row.workshopId,
      active: !row.active,
    };

    try {
      await updateToggleWhitelist(body);
      await setData(res);
      await message.success("Berhasil update status");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = useDebounce(async (filterBy, e) => {
    getData(page, limit, filterBy, e.target.value);
  }, 1000);

  const onNavigate = () => {
    navigate("/master/paylater-settings/create");
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    handleSearch,
    onNavigate,
  };
};
