// eslint-disable-next-line
import { useAtom } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { isEmpty, isNil } from "lodash";

import { stockListAtom } from "@store/stock.store";
import { useDebounce } from "@utils/delay";
import { getListLoanTransaction } from "@services/loanTransaction.services";
import { formatPriceIdr } from "@utils/format";
import { transactionStatusColor } from "@utils/conditional";
import { generalTransactionDate } from "@utils/formatDate";

export const LoanTransactionListLogic = () => {
  const [data, setData] = useAtom(stockListAtom);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoiceRefNum, setInvoiceRefNum] = useState(null);
  const [workshopId, setWorkshopId] = useState(null);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (
    page,
    limit,
    startDate,
    endDate,
    invoiceRefNum,
    workshopId
  ) => {
    setLoading(true);
    const params = {
      limit,
      page,
      start_date: startDate,
      end_date: endDate,
      invoice_ref_num: invoiceRefNum,
      workshop_id: workshopId,
    };

    try {
      const res = await getListLoanTransaction(params);
      isEmpty(res.listTransaction) ? setData([]) : setData(res.listTransaction);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setTotalSize(parseInt(res?.pagination.LastPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "code",
      text: "Nomor Transaksi",
      formatter: (cell, row) => (
        <NavLink
          to={`/sales-report/${row.id}`}
          className='cursor-pointer underline text-black'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "createdAt",
      text: "Tanggal Transaksi",
      formatter: (cell, row) => (
        <div>{moment(cell).format("DD MMMM YYYY HH:mm") || "-"}</div>
      ),
    },
    {
      dataField: "customerName",
      text: "Nama Customer",
      formatter: (cell, row) => (
        <NavLink
          style={{ color: "#000" }}
          to={`/customer/${row.customerId}`}
          className='cursor-pointer underline hover:underline '
          activeclassname='cursor-pointer underline'>
          {cell}
        </NavLink>
      ),
    },
    {
      dataField: "workshopId",
      text: "Kode Bengkel",
    },
    {
      dataField: "total",
      text: "Harga (Rp)",
      formatter: (cell, row) => <div>{formatPriceIdr(cell)}</div>,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) =>
        isNil(cell) ? (
          <div>-</div>
        ) : (
          <div
            className={`p-2 flex items-center justify-center rounded text-white`}
            style={{ background: transactionStatusColor(cell) }}>
            {cell}
          </div>
        ),
    },
    {
      dataField: "locale",
      text: "Locale",
    },
    {
      dataField: "loan.startDate",
      text: "Loan Start Date",
      formatter: (cell, row) => (
        <div>{generalTransactionDate(row?.loan?.startDate)}</div>
      ),
    },
    {
      dataField: "loan.endDate",
      text: "Loan End Date",
      formatter: (cell, row) => (
        <div>{generalTransactionDate(row?.loan?.endDate)}</div>
      ),
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
    },
    {
      dataField: "paymentDate",
      text: "Payment Date",
      formatter: (cell, row) => (
        <div>{generalTransactionDate(row?.paymentDate)}</div>
      ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit, startDate, endDate, invoiceRefNum, workshopId);
  };

  const onLimitChange = (e) => {
    getData(1, e, startDate, endDate, invoiceRefNum, workshopId);
    setLimit(e);
  };

  const handleSearchTransaction = useDebounce(async (e) => {
    getData(1, limit, startDate, endDate, e.target.value, workshopId);
    setInvoiceRefNum(e.target.value);
  }, 1000);

  const handleSearchWorkshopId = useDebounce(async (e) => {
    getData(1, limit, startDate, endDate, invoiceRefNum, e.target.value);
    setWorkshopId(e.target.value);
  }, 1000);

  const onFilterTransactions = (date) => {
    const tempStartDate = isNil(date)
      ? null
      : `${moment(date[0]).format("YYYY-MM-DD")}`;
    const tempEndDate = isNil(date)
      ? null
      : `${moment(date[1]).format("YYYY-MM-DD")}`;

    getData(1, limit, tempStartDate, tempEndDate, invoiceRefNum, workshopId);
  };

  return {
    data,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onFilterTransactions,
    handleSearchTransaction,
    handleSearchWorkshopId,
  };
};
