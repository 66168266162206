import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useAtom } from "jotai";

import { userProfileAtom, isEditProfileAtom } from "@store/user.store";
import { updateUser } from "@services/user.services";
import { getUserProfile } from "@services/auth.services";
import { listsTermOfPaymentAtom } from "@store/user.store";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export const ProfileEditLogic = () => {
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  const [, setIsEditProfile] = useAtom(isEditProfileAtom);
  const [listsTermOfPayment] = useAtom(listsTermOfPaymentAtom);

  const phoneConvert = (data) =>
    data && data.slice(0, 2) === "62" ? data.substring(2) : data;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    watch,
    getValues,
  } = useForm();

  useEffect(() => {
    setValue("name", userProfile?.name);
    setValue("email", userProfile?.email);
    setValue("phone", phoneConvert(userProfile?.phone));
    setValue("phone_wa", phoneConvert(userProfile?.phoneWa) || "");
    setValue("address", userProfile?.address);
    setValue("locale", userProfile?.locale);
    setValue("warehouseId", userProfile?.warehouseId);
    setValue("term_payment", userProfile?.termPayment);
    setValue("partnerType", userProfile?.partnerType || "-");
    setValue("deliveryMethod", userProfile?.deliveryMethod || "-");
    setValue("cutoffTime", userProfile?.cutoffTime || "-");
    setValue("bank_account_name", userProfile?.bankAccountName || "-");
    setValue("bank_name", userProfile?.bankName || "-");
    setValue("bank_rek", userProfile?.bankRek || "-");
    setValue("leadPickUpTime", userProfile?.leadPickUpTime || "-");
    setValue("npwp", userProfile?.npwp || "-");
    setValue("password", "");
    setValue("notes", userProfile?.notes);
  }, []);

  const PasswordStatusConfirm = () => {
    return (watch("password_confirm") === undefined &&
      getValues("password_confirm") === undefined) ||
      getValues("password_confirm").length == 0 ? null : watch(
        "password_confirm"
      ) === watch("password") && getValues("password_confirm") ? (
      <FontAwesomeIcon
        icon={faCheck}
        color='#22c55e'
        className='pl-2 self-center'
      />
    ) : (
      <FontAwesomeIcon
        icon={faClose}
        color='#ef4444'
        className='pl-2 self-center'
      />
    );
  };

  const onSubmit = async (data) => {
    const { locale, warehouseId, notes, ...rest } = data;
    const body = {
      ...rest,
      phone: `62${data.phone}`,
      phone_wa: data.phone_wa.length ? `62${data.phone_wa}` : "",
      term_payment: data.term_payment,
      password: data.password,
      partner_bank_account: userProfile?.partnerBankAccount,
      pickup_locale: userProfile?.pickupLocale,
      purchased_customer_data: userProfile?.purchasedCustomerData,
      minimum_purchase: userProfile?.minimumPurchase,
      point_reward_system: userProfile?.pointRewardSystem,
      external_payment: userProfile?.externalPayment,
      notes,
    };

    try {
      await updateUser(body);
      const user = await getUserProfile();
      setUserProfile(user);
      await setIsEditProfile(false);
      await message.success("Success update profile");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    control,
    handleSubmit,
    errors,
    getValues,
    register,
    onSubmit,
    userProfile,
    setIsEditProfile,
    listsTermOfPayment,
    PasswordStatusConfirm,
  };
};
