import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getDetailMaterial } from "@services/trainingMaterial.services";

export const TrainingMaterialDetailLogic = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await getDetailMaterial(params.id);
      await setData(res);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    data,
    navigate,
  };
};
