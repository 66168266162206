import { isNil } from "lodash";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { SkuTableDetailLogic } from "./SkuTableDetail.logic";
import Button from "@components/atom/Button";

const SkuTableDetail = ({ data }) => {
  const {
    columns,
    onEdit,
    editMode,
    updateData,
    codePartnerTxt,
    setCodePartnerTxt,
    onTableChange,
    success,
  } = SkuTableDetailLogic(data);
  const btnTxt = editMode ? "Cancel" : "Edit";

  return (
    <div>
      <div className="flex w-full justify-between items-center mb-3">
        <p className="font-bold">Detail SKU</p>
        <div className="flex flex-row justify-self-end">
          <Button textButton={btnTxt} onClick={onEdit} />
          {/* {!success && editMode && ( */}
          {!success && (
            <Button
              textButton={"Save"}
              onClick={updateData}
              style={{ marginLeft: 10 }}
              disabled={!editMode}
            />
          )}
        </div>
      </div>

      <div>
        <BootstrapTable
          keyField="id"
          responsive
          remote
          data={isNil(data) ? [] : [data]}
          bordered={true}
          onTableChange={onTableChange}
          {...(editMode && {
            cellEdit: cellEditFactory({
              mode: "click",
              autoSelectText: true,
              blurToSave: true,
              onStartEdit: (row, column, rowIndex, columnIndex) => {},
              beforeSaveCell: (oldValue, newValue, row, column) => {
                setCodePartnerTxt(newValue);
              },
            }),
          })}
          columns={columns}
          hover
          noDataIndication={() => <div className="text-center">Empty Data</div>}
        />
      </div>
    </div>
  );
};

export default SkuTableDetail;
