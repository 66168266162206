import React from "react";
import UserList from "./userList/UserList";

const User = () => {
  return (
    <div>
      <p className='font-bold text-xl pb-10'>User</p>
      <div>
        <UserList />
      </div>
    </div>
  );
};

export default User;
