import React from "react";
import SkuProductList from "./skuProductList/SkuProductList";

const MasterProduct = () => {
  return (
    <div>
      <SkuProductList />
    </div>
  );
};

export default MasterProduct;
