import { authServices } from "@utils/setupApi";

export const getAnalytics = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/analytics`,
    parameter,
  });
};

export const getListNotifications = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/notifications`,
    parameter,
  });
};

export const readAllNotification = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v1/notifications`,
    body,
  });
};
