import React from "react";
import LoanTransactionList from "./loanTransactionList/LoanTransactionList";

const LoanTransaction = () => {
  return (
    <div>
      <LoanTransactionList />
    </div>
  );
};

export default LoanTransaction;
