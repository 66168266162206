import React, { useState } from "react";
import { Modal } from "antd";
import FileSaver from "file-saver";

import Button from "@components/atom/Button";
import Dropzone from "@components/molekul/Dropzone";
import { getBase64 } from "@utils/manipulate";
import { Select } from "antd";

const UploadCSV = ({ onUpload, title, file, fileName, textButton }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [files, setFiles] = useState(null);
  const [inputValue, setValue] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const res = await getBase64(files[0]);
    title === "Upload Stock Akhir" ? onUpload(res, inputValue) : onUpload(res);
    setFiles(null);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const onDownloadTemplate = () => {
    FileSaver.saveAs(file, fileName);
  };

  return (
    <div>
      <Button textButton={textButton} onClick={showModal} />
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Upload'
        destroyOnClose={true}
        footer={
          <div className='flex items-center justify-between'>
            <div>
              <div
                className='bg-green-900 p-2 text-white cursor-pointer rounded'
                onClick={onDownloadTemplate}>
                Download Template
              </div>
            </div>
            <div className='flex items-center'>
              <div
                className='border border-gray-400 p-2 mr-2 cursor-pointer rounded'
                onClick={handleCancel}>
                Cancel
              </div>
              <div
                className='bg-green-900 p-2 text-white  cursor-pointer rounded'
                onClick={handleOk}>
                Upload
              </div>
            </div>
          </div>
        }>
        <div>
          <p className='mb-3'>*Upload file dalam bentuk CSV</p>
          <Dropzone files={files} setFiles={setFiles} />
          {title === "Upload Stock Akhir" ? (
            <div className='md:container md:mx-auto items-center my-3'>
              <label htmlFor='select' className='m-0 mb-2'>
                *Pilih Type
              </label>
              <Select
                style={{ width: "100%" }}
                onChange={handleInputChange}
                options={[
                  { value: "bp", label: "SKU Belanjaparts" },
                  { value: "seller", label: "SKU Seller" },
                ]}
                placeholder='Pilih tipe SKU...'
              />
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default UploadCSV;
