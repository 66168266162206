import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AsyncSelect from "react-select/async";

import Button from "@components/atom/Button";
import { StockUpdateCreateLogic } from "./StockUpdateCreate.logic";

const StockUpdateCreate = () => {
  const {
    loadOptions,
    handleInputChange,
    handleChange,
    addSecondaryProduct,
    onHandleStock,
    handleSubmit,
    onRemove,
    stocks,
    navigate,
    onChangeSwitch,
    enabled,
  } = StockUpdateCreateLogic();

  return (
    <div>
      <div className="flex items-center justify-between mb-10">
        <div className="flex">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-2xl mr-4 cursor-pointer "
            onClick={() => navigate(-1)}
          />
          <p className="font-bold text-xl">Update Stock</p>
        </div>
      </div>
      <div className="flex mb-2 items-center gap-2">
        <label
          for="Toggle1"
          className="inline-flex items-center space-x-4 cursor-pointer"
        >
          <span
            className={`${
              enabled ? "text-gray-300" : "text-black-300"
            } font-semibold`}
          >
            SKU Belanjaparts
          </span>
          <span className="relative">
            <input
              id="Toggle1"
              type="checkbox"
              className="hidden peer"
              value={enabled}
              onClick={onChangeSwitch}
              disabled={stocks.length > 1}
            />
            <div className="w-10 h-6 rounded-full shadow-inner bg-gray-200"></div>
            <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto dark:bg-gray-800"></div>
          </span>
          <span
            className={`${
              !enabled ? "text-gray-300" : "text-black-300"
            } font-semibold`}
          >
            {" "}
            SKU Seller
          </span>
        </label>
      </div>
      <table
        className="w-full"
        style={{ padding: 20, boxSizing: "border-box", height: 60 }}
      >
        <thead>
          <tr>
            <th className="p-3">SKU</th>
            <th className="p-3">Qty Masuk</th>
            <th className="p-3">Qty Keluar</th>
            <th className="p-3">Deskripsi</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((product, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "25%" }} className="p-3">
                  <AsyncSelect
                    isClearable
                    // cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    onInputChange={handleInputChange}
                    onChange={(e) => handleChange(e, index, enabled)}
                    menuPortalTarget={document.querySelector("body")}
                    noOptionsMessage={() => "Typing sku"}
                    value={product.sku}
                  />
                </td>
                <td style={{ width: "25%" }} className="p-3">
                  <input
                    type="text"
                    className="border border-gray-300 w-full rounded py-2 px-3"
                    value={product.stock_in}
                    onChange={(e) => onHandleStock(e, index, "stock_in")}
                  />
                </td>
                <td style={{ width: "25%" }} className="p-3">
                  <input
                    type="text"
                    className="border border-gray-300 w-full rounded py-2 px-3"
                    value={product.stock_out}
                    onChange={(e) => onHandleStock(e, index, "stock_out")}
                  />
                </td>
                <td className="p-3">
                  <input
                    type="text"
                    className="border border-gray-300 w-full rounded py-2 px-3"
                    value={product.description}
                    onChange={(e) => onHandleStock(e, index, "description")}
                  />
                </td>
                <td className="p-3">
                  <p className="cursor-pointer" onClick={() => onRemove(index)}>
                    x
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="mt-5 mb-3">
        <div
          className="border-2 border-green-500 hover:border-green-700 text-green-500 hover:text-green-700 border-dashed rounded p-2 cursor-pointer"
          onClick={addSecondaryProduct}
        >
          <p className="text-sm  text-center">Tambah SKU Lainnya +</p>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button textButton="Simpan" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default StockUpdateCreate;
