import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getDetailSku } from "@services/sku.services";
import { skuDetailAtom } from "@store/sku.store";

export const SkuProductDetailLogic = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [skuDetail, setSkuDetail] = useAtom(skuDetailAtom);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const parameter = {
      id: params?.id,
    };

    try {
      const res = await getDetailSku(parameter);
      setSkuDetail(res);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    navigate,
    skuDetail,
  };
};
