import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";
import { DatePicker } from "antd";

import SearchLimit from "@components/molekul/SearchLimit";
import { LoanTransactionListLogic } from "./LoanTransactionList.logic";

import { Input } from "antd";

import "antd/es/input/style/css";

const { Search } = Input;
const { RangePicker } = DatePicker;
LoadingOverlay.propTypes = undefined;

const LoanTransactionList = () => {
  const {
    data,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onFilterTransactions,
    handleSearchTransaction,
    handleSearchWorkshopId,
  } = LoanTransactionListLogic();

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}>
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className='flex items-center justify-between pb-3 pt-3 md:pt-0'>
              <div className='md:w-1/3'>
                <p className='font-bold text-xl pb-10'>Transaksi Peminjaman</p>
                <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              </div>
              <div className='w-2/3 pt-4'>
                <div className='flex justify-end mb-3'>
                  <div className='w-full'>
                    <div className='mb-2 flex'>
                      <div className='w-32 border border-gray-300 bg-white flex items-center justify-center text-xs'>
                        Tanggal Transaksi
                      </div>
                      <RangePicker
                        placeholder={["Tanggal Mulai", "Tanggal Akhir"]}
                        onChange={onFilterTransactions}
                      />
                    </div>
                    <div className='flex'></div>
                  </div>
                  <div className='w-full ml-2'>
                    <div className='mb-2 flex'>
                      <div className='w-32 border border-gray-300 bg-white flex items-center justify-center text-xs'>
                        No. Transaksi
                      </div>
                      <Search
                        type='text'
                        placeholder={"Cari"}
                        onChange={handleSearchTransaction}
                      />
                    </div>
                    <div className='mb-2 flex'>
                      <div className='w-32 border border-gray-300 bg-white flex items-center justify-center text-xs'>
                        Kode Bengkel
                      </div>
                      <Search
                        type='text'
                        placeholder={"Cari"}
                        onChange={handleSearchWorkshopId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='table-striped table-responsive'>
              <BootstrapTable
                keyField='id'
                remote
                data={data}
                bordered={false}
                loading={loading}
                columns={columns}
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className='text-center'>Empty Data</div>
                )}
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(192,192,192,0.3)",
                })}
                {...paginationTableProps}
              />
            </div>
            <div className='flex items-center justify-between'>
              <div>
                <p className='text-muted'>
                  Menunjukan{" "}
                  <span className='font-weight-bold text-dark'>
                    {`${data.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {data.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    </div>
  );
};

export default LoanTransactionList;
