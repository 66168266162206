import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash";
import moment from "moment";
import React from "react";

import Accordion from "../components/Accordion";
import { TrainingMaterialListLogic } from "./TrainingMaterialList.logic";

const TrainingMaterialList = () => {
  const { navigate, topics, setIsActive, partnerPopupPayload } =
    TrainingMaterialListLogic();

  return topics.map((topic, idx) => (
    <div key={idx}>
      <Accordion
        heading={`${idx + 1}  ${topic.title}`}
        topic={topic}
        idx={idx}
        setIsActive={setIsActive}>
        {isNil(topic.materials) ? null : (
          <div id={idx === 0 ? "welcome-subtopic" : ""}>
            {topic.materials.map((material, id) => (
              <div
                id={
                  material.id === partnerPopupPayload?.idSubTopic
                    ? "my-other-step"
                    : ""
                }
                className={
                  "rounded shadow-md bg-white flex items-center justify-between p-3 mb-4 cursor-pointer hover:shadow-xl"
                }
                onClick={() => navigate(`/training-material/${material.id}`)}
                key={id}>
                <p className='w-1/3'>{material.title}</p>
                <p>{moment(material.updated_at).format("DD MMMM YYYY")}</p>
                <FontAwesomeIcon icon={faChevronRight} className='mr-2' />
              </div>
            ))}
          </div>
        )}
      </Accordion>
    </div>
  ));
};

export default TrainingMaterialList;
