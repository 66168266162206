import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";

import SearchLimit from "@components/molekul/SearchLimit";
import { UserListLogic } from "./UserList.logic";
import SearchTable from "../components/SearchTable";
import Button from "@components/atom/Button";
import { useNavigate } from "react-router-dom";

LoadingOverlay.propTypes = undefined;

const UserList = () => {
  const navigate = useNavigate();

  const {
    data,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
  } = UserListLogic();

  return (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        alwaysShowAllBtns: true,
        prePageText: "Sebelumnya",
        nextPageText: "Selanjutnya",
        withFirstAndLast: false,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <>
          <div className="flex items-center justify-between pb-3">
            <SearchLimit limit={limit} onLimitChange={onLimitChange} />
            <div className="flex items-center">
              <SearchTable />
              <Button
                textButton="Create New User"
                onClick={() => navigate("/user/create")}
              />
            </div>
          </div>
          <div className="table-striped">
            <BootstrapTable
              keyField="id"
              data={data}
              bordered={false}
              loading={false}
              columns={columns}
              hover
              onTableChange={onTableChange}
              noDataIndication={() => (
                <div className="text-center">Empty Data</div>
              )}
              overlay={overlayFactory({
                spinner: true,
                background: "rgba(192,192,192,0.3)",
              })}
              {...paginationTableProps}
            />
          </div>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-muted">
                Menunjukan{" "}
                <span className="font-weight-bold text-dark">
                  {`${data.length}`}
                </span>{" "}
                entries
              </p>
            </div>
            <div>
              {data.length > 0 && (
                <PaginationListStandalone
                  {...paginationProps}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </>
      )}
    </PaginationProvider>
  );
};

export default UserList;
