import React from "react";
import { TimePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { isNil, has, isEmpty } from "lodash";
import { ReactMultiEmail } from "react-multi-email";
import { formatHour } from "@utils/formatDate";
import moment from "moment";
import "react-multi-email/dist/style.css";

import ButtonOutlined from "@components/atom/ButtonOutlined";
import Button from "@components/atom/Button";
import { StockReportLogic } from "./StockReport.logic";

const StockReport = () => {
  const {
    isDisplaySchedule,
    setisDisplaySchedule,
    onSubmitSchedule,
    onAddSchedule,
    onChangeTime,
    scheduleTime,
    onRemove,
    onChangeEmail,
    setFocused,
  } = StockReportLogic();
  const format = "HH:mm";
  return (
    <div className="border border-gray-300 p-8 rounded my-10">
      <div className="md:flex items-center justify-between block mb-3 border-b border-gray-300">
        <div className="mb-3">
          <p className="text-xl font-bold">
            <FontAwesomeIcon icon={faCalendar} /> Atur Laporan Stock
          </p>
          <p>
            Disini kamu bisa atur untuk mendapatkan laporan stock setiap hari
            pada jam yang kamu pilih
          </p>
        </div>
        <div>
          {!isDisplaySchedule ? (
            <ButtonOutlined
              textButton={"Atur Laporan Stock"}
              onClick={() => setisDisplaySchedule(true)}
            />
          ) : (
            <div className="flex justify-end items-center">
              <ButtonOutlined
                textButton={"Batal"}
                style={{ marginRight: 5 }}
                onClick={() => window.location.reload()}
              />
              <Button
                disabled={!scheduleTime.filter((x) => !has(x, "id")).length > 0}
                textButton={"Simpan"}
                onClick={onSubmitSchedule}
              />
            </div>
          )}
        </div>
      </div>
      {isDisplaySchedule ? (
        <>
          <div className="md:flex items-center justify-between block">
            <div className="mb-3 align-start w-11/12">
              <div className="flex w-full mb-2 gap-4 items-center">
                <p className="w-40"></p>
                <p className="w-32 font-bold">
                  {isEmpty(scheduleTime) ? "" : "Jam"}
                </p>
                <p className="w-96 font-bold">
                  {isEmpty(scheduleTime) ? "" : "Email"}
                </p>
              </div>
              {scheduleTime &&
                scheduleTime.map((item, idx) => (
                  <div
                    key={idx}
                    className="flex w-full mb-2 gap-4 items-center"
                  >
                    <p className="w-40 font-bold">Setiap Hari</p>
                    <TimePicker
                      className="w-32"
                      disabled={has(item, "id")}
                      size="large"
                      defaultValue={moment(item.time, format)}
                      format={format}
                      onChange={(tm, tms) => onChangeTime(tm, tms, idx)}
                    />
                    <ReactMultiEmail
                      placeholder="Input your email"
                      className="w-96"
                      emails={item.email}
                      enable={has(item, "id")}
                      onChange={(e) => {
                        onChangeEmail(e, idx);
                      }}
                      autoFocus={true}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              X
                            </span>
                          </div>
                        );
                      }}
                    />
                    <p
                      className="cursor-pointer"
                      onClick={() => onRemove(idx, item)}
                    >
                      X
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="sm:flex items-center justify-between block">
            <div className="flex justify-end items-center mt-4">
              <ButtonOutlined
                textButton={"+ Tambah Jam"}
                onClick={onAddSchedule}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="md:flex items-center justify-between block">
          <div className="mb-3 md:mb-0">
            <div className="flex mb-2 gap-4 ">
              <p className="w-32 font-bold"></p>
              <p className="w-48 font-bold">Jam</p>
              <p className="w-40 font-bold">Email</p>
            </div>
            {isEmpty(scheduleTime) ? (
              <div className="flex mb-2 gap-4 items-start">
                <p className="w-32 font-bold">Setiap Hari</p>
                <p className="w-48">-</p>
                <p className="w-80">-</p>
              </div>
            ) : (
              scheduleTime.map((item, idx) => (
                <div key={idx} className="flex mb-2 gap-4 items-start">
                  <p className="w-32 font-bold">Setiap Hari</p>
                  <p className="w-48">
                    {isNil(item.email) ? "-" : formatHour(item.time)}
                  </p>
                  <div>
                    {isNil(item.email) ? (
                      <p className="w-80">-</p>
                    ) : (
                      item &&
                      item?.email.map((mail, idx) => (
                        <p key={idx} className="w-80">
                          {mail}
                        </p>
                      ))
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StockReport;
