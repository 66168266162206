import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AsyncSelect from "react-select/async";

import { PaylaterSettingsCreateLogic } from "./PaylaterSettingsCreate.logic";
import Button from "@components/atom/Button";

const PaylaterSettingsCreate = (props) => {
  const {
    whitelists,
    onAdd,
    onRemove,
    onChange,
    onSubmit,
    onBack,
    onChangeCreditLimit,
    isLoading,
    loadOptions,
  } = PaylaterSettingsCreateLogic(props);

  return (
    <div>
      <div className=' flex w-1/2'>
        <p
          className='font-bold text-xl py-10 pl-3 cursor-pointer'
          onClick={onBack}>
          <FontAwesomeIcon icon={faArrowLeft} className='mr-2 font-bold' />
          Buat Whitelist
        </p>
      </div>
      <table>
        <thead>
          <tr>
            <th className='pl-3 w-1/2'>Kode Bengkel</th>
            <th className='pl-3 w-1/2'>Credit Limit (Rp)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {whitelists.map((whitelist, index) => {
            return (
              <tr key={index}>
                <td className='p-3 w-1/2'>
                  <AsyncSelect
                    isClearable
                    cacheOptions
                    defaultOptions
                    placeholder='Tulis Kode Bengkel'
                    loadOptions={loadOptions}
                    onChange={(e) => onChange(e, index, "workshop_id")}
                    menuPortalTarget={document.querySelector("body")}
                    noOptionsMessage={() => "Tulis Kode Bengkel"}
                    value={whitelist.workshop_id}
                  />
                </td>
                <td className='p-3 w-1/2'>
                  <input
                    type='text'
                    className='form-control'
                    style={{ minHeight: 38 }}
                    value={whitelist.credit}
                    onChange={(e) => onChangeCreditLimit(e, index, "credit")}
                  />
                </td>
                <td className='p-3'>
                  <p className='cursor-pointer' onClick={() => onRemove(index)}>
                    X
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='p-3'>
        <div className='p-2 border-dashed border-2 border-green-700 cursor-pointer'>
          <p className='text-green-700 text-center' onClick={onAdd}>
            Tambah +
          </p>
        </div>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <Button
            textButton={isLoading ? "Loading..." : "Submit"}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PaylaterSettingsCreate;
