// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Switch, message } from "antd";

import { useDebounce } from "@utils/delay";
import { getListPaymentOptions } from "@services/masterRequest.services";
import {
  deletePaymentOption,
  updateTogglePaymentOption,
} from "@services/masterRequest.services";

export const PaymentOptionsListLogic = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [isDisplayCreateModal, setIsDisplayCreateModal] = useState(false);
  const [isDisplayEditModal, setIsDisplayEditModal] = useState(false);
  const [row, setRow] = useState(null);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit, filterBy, text) => {
    setLoading(true);
    const params = {
      limit,
      page,
      [filterBy]: text,
    };
    try {
      const res = await getListPaymentOptions(params);
      isEmpty(res.paymentOptions) ? setData([]) : setData(res.paymentOptions);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Nama",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "details.duration.number",
      text: "Durasi (Hari)",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "details.adminFee.number",
      text: "Admin Fee",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      formatter: (cell, row) => (
        <Switch checked={row.enabled} onChange={() => onChangeStatus(row)} />
      ),
    },
    {
      dataField: "id",
      text: "Action",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => (
        <div>
          <FontAwesomeIcon
            icon={faPencil}
            className='text-green-900 cursor-pointer mr-3'
            onClick={() => {
              setIsDisplayEditModal(true);
              setRow(row);
            }}
          />
          <FontAwesomeIcon
            icon={faTrash}
            className='text-green-900 cursor-pointer'
            onClick={() => onDelete(row.id)}
          />
        </div>
      ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  const onChangeStatus = async (row) => {
    const res = data.map((paymentOption) =>
      paymentOption.id === row.id
        ? { ...row, enabled: !row.enabled }
        : paymentOption
    );
    try {
      await updateTogglePaymentOption(row.id);
      await setData(res);
      await message.success("Berhasil update status");
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (id) => {
    await Modal.confirm({
      title: "Konfirmasi",
      content: `Apakah anda yakin akan menghapus data ini?`,
      okText: "Ya",
      cancelText: "Tidak",
      onOk: async () => {
        try {
          await deletePaymentOption(id);
          await getData(page, limit);
        } catch (error) {}
      },
    });
  };

  const onNavigateCreateNew = () => navigate("/master/coverage-area/create");

  const handleSearch = useDebounce(async (filterBy, e) => {
    getData(page, limit, filterBy, e.target.value);
  }, 1000);

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onNavigateCreateNew,
    handleSearch,
    isDisplayCreateModal,
    setIsDisplayCreateModal,
    isDisplayEditModal,
    setIsDisplayEditModal,
    getData,
    row,
  };
};
