import { useForm } from "react-hook-form";

export const UserCreateLogic = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return {
    register,
    control,
    handleSubmit,
    reset,
    errors,
    onSubmit,
  };
};
