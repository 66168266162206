import React from "react";
import iconWa from "@assets/wa.png";

const FloatingChat = () => (
  <a
    href='https://api.whatsapp.com/send?phone=628119310778&text=Hi%20Belanjaparts,%20saya%20ingin%20membeli%20spareparts.'
    className='absolute right-5 bottom-5 z-10 bg-green-400 rounded-full cursor-pointer'
    style={{
      background: "#fff",
      padding: 12,
    }}
    target='_blank'>
    <img src={iconWa} alt='icon' className='w-8 h-8 text-white' />
  </a>
);

export default FloatingChat;
