import moment from "moment";

export const generalDate = (date) => {
  if (moment(date).format("DD/MM/YYYY") === "01/01/0001") {
    return "-";
  } else {
    return moment(date).format("DD/MM/YYYY");
  }
};

export const generalTransactionDate = (date) => {
  if (moment(date).format("DD/MM/YYYY") === "01/01/0001") {
    return "-";
  } else {
    return moment(date).format("DD MMMM YYYY");
  }
};

export const fullDate = (date) => {
  if (moment(date).format("DD/MM/YYYY") === "01/01/0001") {
    return "-";
  } else {
    return moment(date).format("DD MMMM YYYY, hh:mm");
  }
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d.valueOf());
};

export const convertDay = (params) => {
  switch (params) {
    case 0:
      return "Minggu";
    case 1:
      return "Senin";
    case 2:
      return "Selasa";
    case 3:
      return "Rabu";
    case 4:
      return "Kamis";
    case 5:
      return "Jumat";
    case 6:
      return "Sabtu";
    default:
      return "Minggu";
  }
};

export const formatHour = (time) => {
  return time
    .split(":")
    .map((x) => x.padStart(2, "0"))
    .join(":")
    .slice(0, 5);
};
