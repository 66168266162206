import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import { getListTopic } from "@services/trainingMaterial.services";
import { partnerPopupPayloadAtom, stepIndexAtom } from "@store/joyride.store";
import { getJoyrideAnnounce, getJoyrideWelcome } from "../../../utils/memory";
import { isNil } from "lodash";
import { isFirstDisplayAtom } from "../../../store/joyride.store";

export const TrainingMaterialListLogic = () => {
  const navigate = useNavigate();

  const [topics, setTopics] = useState([]);
  const [, setStepIndex] = useAtom(stepIndexAtom);
  const [partnerPopupPayload] = useAtom(partnerPopupPayloadAtom);
  const [isFirstDisplay] = useAtom(isFirstDisplayAtom);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const joyride = await getJoyrideAnnounce();
    const joyrideWelcome = await getJoyrideWelcome();
    try {
      const res = await getListTopic();
      const data = await res.topics.map((topic, idx) => {
        if (isNil(joyrideWelcome)) {
          if (isNil(joyride)) {
            if (idx === 0) {
              return { ...topic, isActive: true };
            } else {
              return { ...topic, isActive: false };
            }
          } else {
            if (topic.id === partnerPopupPayload?.idTopic) {
              if (topic.id === joyride?.idTopic) {
                if (partnerPopupPayload?.idSubTopic !== joyride?.idSubTopic) {
                  return { ...topic, isActive: true };
                } else {
                  return { ...topic, isActive: false };
                }
              } else if (topic.id !== joyride?.idTopic) {
                return { ...topic, isActive: true };
              }
            } else {
              return { ...topic, isActive: false };
            }
          }
        } else if (isNil(joyride)) {
          if (isFirstDisplay) {
            return { ...topic, isActive: false };
          } else {
            if (topic.id === partnerPopupPayload?.idTopic) {
              return { ...topic, isActive: true };
            } else {
              return { ...topic, isActive: false };
            }
          }
        } else {
          if (topic.id === partnerPopupPayload?.idTopic) {
            if (topic.id === joyride?.idTopic) {
              if (partnerPopupPayload?.idSubTopic !== joyride?.idSubTopic) {
                return { ...topic, isActive: true };
              } else {
                return { ...topic, isActive: false };
              }
            } else if (topic.id !== joyride?.idTopic) {
              return { ...topic, isActive: true };
            }
          } else {
            return { ...topic, isActive: false };
          }
        }
      });
      await setTopics(data);
      await setStepIndex(1);
    } catch (error) {
      console.log(error);
    }
  };

  const setIsActive = (idx) => {
    const data = topics.map((topic, id) =>
      id === idx ? { ...topic, isActive: !topic.isActive } : topic
    );
    setTopics(data);
  };

  return {
    navigate,
    topics,
    setIsActive,
    partnerPopupPayload,
  };
};
