import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/atom/Button";
import { UserCreateLogic } from "./UserCreate.logic";

const UserCreate = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, errors, onSubmit } = UserCreateLogic();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-center pb-10'>
        <FontAwesomeIcon
          icon={faArrowLeft}
          className='text-2xl mr-4 cursor-pointer '
          onClick={() => navigate(-1)}
        />
        <p className='font-bold text-xl'>Create New User</p>
      </div>
      <div className='grid grid-cols-1 gap-5 pb-10'>
        <div>
          <p className='pb-1'>Nama Lengkap</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5  pb-10'>
        <div>
          <p className='pb-1'>Alamat Email</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            {...register("email", { required: true })}
          />
          {errors.email && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>
        <div>
          <p className='pb-1'>No Handphone</p>
          <div className='flex items-center'>
            <div className='w-1/5 border border-gray-300 w-full rounded py-2 px-3 mr-5 text-center'>
              +62
            </div>
            <input
              type='text'
              className='w-4/5 border border-gray-300 w-full rounded py-2 px-3'
              {...register("phone", { required: true })}
            />
          </div>
          {errors.phone && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5'>
        <div>
          <p className='pb-1'>Password</p>
          <input
            type='password'
            className='border border-gray-300 w-full rounded py-2 px-3'
            {...register("password", { required: true })}
          />
          {errors.password && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>
        <div>
          <p className='pb-1'>Konfirmasi Password</p>
          <input
            type='password'
            className='border border-gray-300 w-full rounded py-2 px-3'
            {...register("confirmPassword", { required: true })}
          />
          {errors.confirmPassword && (
            <span className='text-red-500'>This field is required</span>
          )}
        </div>
      </div>
      <div className='flex justify-end mt-5'>
        <Button textButton={"Create New User"} />
      </div>
    </form>
  );
};

export default UserCreate;
