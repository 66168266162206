import { authServices } from "@utils/setupApi";

export const getListSku = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/sku`,
    parameter,
  });
};

export const getDetailSku = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/sku/detail`,
    parameter,
  });
};

export const updateDetailSku = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v1/sku/mapping`,
    body,
  });
};

export const uploadCsvPrice = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/import-update-price`,
    body,
  });
};
