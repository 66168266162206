import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@styles/index.scss";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-modern-drawer/dist/index.css";
import "react-medium-image-zoom/dist/styles.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "@routes";
import { Provider } from "jotai";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider>
    <Routes />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
