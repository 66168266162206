import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { message } from "antd";

import { updatePaymentOption } from "@services/masterRequest.services";

export const PaymentOptionsEditLogic = ({ onCancel, getData, limit, row }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (row) {
      const { name, description } = row;
      setValue("name", name);
      setValue("description", description);
      if (row.details) {
        const { adminFee, duration } = row.details;
        setValue("admin_fee", adminFee?.number);
        setValue("duration", duration?.number);
      }
    }
  }, [row]);

  const onSubmit = async (data, e) => {
    const { name, description, admin_fee, duration } = data;

    const details = {
      admin_fee: {
        number: admin_fee ? parseFloat(admin_fee) : 0,
        unit: "percentage",
      },
      duration: {
        number: duration ? parseInt(duration) : 0,
        unit: "day",
      },
    };

    const req = {
      id: row.id,
      payment_key: row.paymentKey,
      name,
      description,
      details,
    };

    try {
      await updatePaymentOption(req);
      await e.target.reset();
      await getData(1, limit);
      await onCancel();
      await message.success("Berhasil update pembayaran tempo");
    } catch (error) {
      await message.error(`${error.data.message}`);
    }
  };

  return {
    errors,
    handleSubmit,
    onSubmit,
    register,
  };
};
