import React, { useState } from "react";
import { Select, Input } from "antd";

import "antd/es/input/style/css";
import "antd/es/select/style/css";

const { Option } = Select;
const { Search } = Input;

const SearchTable = ({ handleSearch }) => {
  const [filterBy, setfilterBy] = useState("name");

  const onChangeFilter = (e) => {
    setfilterBy(e);
  };

  return (
    <div className='mr-2'>
      <div className='flex items-center justify-end'>
        <div className='pr-2'>
          <Select
            value={filterBy}
            style={{ width: 160 }}
            onChange={onChangeFilter}>
            <Option value='name'>Name</Option>
            <Option value='phone'>Handphone</Option>
            <Option value='email'>Email</Option>
          </Select>
        </div>
        <Search
          type='text'
          placeholder={"Search"}
          onChange={(e) => handleSearch(filterBy, e)}
          style={{ width: 200 }}
        />
      </div>
    </div>
  );
};

export default SearchTable;
