import React, { useState } from "react";
import { Select, Input } from "antd";

import "antd/es/input/style/css";
import "antd/es/select/style/css";

const { Option } = Select;
const { Search } = Input;

const SearchTable = ({ handleSearch }) => {
  const [filterBy, setfilterBy] = useState("po_number");

  const onChangeFilter = (e) => {
    setfilterBy(e);
  };

  return (
    <div>
      <div className="flex items-center justify-start w-full">
        <Select
          value={filterBy}
          style={{ width: 120 }}
          onChange={onChangeFilter}
        >
          <Option value="po_number">No. PO</Option>
        </Select>
        <Search
          type="text"
          placeholder={"Cari"}
          onChange={(e) => handleSearch(filterBy, e)}
        />
      </div>
    </div>
  );
};

export default SearchTable;
