import { authServices } from "@utils/setupApi";

export const getListTopic = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/topics`,
  });
};

export const getDetailMaterial = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/material/${id}`,
  });
};
