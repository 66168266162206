import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import SkuTableDetail from "../components/SkuTableDetail/SkuTableDetail";
import SkuTableHistory from "../components/SkuTableHistory/SkuTableHistory";
import { SkuProductDetailLogic } from "./SkuProductDetail.logic";

const SkuProductDetail = () => {
  const { navigate, skuDetail } = SkuProductDetailLogic();
  return (
    <div>
      <div className="flex items-center justify-between pb-10 pt-3 md:pt-0">
        <div className="flex">
          <FontAwesomeIcon
            size="lg"
            icon={faArrowLeft}
            className="text-2xl mr-4 cursor-pointer "
            onClick={() => navigate(-1)}
          />
          <p className="font-bold text-xl">Detail SKU</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-8">
          <p className="text-gray-300 font-bold">SKU</p>
          <p className="text-transform uppercase font-bold">
            {skuDetail?.code}
          </p>
        </div>
        <div>
          <p className="text-gray-300 font-bold">Name Produk</p>
          <p className="text-transform uppercase font-bold">
            {skuDetail?.name}
          </p>
        </div>
      </div>
      <div className="mt-10">
        <SkuTableDetail data={skuDetail} />
      </div>
      <div className="mt-10">
        <SkuTableHistory data={skuDetail} />
      </div>
    </div>
  );
};

export default SkuProductDetail;
