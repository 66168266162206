import { useState, useEffect } from "react";
import { has, isNil } from "lodash";
import { useAtom } from "jotai";
import { pickupLoactionsAtom } from "@store/user.store";
import { message } from "antd";
import {
  createPickupLocation,
  getPickupLocation,
} from "@services/settings.services";

export const WarehouseFieldLogic = () => {
  const [warehouses, setWarehouses] = useAtom(pickupLoactionsAtom);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const resLocation = await getPickupLocation();
      await setWarehouses(resLocation.locations || []);
    } catch (error) {
      console.log(error);
    }
  };

  const manipulatePayload = (data) =>
    data.map((x) => ({
      name: x.name || "",
      address: x.address || "",
      pluscode: x.pluscode || "",
    }));

  const onRemove = async (index, item) => {
    const data = warehouses.filter((item, idx) => idx !== index);
    const dataDelete = warehouses.filter((item, idx) => idx == index);
    setWarehouses(data);
    if (has(item, "id")) {
      try {
        await createPickupLocation({ locations: manipulatePayload(data) });
        await message.success(
          `Berhasil menghapus Pickup Location - "${dataDelete[0].name}"`
        );
      } catch (err) {
        console.log("remove pickup location error: ", err);
      }
    }
    if (warehouses.length === 1) {
      setWarehouses([{ name: "", address: "", pluscode: "" }]);
    }
  };

  const onChangeName = (e, idx) => {
    const data = warehouses.map((item, id) =>
      id === idx ? { ...item, name: e } : item
    );
    setWarehouses(data);
  };

  const onChangeAddress = (e, idx) => {
    const data = warehouses.map((item, id) =>
      id === idx ? { ...item, address: e } : item
    );
    setWarehouses(data);
  };

  const onChangePlusCode = (e, idx) => {
    const data = warehouses.map((item, id) =>
      id === idx ? { ...item, pluscode: e } : item
    );
    setWarehouses(data);
  };

  const onAddWarehouse = () => {
    const newData = {
      name: "",
      address: "",
      pluscode: "",
    };
    warehouses.length >= 3
      ? message.error(`maximal 3 alamat warehouse`)
      : setWarehouses([...warehouses, newData]);
  };

  const onSubmit = async () => {
    const payload = manipulatePayload(warehouses);
    const types = payload.filter((x) => Object.values(x).includes(""));

    if (types.length) {
      message.warning(`Harap melengkapi data alamat pickup!`);
    } else {
      try {
        await createPickupLocation({ locations: payload });
        await setIsEditMode(false);
        await message.success(`Info Pickup berhasil disimpan`);
        const resLocation = await getPickupLocation();
        await setWarehouses(resLocation.locations || []);
      } catch (error) {
        await message.error(`${error.data.message}`);
      }
    }
  };

  return {
    onChangeName,
    onRemove,
    onChangeAddress,
    warehouses,
    onAddWarehouse,
    onSubmit,
    onChangePlusCode,
    isEditMode,
    setIsEditMode,
  };
};
