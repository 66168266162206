import { authServices } from "@utils/setupApi";

export const getListRequest = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/partner/request`,
    parameter,
  });
};

export const downloadTemplateRequest = (type) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/request/template/${type}`,
  });
};

export const uploadRequest = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/upload/partner/request`,
    body,
  });
};

export const getListCampaignRegistration = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/campaign-event-user`,
    parameter,
  });
};

///coverage-area///

export const getListWarehouseCoverageArea = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/coverage`,
    parameter,
  });
};

export const upsertWarehouseCoverageArea = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/coverage`,
    body,
  });
};

export const deleteWarehouseCoverageArea = (parameter) => {
  return authServices({
    method: "DELETE",
    url: `${process.env.REACT_APP_API}/v3/coverage`,
    parameter,
  });
};

export const uploadCsvCoverageArea = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/upload/coverage`,
    body,
  });
};

export const downloadTemplateCoverageArea = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/export/template/coverage`,
  });
};

export const downloadCsvCoverageArea = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/export/coverage`,
  });
};

// payment-options
export const getListPaymentOptions = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/payment-option`,
    parameter,
  });
};

export const createPaymentOption = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/payment-option`,
    body,
  });
};

export const updatePaymentOption = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/payment-option`,
    body,
  });
};

export const deletePaymentOption = (id) => {
  return authServices({
    method: "DELETE",
    url: `${process.env.REACT_APP_API}/v3/payment-option/${id}`,
  });
};

export const updateTogglePaymentOption = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/payment-option/toggle/${id}`,
  });
};

// paylater settings
export const getListWhitelists = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/user-credit`,
    parameter,
  });
};

export const getWhitelist = (id) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/user-credit/${id}`,
  });
};

export const createWhitelists = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v3/user-credit`,
    body,
  });
};

export const updateWhitelist = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/user-credit`,
    body,
  });
};

export const updateToggleWhitelist = (body) => {
  return authServices({
    method: "PUT",
    url: `${process.env.REACT_APP_API}/v3/user-whitelist`,
    body,
  });
};
