import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

import Dropzone from "@components/molekul/Dropzone";
import Button from "@components/atom/Button";
import { Select } from "antd";
import { PoEditLogic } from "./PoEdit.logic";
import { Controller } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { isEmpty } from "lodash";
import DropZoneFile from "../../../components/molekul/DropZoneFile";
import { acceptImageAndPdf } from "../../../components/molekul/Dropzone";

const { Option } = Select;

const PoEdit = ({ isEdit, setIsEdit }) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    onSubmit,
    register,
    invoiceFile,
    setInvoiceFile,
    statusLists,
    poDetail,
    invoiceExist,
    onRemoveFileInvoice,
    onDownloadPdf,
    isLoading,
  } = PoEditLogic(setIsEdit);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-center justify-between pb-10 pt-3 md:pt-0'>
        <div className='flex'>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className='text-2xl mr-4 cursor-pointer '
            onClick={() => navigate(-1)}
          />
          <p className='font-bold text-xl'>Edit Purchased Order</p>
        </div>
        <div className='flex'>
          <Button textButton={"Save"} type='submit' disabled={isLoading} />
          {isEdit && (
            <div className='ml-3'>
              <Button textButton='Cancel' onClick={() => setIsEdit(!isEdit)} />
            </div>
          )}
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5 pb-10'>
        <div>
          <p className='pb-1'>Purchase Order Number</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            disabled
            {...register("poRefNum")}
          />
        </div>
        <div>
          <p className='pb-1'>Purchase Order Date</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            disabled
            {...register("poDate")}
          />
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5 pb-10'>
        <div>
          <p className='pb-1'>Total PO</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            disabled
            {...register("total")}
          />
        </div>
        <div>
          <div className='flex flex-row items-center pb-1'>
            <p className='pr-1'>PO Status</p>
            <p className='italic text-gray-400'>
              (Seller perlu ganti status ke Processing ketika barang sudah siap)
            </p>
          </div>
          <div>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  style={{ width: "100%" }}
                  placeholder='Pilih Status'
                  onChange={onChange}
                  value={value}
                  disabled={
                    value === "Received" ||
                    value === "Paid" ||
                    value === "Canceled"
                  }>
                  {statusLists.map((status, idx) => {
                    return (
                      <Option value={status.value} key={idx}>
                        {status.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
              name='status'
              defaultValue=''
            />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5 pb-10'>
        <div>
          <p className='pb-1'>Fulfillment Date</p>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ReactInputMask
                mask={"99/99/9999"}
                className='border border-gray-300 w-full rounded py-2 px-3'
                placeholder='dd/mm/yyyy'
                value={value}
                onChange={onChange}
                disabled
              />
            )}
            name='fullfilmentDate'
            defaultValue=''
          />
        </div>
        <div>
          <p className='pb-1'>ETA Payment Date</p>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            disabled
            {...register("paymentDate")}
          />
        </div>
        <div>
          <div className='flex flex-row items-center'>
            <p className='pr-1'>Merchant Billing No.</p>
            <p className='italic text-gray-400'>
              (Seller wajib upload invoice setelah pengiriman / pickup)
            </p>
          </div>
          <input
            type='text'
            className='border border-gray-300 w-full rounded py-2 px-3'
            {...register("merchant_billing_no")}
          />
        </div>
        {/* <div>
          <p className="pb-1">Paid Timestamp</p>
          <input
            type="text"
            className="border border-gray-300 w-full rounded py-2 px-3"
            disabled
            {...register("paidTimestamp")}
          />
        </div> */}
      </div>
      <div className='grid grid-cols-2 gap-5 pb-10 mt-3'>
        <div>
          <p className='pb-1'>PO Detail</p>
          {isEmpty(poDetail?.poDetailsUri) ? (
            <p>-</p>
          ) : (
            <div
              className='button-basic w-40'
              onClick={() => onDownloadPdf(poDetail?.poDetailsUri)}>
              Download PDF
            </div>
          )}
        </div>
        <div>
          <p className='pb-1'>Delivery Order</p>
          {isEmpty(poDetail?.deliveryOrderUri) ? (
            <p>-</p>
          ) : (
            <div
              className='button-basic w-40'
              onClick={() => onDownloadPdf(poDetail?.deliveryOrderUri)}>
              Download PDF
            </div>
          )}
        </div>
      </div>
      <div className='grid grid-cols-2 gap-5 pb-10'>
        <div>
          <p className='text-xl font-bold mb-1'>Upload Billing</p>
          <p className='text-xs mb-2'>
            *Upload file dalam bentuk jpg, zip, rar atau PDF
          </p>
          {isEmpty(invoiceExist) ? (
            <Dropzone
              files={invoiceFile}
              setFiles={setInvoiceFile}
              accept={acceptImageAndPdf}
            />
          ) : (
            <DropZoneFile onRemoveFile={onRemoveFileInvoice} />
          )}
        </div>
      </div>
      <div>
        <p className='text-xl font-bold mb-1'>Return Item</p>
        <div className='grid grid-cols-2 gap-5 pb-3'>
          <div>
            <p className='pb-1'>Total Return</p>
            <input
              type='text'
              className='border border-gray-300 w-full rounded py-2 px-3'
              disabled
              {...register("returnAmount")}
            />
          </div>
          <div>
            <p className='pb-1'>Invoice Return</p>
            {isEmpty(poDetail?.return?.invoiceUri) ? (
              <p>-</p>
            ) : (
              <div
                className='button-basic w-48'
                onClick={() => onDownloadPdf(poDetail?.return?.invoiceUri)}>
                Download Return PDF
              </div>
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 gap-5 pb-10'>
          <div>
            <p className='pb-1'>Return Notes</p>
            <input
              type='text'
              className='border border-gray-300 w-full rounded py-2 px-3'
              disabled
              {...register("returnNotes")}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PoEdit;
