import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { message } from "antd";

import { getUserProfile } from "@services/auth.services";
import { userProfileAtom } from "@store/user.store";
import { onEnabledDelivery } from "@services/settings.services";

export const DeliveryMethodLogic = () => {
  const [profile, setUserProfile] = useAtom(userProfileAtom);
  const [delivery, setDelivery] = useState(false);
  const [selfPickup, setSelfPickup] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      setDelivery(profile?.deliveryConfig?.toggle?.delivery);
      setSelfPickup(profile?.deliveryConfig?.toggle?.selfPickUp);
    }
  }, [profile]);

  const onChangeToogle = async (params) => {
    setLoading(true);
    const body = {
      configurations: {
        toggle:
          params === "delivery"
            ? {
                self_pick_up: selfPickup,
                delivery: !delivery,
              }
            : {
                self_pick_up: !selfPickup,
                delivery: delivery,
              },
      },
    };
    try {
      await onEnabledDelivery(body);
      const user = await getUserProfile();
      setUserProfile(user);
      setLoading(false);
    } catch (err) {
      await message.error(`${err.data.message}`);
      setLoading(false);
    }
  };

  return {
    loading,
    onChangeToogle,
    delivery,
    selfPickup,
  };
};
