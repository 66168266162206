import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import LoadingOverlay from "react-loading-overlay";

import SearchLimit from "@components/molekul/SearchLimit";
import { RequestItemListLogic } from "./RequestItemList.logic";
import UploadCSVTemplateApi from "@components/molekul/UploadCSVTemplateApi";

LoadingOverlay.propTypes = undefined;

const RequestItemList = () => {
  const {
    data,
    columns,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onUpload,
    onDownloadTemplate,
  } = RequestItemListLogic();

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page,
          sizePerPage,
          totalSize,
          alwaysShowAllBtns: true,
          prePageText: "Sebelumnya",
          nextPageText: "Selanjutnya",
          withFirstAndLast: false,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <>
            <div className="flex items-end justify-between pb-3 pt-3 md:pt-0">
              <div className="md:w-1/3">
                <p className="font-bold text-xl pb-10">Permintaan SKU Baru</p>
                <SearchLimit limit={limit} onLimitChange={onLimitChange} />
              </div>
              <div className="w-2/3">
                <div className="md:flex justify-end">
                  <div className="md:mb-0">
                    <UploadCSVTemplateApi
                      onUpload={onUpload}
                      textButton="Upload CSV"
                      title="Upload Request Produk Baru"
                      onDownloadTemplate={onDownloadTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <BootstrapTable
                keyField="id"
                remote
                data={data}
                bordered={false}
                loading={loading}
                columns={columns}
                hover
                onTableChange={onTableChange}
                noDataIndication={() => (
                  <div className="text-center">Empty Data</div>
                )}
                overlay={overlayFactory({
                  spinner: true,
                  background: "rgba(192,192,192,0.3)",
                })}
                {...paginationTableProps}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="text-muted">
                  Menunjukan{" "}
                  <span className="font-weight-bold text-dark">
                    {`${data.length}`}
                  </span>{" "}
                  entry
                </p>
              </div>
              <div>
                {data.length > 0 && (
                  <PaginationListStandalone
                    {...paginationProps}
                    onPageChange={onPageChange}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </PaginationProvider>
    </div>
  );
};

export default RequestItemList;
