import React from "react";
import { Select } from "antd";
import "antd/es/select/style/css";

const { Option } = Select;

const SearchLimit = ({ limit, onLimitChange }) => {
  return (
    <div className="md:flex items-center">
      <label htmlFor="selectLimit" className="m-0 pr-2">
        Menunjukan :{" "}
      </label>
      <Select value={limit} style={{ width: 70 }} onChange={onLimitChange}>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={50}>50</Option>
        <Option value={100}>100</Option>
        <Option value={200}>200</Option>
      </Select>
    </div>
  );
};

export default SearchLimit;
