import React from "react";
import TrainingMaterialList from "./trainingMaterialList/TrainingMaterialList";

const TrainingMaterial = () => {
  return (
    <div>
      <div className='flex items-center justify-between pb-3 pt-3 md:pt-0'>
        <div className='w-1/2'>
          <p className='font-bold text-xl pb-10'>Tutorial</p>
        </div>
      </div>
      <TrainingMaterialList />
    </div>
  );
};

export default TrainingMaterial;
