import React from "react";

const ButtonOutlined = ({ textButton, onClick, type, style, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={disabled ? "button-disabled" : "button-outlined"}
      type={type}
      style={style}>
      {textButton}
    </button>
  );
};

export default ButtonOutlined;
