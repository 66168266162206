import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil } from "lodash";
import moment from "moment";
import React from "react";

import FileIcon from "../components/FileIcon";
import { TrainingMaterialDetailLogic } from "./TrainingMaterialDetail.logic";

const TrainingMaterialDetail = () => {
  const { data, navigate } = TrainingMaterialDetailLogic();

  const onPreview = (url) => {
    window.open(`${url}`, "_blank");
  };

  return (
    <div>
      <div className="pb-10 pt-3 md:pt-0">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon
            size="lg"
            icon={faArrowLeft}
            className="text-2xl mr-4 cursor-pointer"
            onClick={() => navigate("/training-material")}
          />
          <p className="font-bold text-xl">Detail Panduan Pengguna-an</p>
        </div>
        <div className="mb-4">
          <p className="text-3xl font-bold mb-1">{data?.title}</p>
          <p>{moment(data?.updated_at).format("DD MMMM YYYY")}</p>
        </div>
        <div className="mb-4">
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
        {isNil(data?.sources) ? null : (
          <div>
            <p className="font-bold  text-xl mb-2">Sources</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-10">
              {data?.sources.map((source, idx) => (
                <div
                  className="cursor-pointer rounded flex items-center bg-white shadow-md border border-gray-300 hover:shadow-xl"
                  key={idx}
                  onClick={() => onPreview(source.url)}
                >
                  <div className="w-20 h-20 flex items-center justify-center px-3 border-r border-gray-300">
                    {FileIcon(source.type)}
                  </div>
                  <div className="px-3">
                    <p className="text-xs">
                      <span>{source.title}</span> - <span>{source.type}</span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingMaterialDetail;
