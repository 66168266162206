// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { isEmpty, isNil } from "lodash";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, message } from "antd";

import {
  getListWarehouseCoverageArea,
  deleteWarehouseCoverageArea,
  uploadCsvCoverageArea,
} from "@services/masterRequest.services";
import { useDebounce } from "@utils/delay";
import { formatPriceIdr } from "@utils/format";
import { downloadTemplateCoverageArea } from "@services/masterRequest.services";
import { downloadCsvCoverageArea } from "@services/masterRequest.services";

export const CoverageAreaListLogic = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  useEffect(() => {
    getData(page, limit);
  }, []);

  const getData = async (page, limit, filterBy, text) => {
    setLoading(true);
    const params = {
      limit,
      page,
      [filterBy]: text,
    };
    try {
      const res = await getListWarehouseCoverageArea(params);
      isEmpty(res.coverages) ? setData([]) : setData(res.coverages);
      setPage(parseInt(res?.pagination.currentPage));
      setTotalSize(parseInt(res?.pagination.Count));
      setSizePerPage(parseInt(res?.pagination.RecordPerPage));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      dataField: "city",
      text: "Kota",
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "district",
      text: "Kecamatan",
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "minPurchase",
      text: "Minimum Pembelian",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <p>{isNil(cell) ? "-" : formatPriceIdr(cell)}</p>
      ),
    },
    {
      dataField: "shippingFee",
      text: "Ongkos Kirim",
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <p>{isNil(cell) ? "-" : formatPriceIdr(cell)}</p>
      ),
    },
    {
      dataField: "id",
      text: "Action",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => (
        <FontAwesomeIcon
          icon={faTrash}
          className='text-green-900 cursor-pointer'
          onClick={() => onDelete(row.id)}
        />
      ),
    },
  ];

  const onTableChange = (e) => {
    console.log(e);
  };

  const onPageChange = (e) => {
    getData(e, limit);
  };

  const onLimitChange = (e) => {
    getData(1, e);
    setLimit(e);
  };

  const onDelete = async (id) => {
    const params = {
      id,
    };
    await Modal.confirm({
      title: "Konfirmasi",
      content: `Apakah anda yakin akan menghapus data ini?`,
      okText: "Ya",
      cancelText: "Tidak",
      onOk: async () => {
        try {
          await deleteWarehouseCoverageArea(params);
          await getData(page, limit);
        } catch (error) {}
      },
    });
  };

  const onNavigateCreateNew = () => navigate("/master/coverage-area/create");

  const handleSearch = useDebounce(async (filterBy, e) => {
    getData(page, limit, filterBy, e.target.value);
  }, 1000);

  const onUpload = async (file) => {
    const body = {
      file,
    };
    try {
      await uploadCsvCoverageArea(body);
      await message.success(`success upload csv file`);
      await getData(page, limit);
    } catch (error) {
      message.error(`${error.data.message}`);
    }
  };

  const onDownloadTemplateCoverage = async () => {
    try {
      const res = await downloadTemplateCoverageArea();
      await window.open(res.url, "_blank");
    } catch (error) {}
  };

  const onDownloadCsv = async () => {
    try {
      const res = await downloadCsvCoverageArea();
      await window.open(res.url, "_blank");
    } catch (error) {}
  };

  return {
    columns,
    data,
    page,
    sizePerPage,
    totalSize,
    limit,
    loading,
    onTableChange,
    onPageChange,
    onLimitChange,
    onNavigateCreateNew,
    handleSearch,
    onUpload,
    onDownloadTemplateCoverage,
    onDownloadCsv,
  };
};
