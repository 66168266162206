import { message } from "antd";
import { useAtom } from "jotai";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { getListPartnerStatus } from "@services/dictionaries.services";
import { updatePo, uploadPo } from "@services/po.services";
import { poDetailAtom } from "@store/po.store";
import { convertStatusToSelect } from "@utils/convertData";
import { generalDate } from "@utils/formatDate";
import { getBase64 } from "@utils/manipulate";
import { partnerStatusListsAtom } from "@store/dictionaries";
import { getPoDetail } from "@services/po.services";

export const PoEditLogic = () => {
  const params = useParams();

  const [invoiceFile, setInvoiceFile] = useState("");
  const [invoiceExist, setInvoiceExist] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [poDetail, setPoDetail] = useAtom(poDetailAtom);
  const [statusLists, setStatusLists] = useAtom(partnerStatusListsAtom);

  const { control, handleSubmit, setValue, register } = useForm();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isEmpty(statusLists)) {
      getDataList();
    }
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const resPoDetail = await getPoDetail(params?.id);
      setPoDetail(resPoDetail);
      setValue("poRefNum", resPoDetail?.poRefNum);
      setValue("poDate", generalDate(resPoDetail?.poDate));
      setValue("fullfilmentDate", generalDate(resPoDetail?.fullfilmentDate));
      setValue("paymentDate", generalDate(resPoDetail?.paymentDate));
      setValue("status", resPoDetail?.status);
      setValue("total", resPoDetail?.total.number);
      setValue("returnAmount", resPoDetail?.return?.amount.number);
      setValue("returnNotes", resPoDetail?.return?.notes);
      setValue("merchant_billing_no", resPoDetail?.merchantBillingNo || "-");
      setValue(
        "paidTimestamp",
        resPoDetail.hasOwnProperty("paidTimestamp")
          ? generalDate(resPoDetail?.paidTimestamp)
          : "-"
      );
      setInvoiceExist(resPoDetail?.invoiceUri);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getDataList = async () => {
    try {
      const res = await getListPartnerStatus();
      await setStatusLists(convertStatusToSelect(res.entries));
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const body = {
      purchaseOrderId: params?.id,
      orgId: poDetail?.orgId,
      poRefNum: data?.poRefNum,
      total: {
        currency: "IDR",
        number: data?.total,
      },
      status: data?.status,
      fullfilmentDate: moment(`${data.fullfilmentDate}`, "DD/MM/YYYY"),
      paymentDate: moment(`${poDetail.paymentDate}`),
      poDate: moment(`${poDetail.poDate}`),
      return: {
        notes: data?.returnNotes,
        amount: {
          currency: "IDR",
          number: data?.returnAmount,
        },
      },
      merchantBillingNo:
        data?.merchant_billing_no == "-" ? "" : data?.merchant_billing_no,
    };

    if (
      data?.fullfilmentDate === "-" ||
      isEmpty(data?.fullfilmentDate) ||
      (data?.fullfilmentDate &&
        moment(data?.fullfilmentDate, "DD/MM/YYYY")._isValid)
    ) {
      try {
        await updatePo(params?.id, body);
        if (invoiceFile.length > 0) {
          const file = await getBase64(invoiceFile[0]);
          const data = {
            channel: "invoice",
            file,
          };
          await uploadPo(params?.id, data);
          await setInvoiceFile("");
        }
        await window.location.reload();
        await message.success("success update purchase order");
        await setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      message.error("Format date is wrong");
    }
  };

  const onRemoveFileInvoice = () => {
    setInvoiceExist("");
  };

  const onDownloadPdf = (params) => {
    if (params) {
      window.open(`${params}`, "_blank");
    }
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    register,
    invoiceFile,
    setInvoiceFile,
    statusLists,
    poDetail,
    invoiceExist,
    onRemoveFileInvoice,
    onDownloadPdf,
    isLoading,
  };
};
