import React, { useState } from "react";
import { Modal, message } from "antd";

import Button from "@components/atom/Button";
import Dropzone from "@components/molekul/Dropzone";
import DropzoneMultiple from "@components/molekul/DropzoneMultiple";
import { isNil } from "lodash";

import { getBase64 } from "@utils/manipulate";

const UploadCSVTemplateApi = ({
  onUpload,
  title,
  textButton,
  onDownloadTemplate,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileCatalog, setFileCatalog] = useState(null);
  const [pic, setPic] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (isNil(files)) {
      await message.error(`CSV belum di upload`);
    } else {
      const res = await getBase64(files[0]);
      const catalogs = isNil(fileCatalog)
        ? null
        : await Promise.all(
            fileCatalog.map(async (x) => ({
              name: x.name,
              file: await getBase64(x),
            }))
          );

      await onUpload(res, files[0].name, pic, catalogs);
      setPic("");
      setFiles(null);
      setFileCatalog(null);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangePic = (e) => {
    setPic(e.target.value);
  };

  return (
    <div>
      <Button textButton={textButton} onClick={showModal} />
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
        footer={
          <div className="flex items-center justify-between">
            <div>
              <div
                className="bg-green-900 p-2 text-white cursor-pointer rounded"
                onClick={onDownloadTemplate}
              >
                Download Template CSV
              </div>
            </div>
            <div className="flex items-center">
              <div
                className="border border-gray-400 p-2 mr-2 cursor-pointer rounded"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <div
                className="bg-green-900 p-2 text-white  cursor-pointer rounded"
                onClick={handleOk}
              >
                Upload
              </div>
            </div>
          </div>
        }
      >
        <div>
          <p className="mb-3">*Upload file dalam bentuk CSV</p>
          <Dropzone files={files} setFiles={setFiles} />
        </div>
        {title === "Upload Request Produk Baru" ? (
          <>
            <div>
              <p className="my-3">
                *Upload Multiple Catalog dalam bentuk image/pdf (optional)
              </p>
              <DropzoneMultiple files={fileCatalog} setFiles={setFileCatalog} />
            </div>
            <div>
              <p className="mt-3 mb-1">PIC Belanjaparts (optional)</p>
              <input
                type="text"
                className="border border-gray-300 w-full rounded py-2 px-3 mb-2"
                onChange={onChangePic}
                value={pic}
              />
            </div>
          </>
        ) : null}
      </Modal>
    </div>
  );
};

export default UploadCSVTemplateApi;
