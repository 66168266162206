import { authServices } from "@utils/setupApi";

export const updateUser = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/profile/update`,
    body,
  });
};

export const getListWorkshops = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v3/customer`,
    parameter,
  });
};
