import { authServices } from "@utils/setupApi";

export const getListStock = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/stock`,
    parameter,
  });
};

export const getSearchSku = (parameter) => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/sku/search`,
    parameter,
  });
};

export const createStockUpdate = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/stock`,
    body,
  });
};

export const uploadCsvStockUpdate = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/upload/stock`,
    body,
  });
};

export const uploadCsvFinalStock = (body) => {
  return authServices({
    method: "POST",
    url: `${process.env.REACT_APP_API}/v1/upload/stock/final`,
    body,
  });
};

export const downloadStock = () => {
  return authServices({
    method: "GET",
    url: `${process.env.REACT_APP_API}/v1/export/stock-update`,
  });
};
